import React from 'react';
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Close} from "@mui/icons-material";
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import IconButton from "@mui/material/IconButton";
import useStylesPayoutDataDialog from "./styles";

const PayoutDataDialog = (props) => {
    const {fields, errors, handleFormValid} = props;
    const {classes: payoutDataDialogStyles} = useStylesPayoutDataDialog();

    return <>
        <Dialog
            className={payoutDataDialogStyles.payoutDataDialog}
            fullWidth={true}
            maxWidth={"sm"}
            open={true}
        >
            <DialogTitle className={payoutDataDialogStyles.title}>
                <div className={payoutDataDialogStyles.titleText}>
                    Dane do wypłaty
                </div>
                <div className={payoutDataDialogStyles.titleDescription}>
                    {props.user.auth.userData.email} ({props.user.auth.userData.firstName} {props.user.auth.userData.lastName})
                </div>
                <IconButton
                    aria-label="close"
                    className={payoutDataDialogStyles.closeIcon}
                    onClick={() => props.setPayoutItem(null)}
                    size="large">
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={payoutDataDialogStyles.content}>
                {props.payoutItem.action.payoutInfo && (
                    <div className={payoutDataDialogStyles.payoutInfo} dangerouslySetInnerHTML={{__html: props.payoutItem.action.payoutInfo}}/>
                )}

                <BasicFormBuilderContainer
                    fields={fields}
                    errors={errors}
                    handleFormValid={handleFormValid}
                    btnText="Zapisz dane"
                />
            </DialogContent>
        </Dialog>
    </>;
}

export default PayoutDataDialog;