import React from 'react';
import {Container} from "@mui/material";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import ActionsSectionContainer from "@components/views/shared/ActionsSection";
import useStylesMain from "@components/layout/Main/styles";

const RegisterCompleted = () => {
    const {classes: mainStyles} = useStylesMain();

    return (
        <>
            <Container>
                <div className={mainStyles.content}>
                    <h1 className={mainStyles.contentTitle}>Rejestracja zakończona</h1>
                    <div className={mainStyles.contentBody}>
                        <div className={mainStyles.contentBodyFull}>
                            <DisplayMessageContainer type={DISPLAY_MESSAGES.REGISTER_CHECK_EMAIL}/>
                        </div>
                    </div>
                </div>
            </Container>

            <div className={mainStyles.actionsSectionContainer}>
                <ActionsSectionContainer/>
            </div>
        </>
    );
}

export default RegisterCompleted;