import React from 'react';
import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import actionImageMask from "@assets/actions/actionImageMask.png";
import {Link} from "react-router-dom";
import useStylesActionsSection from "./styles";

const ActionsSection = (props) => {
    const {classes: actionsSectionStyles} = useStylesActionsSection();

    return (
        <>
            {(props.actionsItems?.ongoing?.length > 0) && (
                <div className={actionsSectionStyles.actionsSection}>
                    <Container>
                        <div className={actionsSectionStyles.actionsTitle}>Aktywne Akcje Bonus</div>
                        <div className={actionsSectionStyles.actionsList}>
                            <Grid container spacing={3}>
                                {props.actionsItems.ongoing.map((action, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                        <Link to={`/akcja/${action.slug}/`}>
                                            <div className={actionsSectionStyles.action}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <div className={actionsSectionStyles.topContainer}>
                                                            <div className={actionsSectionStyles.imageContainer}>
                                                                <img
                                                                    className={actionsSectionStyles.imageMask}
                                                                    src={actionImageMask}
                                                                    alt={action.name}
                                                                />
                                                                <img
                                                                    className={actionsSectionStyles.image}
                                                                    src={action.thumbUrl}
                                                                    alt={action.name}
                                                                />
                                                            </div>

                                                            {action.bonusText && (
                                                                <div
                                                                    className={actionsSectionStyles.getBonusText}
                                                                    dangerouslySetInnerHTML={{__html: action.bonusText}}
                                                                />
                                                            )}
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={0}
                                                      className={actionsSectionStyles.actionContent}>
                                                    <Grid item xs={12}>
                                                        <div className={actionsSectionStyles.name}>
                                                            {action.name}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Container>
                </div>
            )}
        </>
    );
}

export default ActionsSection;