import React from 'react';
import Login from "@components/views/Login/Main";
import {connect} from "react-redux";
import {loginInit, login} from "@store/actions/user";
import {bindActionCreators} from "redux";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import {useNavigate} from "react-router";

const LoginContainer = (props) => {
    const formFields = BASIC_FORM_FIELDS_CONFIG.LOGIN;

    const navigate = useNavigate();

    const handleFormValid = (data) => {
        props.actions.login(data, navigate);
    }

    return (
        <Login
            fields={formFields}
            errors={props.user.auth.errors}
            handleFormValid={handleFormValid}
        />
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            loginInit,
            login,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainer);