import {
    SET_PARTICIPATION_DIALOG_OPEN,
} from "@store/types/actionPage";

const setParticipationDialogOpen = (isOpen) => ({
    type: SET_PARTICIPATION_DIALOG_OPEN,
    payload: isOpen
});

export {
    setParticipationDialogOpen,
}