import {makeStyles} from "tss-react/mui";

const useStylesParticipationDialog = makeStyles()(theme => ({
    participationDialog: {},
    title: {
        textAlign: "center",
        borderBottom: `1px solid ${theme.palette.color3}`,
    },
    titleText: {
        color: "#000",
        fontSize: "18px",
        fontWeight: 600,
        textTransform: "uppercase",
    },
    titleDescription: {
        display: "block",
        color: theme.palette.color1,
        fontSize: "14px",
    },
    participationDialogContent: {
        wordBreak: "break-word",
        overflowX: "hidden",
        padding: "15px!important",
        boxShadow: "inset 0 -20px 20px -20px #ddd"
    },
    participationMessage: {
        display: "block",
        fontSize: "14px",
        color: theme.palette.color7,
        textAlign: "justify",
        margin: "0 0 15px 0",
        padding: "5px 10px",
        borderRadius: "5px",
        border: `1px solid ${theme.palette.color7}`,
        fontWeight: 600,
    },
    agreementsContainer: {},
    agreementsTitle: {
        fontSize: "15px",
        color: "#000",
        fontWeight: 600,
    },
    agreements: {},
    agreementContainer: {
        width: "100%",
        marginBottom: "15px",
        position: "relative",
    },
    agreementField: {
        width: "calc(100% + 11px)",
        "& [class*=-checked]": {
            "& .MuiSvgIcon-root": {
                color: theme.palette.color1,
            },
        },
        "& .MuiSvgIcon-root": {
            color: theme.palette.color5,
        },
        "& .MuiTypography-root": {
            fontSize: "13px",
            textAlign: "justify",
        }
    },
    agreementCheckbox: {
        alignSelf: "flex-start",
    },
    checkAllAgreementsContainer: {},
    checkAllAgreementsButton: {
        backgroundColor: theme.palette.color2,
        color: "#fff",
        fontSize: "14px",
        textTransform: "none",
        borderRadius: "15px",
        height: "30px",
        padding: "0 15px",
        "&:hover": {
            backgroundColor: theme.palette.color2Darker,
        },
    },
    labelParagraph: {
        margin: 0,
        fontSize: "14px",
        color: "#000",
        textAlign: "justify",
        "& a": {
            textDecoration: "underline",
            color: theme.palette.color1,
        }
    },
    fieldError: {
        fontSize: "13px",
        fontWeight: 600,
        color: theme.palette.color7,
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "20px",
            margin: "0 5px 0 0",
        }
    },
    referralCodeContainer: {
        margin: "15px 0",
    },
    referralCodeTitle: {
        fontSize: "15px",
        color: "#000",
        fontWeight: 600,
    },
    referralCodeSwitcherLabel: {
        "& .MuiFormControlLabel-label": {
            fontSize: "14px",
            fontWeight: 600,
            color: "#000",
        }
    },
    referralCodeSwitcher: {
        "& .Mui-checked .MuiSwitch-thumb": {
            backgroundColor: theme.palette.color2,
        },
        "& .MuiSwitch-track": {
            backgroundColor: theme.palette.color2,
        }
    },
    referralCodeDescription: {
        fontSize: "14px",
        color: "#000",
    },
    referralCodeFieldContainer: {
        display: "table",
        margin: "15px auto 0 auto",
    },
    referralCodeField: {},
    actionsContainer: {
        backgroundColor: theme.palette.color6,
        borderTop: `1px solid ${theme.palette.color3}`,
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    serverErrors: {
        width: "100%",
        margin: "0 0 15px 0",
        padding: "5px",
        borderRadius: "5px",
        backgroundColor: theme.palette.color7,
    },
    serverError: {
        fontSize: "13px",
        fontWeight: 600,
        color: "#fff",
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "20px",
            margin: "0 5px 0 0",
        }
    },
    buttonsContainer: {
        width: "100%",
        margin: "0!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    cancelButton: {
        textTransform: "none",
    },
    submitButton: {
        padding: "10px 20px",
        fontSize: "14px",
        backgroundColor: theme.palette.color1,
        color: "#fff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        }
    },

    redirectContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "30px 15px",
    },
    redirectTitle: {
        textAlign: "center",
        fontWeight: 700,
        fontSize: "15px",
        margin: "15px auto 0 auto",
    },
    redirectImageContainer: {
        position: "relative",
        margin: "0 auto",
    },
    redirectImageMask: {
        width: "100%",
    },
    redirectImage: {
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        animation: "showElement 0.3s"
    },
    redirectDescription: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "15px auto 0 auto",
        textAlign: "center",
        fontSize: "14px",
    },
    redirectLoader: {
        marginLeft: "5px",
        width: "24px!important",
        height: "24px!important",
        color: theme.palette.color2,
        animation: "rotation 2s infinite linear",
    }
}))

export default useStylesParticipationDialog;