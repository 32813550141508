import React from 'react';
import {Container} from "@mui/material";
import {Link} from "react-router-dom";
import ROUTES from "@consts/routes";
import Button from "@mui/material/Button";
import useStylesLandingPage from "@components/views/StartPage/LandingPage/styles";
import useStylesTopSection from "./styles";

const TopSection = () => {
    const {classes: topSectionStyles} = useStylesTopSection();
    const {classes: landingPageStyles} = useStylesLandingPage();

    return (
        <div className={topSectionStyles.topSection}>
            <Container className={topSectionStyles.container}>
                <h1 className={topSectionStyles.title}>
                    Dołącz do nas<br/>i zyskaj BONUS!
                </h1>

                <div className={topSectionStyles.buttonContainer}>
                    <Link to={ROUTES.REGISTER}>
                        <Button className={landingPageStyles.button}>Zarejestruj się</Button>
                    </Link>
                </div>

                <div className={topSectionStyles.loginLinkContainer}>
                    <div className={topSectionStyles.loginLinkTitle}>
                        Masz już profil uczestnika?
                    </div>

                    <Link to={ROUTES.LOGIN} className={topSectionStyles.loginLink}>
                        Zaloguj się i weź udział
                    </Link>
                </div>
            </Container>
        </div>
    );
}

export default TopSection;