import React from 'react';
import './index.scss';
import App from "./App";
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider, StyledEngineProvider} from "@mui/material";
import store from "./store";
import { createRoot } from 'react-dom/client';

const theme = createTheme({
    palette: {
        color1: '#007FFF',
        color1Darker: '#0072E6',
        color1Lighter: '#ECF5FF',
        color2: '#3db224',
        color2Darker: '#369D20',
        color3: '#f1f1f1',
        color3Darker: '#E4E4E4',
        color4: '#777',
        color5: '#bbb',
        color6: '#f8f8f8',
        color7: '#F61F1F',
        color8: '#333',
    },
    typography: {
        fontFamily: 'Lato, Arial',
    },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
