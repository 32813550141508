import React from 'react';
import Complaints from "@components/views/Complaints/Main";
import {connect} from "react-redux";
import {getComplaintsInit, getComplaints} from "@store/actions/complaints";
import {bindActionCreators} from "redux";

const ComplaintsContainer = (props) => {
    return (
        <Complaints {...props}/>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    complaints: state.complaints
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getComplaintsInit,
            getComplaints,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComplaintsContainer);