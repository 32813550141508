import {
    GET_COMPLAINTS_INIT,
    GET_COMPLAINTS_SUCCESS,
    GET_COMPLAINTS_FAILURE,
    SEND_COMPLAINTS_DATA_INIT,
    SEND_COMPLAINTS_DATA_SUCCESS,
    SEND_COMPLAINTS_DATA_FAILURE
} from "@store/types/complaints";

const initialState = {
    complaintsItems: null,
    complaintsItemsErrors: null,
    complaintsDataSent: false,
    complaintsDataSentErrors: null,
}

const complaintsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPLAINTS_INIT: {
            return initialState;
        }
        case GET_COMPLAINTS_SUCCESS: {
            return {
                ...state,
                complaintsItems: action.payload,
                complaintsItemsErrors: initialState.complaintsItemsErrors,
            };
        }
        case GET_COMPLAINTS_FAILURE: {
            return {
                ...state,
                complaintsItems: initialState.complaintsItems,
                complaintsItemsErrors: action.payload
            };
        }

        case SEND_COMPLAINTS_DATA_INIT: {
            return {
                ...state,
                complaintsDataSent: initialState.complaintsDataSent,
                complaintsDataSentErrors: initialState.complaintsDataSentErrors
            };
        }
        case SEND_COMPLAINTS_DATA_SUCCESS: {
            return {
                ...state,
                complaintsItems: action.payload,
                complaintsItemsErrors: initialState.complaintsItemsErrors,
                complaintsDataSent: true,
                complaintsDataSentErrors: initialState.complaintsDataSentErrors,
            };
        }
        case SEND_COMPLAINTS_DATA_FAILURE: {
            return {
                ...state,
                complaintsDataSent: false,
                complaintsDataSentErrors: action.payload,
            };
        }

        default:
            return state;
    }
}

export default complaintsReducer;


