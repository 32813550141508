import * as React from "react";
import {Component} from "react";
import {Container} from "@mui/material";

class LazyLoad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            component: null,
            errorMessage: null,
        };

        this.reloadTimeout = null;
    }

    async componentDidMount() {
        try {
            const {
                default: component
            } = await this.props.resolve();

            this.setState({
                component
            });
        } catch (error) {
            this.reloadTimeout = setTimeout(() => {
                window.location.reload(true);
            }, 1000);

            this.setState({
                errorMessage: "Wczytywanie akcji"
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.reloadTimeout);
    }

    render() {
        const {component, errorMessage} = this.state;
        const {...rest} = this.props;

        return (
            <>
                {errorMessage && <Container>{errorMessage}</Container>}
                {component && React.createElement(component, rest)}
            </>
        )
    }
}

export default LazyLoad;