import React from 'react';
import SKELETONS from "@consts/skeletons";
import Grid from "@mui/material/Grid";
import Skeleton from '@mui/material/Skeleton';

const SkeletonContent = ({type}) => {
    const generateRandomNumber = (min, max) => {
        return min + (Math.random() * (max - min));
    }

    const getSkeletonContent = () => {
        switch (type) {
            case SKELETONS.ACTIONS:
            case SKELETONS.STATISTICS:
            case SKELETONS.PAYOUT:
            case SKELETONS.COMPLAINTS: {
                return (
                    <Grid container spacing={3}>
                        {[...Array(3)].map((el, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                <Skeleton
                                    variant="rectangular"
                                    height={230}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="text"
                                    width={`${generateRandomNumber(25, 50)}%`}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="text"
                                    width={`${generateRandomNumber(50, 75)}%`}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="text"
                                    width={`${generateRandomNumber(75, 100)}%`}
                                    animation="wave"
                                />
                            </Grid>
                        ))}
                    </Grid>
                );
            }
            case SKELETONS.FAQ: {
                return (
                    <Grid container>
                        {[...Array(5)].map((el, index) => (
                            <Grid item xs={12} key={index}>
                                <Skeleton
                                    variant="text"
                                    width="100%"
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="text"
                                    width="100%"
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="text"
                                    width={`${generateRandomNumber(25, 100)}%`}
                                    animation="wave"
                                />
                            </Grid>
                        ))}
                    </Grid>
                )
            }
            case SKELETONS.STATIC_PAGE_TITLE: {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Skeleton
                                width={`${generateRandomNumber(180, 220)}px`}
                                height={45}
                                animation="wave"
                            />
                        </Grid>
                    </Grid>
                )
            }
            case SKELETONS.STATIC_PAGE: {
                return (
                    <Grid container>
                        {[...Array(3)].map((el, index) => (
                            <Grid item xs={12} key={index}>
                                <Skeleton
                                    width="100%"
                                    height={45}
                                    animation="wave"
                                />
                                {[...Array(5)].map((el, index) => (
                                    <Skeleton
                                        variant="text"
                                        width={`${generateRandomNumber(25, 100)}%`}
                                        animation="wave"
                                        key={index}
                                    />
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                )
            }
            case SKELETONS.CONTACT: {
                return (
                    <Grid container>
                        {[...Array(3)].map((el, index) => (
                            <Grid item xs={12} key={index}>
                                <Skeleton
                                    width="100%"
                                    height={45}
                                    animation="wave"
                                />
                                {[...Array(5)].map((el, index) => (
                                    <Skeleton
                                        variant="text"
                                        width={`${generateRandomNumber(25, 100)}%`}
                                        animation="wave"
                                        key={index}
                                    />
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                )
            }
            case SKELETONS.REGISTER: {
                return (
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Grid container>
                                <Grid item xs={4} sm={2}>
                                    <Skeleton
                                        variant="circular"
                                        width={45}
                                        height={45} animation="wave"
                                    />
                                </Grid>
                                <Grid item xs={8} sm={10}>
                                    <Skeleton
                                        width="100%"
                                        height={45}
                                        animation="wave"
                                    />
                                </Grid>
                                {[...Array(10)].map((el, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Skeleton
                                            width="100%"
                                            animation="wave"
                                        />
                                    </Grid>
                                ))}
                                <Skeleton
                                    width="100%"
                                    height={60}
                                    animation="wave"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }
            default: {
                return <>{type} - Skeleton content error.</>
            }
        }
    }

    return (
        <>
            {getSkeletonContent()}
        </>
    );
}

export default SkeletonContent;