import {
    LOGIN_USER_INIT,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    AUTO_LOGIN_USER_INIT,
    AUTO_LOGIN_USER_SUCCESS,
    AUTO_LOGIN_USER_FAILURE,
    REFRESH_TOKEN_INIT,
    LOGOUT,
    UPDATE_PROFILE_SUCCESS, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE, RESET_REDIRECT_USER_AFTER_LOGOUT_TO,
} from "@store/types/user";

const initialState = {
    isLoggedIn: false,
    userData: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        uuid: null,
        dataVerification: null,
    },
    refreshTokenRequest: false,
    errors: null,
    redirectToAfterLogout: null,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER_INIT:
        case AUTO_LOGIN_USER_INIT: {
            return initialState;
        }
        case LOGOUT: {
            return {
                ...initialState,
                redirectToAfterLogout: action.payload,
            };
        }
        case RESET_REDIRECT_USER_AFTER_LOGOUT_TO: {
            return {
                ...state,
                redirectToAfterLogout: initialState.redirectToAfterLogout,
            };
        }
        case AUTO_LOGIN_USER_SUCCESS:
        case LOGIN_USER_SUCCESS:
        case UPDATE_PROFILE_SUCCESS: {
            return {
                ...initialState,
                userData: action.payload,
                isLoggedIn: true,
            };
        }
        case LOGIN_USER_FAILURE:
        case AUTO_LOGIN_USER_FAILURE: {
            return {
                ...initialState,
                errors: action.payload,
            };
        }

        case REFRESH_TOKEN_INIT:
            return {
                ...state,
                refreshTokenRequest: true,
            };
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                refreshTokenRequest: false,
            };
        case REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                refreshTokenRequest: true,
            };
        default:
            return state;
    }
}

export default authReducer;