import React, {useState} from 'react';
import Logo from "@assets/logos/logo.png";
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import {Container} from "@mui/material";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import SkeletonContentContainer from "@components/shared/SkeletonContent";
import SKELETONS from "@consts/skeletons";
import {Link} from "react-router-dom";
import ROUTES from "@consts/routes";
import ActionsSectionContainer from "@components/views/shared/ActionsSection";
import {LiveHelp} from "@mui/icons-material";
import {Navigate} from "react-router";
import useStylesMain from "@components/layout/Main/styles";
import ActionThumbContainer from "@components/views/Register/ActionThumb";

const Register = (props) => {
    const {fields, user, handleFormValid} = props;
    const {classes: mainStyles, cx} = useStylesMain();

    const [showMessage, setShowMessage] = useState(false);

    const getContent = () => {
        if (user.profile.registerSuccess) {
            return (
                <Navigate to={ROUTES.REGISTER_COMPLETED} push={true}/>
            )
        } else if (user.profile.registerAgreementsItems) {
            return (
                <>
                    <img
                        className={mainStyles.logo}
                        src={Logo}
                        alt="Logo"
                    />

                    <div className={mainStyles.registerFormLinks}>
                        <p className={cx(mainStyles.loginFormLinkParagraph, mainStyles.loginFormLinkToRegisterParagraph)}>
                            Masz już profil uczestnika? <Link to={ROUTES.LOGIN}>Zaloguj się</Link>
                        </p>
                    </div>

                    <ActionThumbContainer/>

                    <p className={mainStyles.formDescription}>
                        Utwórz nowy profil uczestnika
                        <span className={mainStyles.helpMessageContainer}>
                            <LiveHelp
                                className={mainStyles.helpMessageIcon}
                                onClick={() => setShowMessage(!showMessage)}
                            />

                            {showMessage && (
                                <span className={mainStyles.helpMessageText}>
                                    Zarejestruj swój Profil Uczestnika, aby wziąć udział w Akcji i zyskać Bonus.<br/>
                                    Po aktywacji Profilu, zaloguj się i weź udział w promocji.
                                </span>
                            )}
                        </span>
                    </p>
                    <BasicFormBuilderContainer
                        fields={fields}
                        errors={user.profile.registerErrors}
                        handleFormValid={handleFormValid}
                        btnText="Zarejestruj się"
                    />

                    <>
                        {fields.map((field, index) => {
                            if (field.type === 'checkbox' && field.agreementType === 'INFORMATION') {
                                return (
                                    <div key={index}
                                         className={mainStyles.adminInfo}
                                         dangerouslySetInnerHTML={{__html: field.label}}
                                    />
                                )
                            }
                            return null;
                        })}
                    </>
                </>
            )
        } else if (user.profile.registerAgreementsErrors) {
            return <DisplayMessageContainer type={DISPLAY_MESSAGES.SOMETHING_WENT_WRONG}/>;
        } else if (!user.profile.registerAgreementsItems && !user.profile.registerAgreementsErrors) {
            return <SkeletonContentContainer type={SKELETONS.REGISTER}/>
        }
    }

    return (
        <>
            <Container>
                <div className={mainStyles.content}>
                    <h1 className={mainStyles.contentTitle}>Rejestracja</h1>
                    <div className={mainStyles.contentBody}>
                        <div className={mainStyles.contentBodyFull}>
                            {getContent()}
                        </div>
                    </div>
                </div>
            </Container>

            <div className={mainStyles.actionsSectionContainer}>
                <ActionsSectionContainer/>
            </div>
        </>
    );
}

export default Register;