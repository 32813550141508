import {makeStyles} from "tss-react/mui";

const useStylesStaticPage = makeStyles()(theme => ({
    textContainer: {
        wordBreak: "break-word",
    },
    attachments: {
        width: "100%",
    },
    attachmentsTitle: {
        fontWeight: 600,
    },
    attachment: {
        color: theme.palette.color1,
    },
}));

export default useStylesStaticPage;