import React, {useEffect, useState} from 'react';
import Contact from "@components/views/Contact/Main";
import {connect} from "react-redux";
import {getAdminInfoInit, getAdminInfo, sendMessage} from "@store/actions/contact";
import {bindActionCreators} from "redux";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import {useMount} from "@s-ui/react-hooks";

const ContactContainer = (props) => {
    const [fields, setFields] = useState(BASIC_FORM_FIELDS_CONFIG.CONTACT);
    const {adminInfo, adminInfoErrors, messageSent, messageSentErrors} = props.contact;

    const handleFormValid = (data) => {
        props.actions.sendMessage(data);
    }

    useEffect(() => {
        return () => {
            props.actions.getAdminInfoInit();
        }
    }, [props.actions]);

    useMount(() => {
        if (props.user.auth.isLoggedIn) {
            setFields(fields.map(field => {
                if (field.name === 'email') {
                    return {
                        ...field,
                        value: props.user.auth.userData.email,
                        isDisabled: true,
                    }
                }
                return field;
            }));
        }

        props.actions.getAdminInfo();
    });

    return (
        <Contact
            fields={fields}
            adminInfo={adminInfo}
            adminInfoErrors={adminInfoErrors}
            messageSent={messageSent}
            messageSentErrors={messageSentErrors}
            handleFormValid={handleFormValid}
        />
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
    contact: state.contact
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getAdminInfoInit,
            getAdminInfo,
            sendMessage,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactContainer);