import React from 'react';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import useStylesDisplayMessage from "./styles";

const DisplayMessage = (props) => {
    const {classes: displayMessageStyles} = useStylesDisplayMessage();
    const {type, customTitle, customDescription} = props;

    return (
        <>
            {type.icon && <type.icon className={displayMessageStyles.icon}/>}

            {(customTitle || type.title) && <h2 className={displayMessageStyles.title}>
                {customTitle ? customTitle : type.title}
            </h2>}

            {(customDescription || type.description) &&
            <div
                className={displayMessageStyles.description}
                dangerouslySetInnerHTML={{__html: customDescription ? customDescription : type.description}}/>}

            {type.link && <Link className={displayMessageStyles.link} to={type.link}>
                <Button variant="contained" className={displayMessageStyles.button}>{type.linkText}</Button>
            </Link>}
        </>
    );
}

export default DisplayMessage;