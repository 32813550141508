import {makeStyles} from "tss-react/mui";

const useStylesDisplayMessage = makeStyles()(theme => ({
    icon: {
        display: "block",
        margin: "0 auto",
        width: "48px",
        height: "48px",
        color: theme.palette.color2,
    },
    title: {
        display: "block",
        margin: "10px auto 0 auto",
        fontSize: "18px",
        textAlign: "center",
    },
    description: {
        display: "block",
        margin: "10px auto 0 auto",
        fontSize: "14px",
        textAlign: "center",
    },
    link: {
        display: "table",
        margin: "20px auto 0 auto",
    },
    button: {
        textTransform: "none",
        backgroundColor: theme.palette.color1,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        }
    }
}));

export default useStylesDisplayMessage;