import {makeStyles} from "tss-react/mui";

const useStylesLoader = makeStyles()(theme => ({
    fullScreenLoader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 9999,
        backgroundColor: "#fff",
        animation: "loaderFadeOut .2s forwards",
    },
    fullScreenLoaderActive: {
        animation: "none",
    },
    circles: {
        display: "flex",
    },
    circle: {
        position: "relative",
        animation: "rotation 2s infinite linear",
        margin: "0 5px",
        height: "40px",
        "&:nth-of-type(2)": {
            top: "20px",
        },
    },

    fullScreenTransparentLoader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 9998,
        backgroundColor: "rgba(255,255,255,.5)",
        animation: "loaderFadeOut .2s forwards",
    },
    fullScreenTransparentLoaderActive: {
        animation: "none",
    },

    fullScreenForSkeletonLoader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 9997,
        backgroundColor: "rgba(255,255,255,.5)",
        animation: "loaderFadeOut .2s forwards",
    },
    fullScreenForSkeletonLoaderActive: {
        animation: "none",
    },
}));

export default useStylesLoader;