const API_ERRORS = [
    {code: 'SECURITY_SDK:FORBIDDEN', message: 'Brak dostępu do zasobu'},
    {code: 'TOKEN_REQUEST:INVALID_GRANT', message: 'Nieprawidłowa nazwa użytkownika lub hasło'},
    {code: 'TOKEN_REQUEST:USER_NOT_ACTIVE', message: 'Konto nie jest aktywne. E-mail z linkiem aktywacyjnym został wysłany na Twój adres e-mail'},
    {code: 'AUTH:REGISTER:EMAIL_ALREADY_EXISTS', message: 'Konto o podanym adresie email już istnieje'},
    {code: 'AUTH:REGISTER:PHONE_ALREADY_EXISTS', message: 'Konto o podanym numerze telefonu już istnieje'},
    {code: 'AUTH:REGISTER:PHONE_IS_INVALID', message: 'Podany nr telefonu jest nieprawidłowy'},
    {code: 'PROFILE:EDIT:PHONE_IS_INVALID', message: 'Podany nr telefonu jest nieprawidłowy'},
    {code: 'AUTH:ACTIVATE:TOKEN_NOT_FOUND', message: 'Podany token aktywacji użytkownika jest niepoprawny lub użytkownik jest już aktywny'},
    {code: 'AUTH:ACTIVATE:TOKEN_EXPIRED', message: 'Podany token aktywacji użytkownika jest nieważny'},
    {code: 'EDIT_USER:CURRENT_PASSWORD_NOT_MATCH', message: 'Aktualne hasło jest niepoprawne'},
    {code: 'RECOVER_PASSWORD:SET_PASSWORD:TOKEN_NOT_FOUND', message: 'Podany token zmiany hasła nie został odnaleziony'},
    {code: 'RECOVER_PASSWORD:SET_PASSWORD:TOKEN_EXPIRED', message: 'Podany token zmiany hasła jest nieważny'},
    {code: 'RECOVER_PASSWORD:USER_NOT_FOUND', message: 'Podany adres email nie został odnaleziony'},
    {code: 'PROFILE:DELETE:PASSWORD_IS_INVALID', message: 'Hasło jest niepoprawne'},
    {code: 'PROFILE:PHONE_ALREADY_EXISTS', message: 'Podany nr telefonu jest używany przez innego użytkownika'},
    {code: 'USER:REFERRAL_USER_NOT_FOUND', message: 'Podany kod polecający nie istnieje'},
    {code: 'PARTICIPATION:ALREADY_PARTICIPATED', message: 'Ten użytkownik wziął udział w tej akcji'},
    {code: 'PARTICIPATION:CANT_USE_OWN_REFERRAL_CODE', message: 'Nie możesz użyć własnego kodu polecającego'},
    {code: 'ACTION:BAD_ACCESS_TOKEN', message: 'Podany kod dostępu jest nieprawidłowy'},
];

export default API_ERRORS;