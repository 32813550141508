import React from 'react';
import Payout from "@components/views/Payout/Payout";
import {connect} from "react-redux";
import {getPayoutInit, getPayout} from "@store/actions/payout";
import {bindActionCreators} from "redux";

const PayoutContainer = (props) => {
    return (
        <Payout {...props}/>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    payout: state.payout
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getPayoutInit,
            getPayout,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayoutContainer);