import {makeStyles} from "tss-react/mui";

const useStylesFaq = makeStyles()(theme => ({
    faqItem: {
        width: "100%",
        display: "block",
        margin: "0 0 15px 0",
        backgroundColor: "#fff",
        padding: 0,
        borderRadius: "7px",
        boxShadow: `0 1px 2px ${theme.palette.color5}`,
        "&:before": {
            display: "none",
        },
        "&:last-of-type": {
            margin: "0",
            border: "none",
        },
        "& .MuiAccordionSummary-content": {
            margin: "0",
        },
        "&.MuiAccordion-root.Mui-expanded": {
            margin: "0 0 15px 0",
        },
    },
    faqQuestionContainer: {
        padding: "15px",
        fontSize: "16px",
        fontWeight: 600,
        color: "#000",
        "&.Mui-expanded": {
            color: theme.palette.color1,
        },
        "&.MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 0,
        }
    },
    faqQuestionText: {},
    faqAnswerContainer: {},
    faqAnswerText: {
        fontSize: "14px",
        textAlign: "justify",
    },
    skeleton: {
        width: "100%",
    },
    skeletonItem: {
        width: "100%",
        margin: "0 0 15px 0",
        "&:last-of-type": {
            margin: 0,
        }
    }
}));

export default useStylesFaq;