import React, {useState} from 'react';
import ComplaintsDataDialog from "@components/views/Complaints/ComplaintsDataDialog/Main";
import {bindActionCreators} from "redux";
import {
    sendComplaintsDataInit,
    sendComplaintsData,
} from "@store/actions/complaints";
import {connect} from "react-redux";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import {useMount} from "@s-ui/react-hooks";

const ComplaintsDataDialogContainer = (props) => {
    const [fields, setFields] = useState(BASIC_FORM_FIELDS_CONFIG.COMPLAINTS);

    const updateFormFields = () => {
        const complaintsItem = props.complaintsItem;

        const updatedFields = fields.map(field => {
            if (field.name === "participationUuid") {
                return {
                    ...field,
                    value: complaintsItem.participationUuid,
                    isDisabled: true,
                }
            }
            return field;
        });

        setFields([
            ...updatedFields,
        ]);
    }

    const handleFormValid = (data) => {
        props.actions.sendComplaintsData(data);
    }

    useMount(() => {
        props.actions.sendComplaintsDataInit();
        updateFormFields();
    });

    return (
        <>
            {fields && (
                <ComplaintsDataDialog
                    setComplaintsItem={props.setComplaintsItem}
                    fields={fields}
                    errors={props.complaints.complaintsDataSentErrors}
                    complaintsItem={props.complaintsItem}
                    user={props.user}
                    handleFormValid={handleFormValid}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    complaints: state.complaints,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            sendComplaintsDataInit,
            sendComplaintsData
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComplaintsDataDialogContainer);