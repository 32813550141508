import React, {useState} from 'react';
import ParticipationDialog from "./Main";
import validation from "@tools/validation";
import {bindActionCreators} from "redux";
import {
    sendParticipationDataInit,
    sendParticipationData,
    setParticipationDialogOpen
} from "@store/actions/actionPage";
import {connect} from "react-redux";
import VALIDATORS from "@consts/validators";
import partnerLeadId from "@tools/partnerLeadId";
import {useMount} from "@s-ui/react-hooks";

const ParticipationDialogContainer = (props) => {
    const [fields, setFields] = useState([]);

    const getAgreementValue = (agreement) => {
        if (props.actionPage.actionPageDetails.participation) {
            const participationAcceptedAgreements = props.actionPage.actionPageDetails.participation.acceptedAgreements;
            return participationAcceptedAgreements.some(participationAcceptedAgreement => participationAcceptedAgreement === agreement.uuid) || agreement.type === 'INFORMATION';
        } else {
            return agreement.type === 'INFORMATION';
        }
    }

    const buildFormFields = () => {
        const actionUuid = {
            name: 'actionUuid',
            value: props.actionPage.actionPageDetails.uuid,
            label: '',
            validator: VALIDATORS.HIDDEN_INPUT,
            type: 'hidden',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: '',
            isTouched: false,
            toSend: true,
        }

        const partnerLeadId = {
            name: 'partnerLeadId',
            value: '',
            label: '',
            validator: VALIDATORS.HIDDEN_INPUT,
            type: 'hidden',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: '',
            isTouched: false,
            toSend: true,
        }

        const referralCode = {
            name: 'referralCode',
            value: props.actionPage.actionPageDetails.participation?.referralCode || '',
            label: 'Kod polecający',
            validator: VALIDATORS.REFERRAL_CODE,
            type: 'text',
            isRequired: false,
            isCorrect: true,
            isDisabled: false,
            errorMessage: '',
            isTouched: false,
            toSend: true,
        }

        let agreements = [];

        props.actionPage.actionPageDetails.agreements.forEach(agreement => {
            agreements.push({
                agreementType: agreement.type,
                name: agreement.uuid,
                value: getAgreementValue(agreement),
                label: agreement.content,
                validator: VALIDATORS.CHECKBOX,
                type: 'checkbox',
                class: 'checkbox-field',
                isRequired: agreement.required,
                isCorrect: agreement.type === 'INFORMATION',
                isDisabled: false,
                errorMessage: 'Zgoda wymagana',
                isTouched: false,
                toSend: true,
            });
        });

        setFields([
            ...fields,
            actionUuid,
            partnerLeadId,
            ...agreements,
            referralCode,
        ]);
    }

    const handleChange = (name, value) => {
        const updatedFields = fields.map((field) => {
            if (field.name === name) {
                return {
                    ...field,
                    value: value,
                    isCorrect: validation(fields, name, value),
                    isTouched: true
                };
            }

            return field;
        });

        setFields(updatedFields);
    }

    const handleChangeCheckAllAgreements = () => {
        const updatedFields = fields.map((field) => {
            if (field?.agreementType === "NORMAL") {
                return {
                    ...field,
                    value: true,
                    isCorrect: true,
                    isTouched: true
                };
            }

            return field;
        });

        setFields(updatedFields);
    }

    const handleSubmit = async e => {
        e.preventDefault();

        const isFormValid = fields.every((field) => validation(fields, field.name, field.value));

        if (isFormValid) {
            let data = {};
            let agreements = [];

            fields.forEach((field) => {
                if (field.toSend) {
                    if (field.agreementType) {
                        if (field.value) {
                            agreements.push(field.name);
                        }
                    } else {
                        data[field.name] = field.value;
                    }
                }
            });

            data['agreements'] = agreements;

            if (data['referralCode'] === "") {
                data['referralCode'] = null;
            }

            data['partnerLeadId'] = partnerLeadId.get(props.actionPage.actionPageDetails.uuid) || null;

            props.actions.sendParticipationData(data);
        } else {
            allFieldsValidation();
        }
    }

    const allFieldsValidation = async () => {
        const updatedFields = fields.map((field) => {
            return {
                ...field,
                value: field.value,
                isCorrect: validation(fields, field.name, field.value),
                isTouched: true
            };
        });

        await setFields(updatedFields);

        const notValidField = document.querySelector('.not-valid-field-row');
        if (notValidField) {
            notValidField.scrollIntoView();
        }
    }

    useMount(async () => {
        await props.actions.sendParticipationDataInit();
        buildFormFields();
    });

    return (
        <>
            {fields.length && (
                <ParticipationDialog
                    fields={fields}
                    handleChange={handleChange}
                    handleChangeCheckAllAgreements={handleChangeCheckAllAgreements}
                    handleSubmit={handleSubmit}
                    user={props.user}
                    actionPageDetails={props.actionPage.actionPageDetails}
                    participationData={props.actionPage.participationData}
                    participationDataErrors={props.actionPage.participationDataErrors}
                    sendParticipationDataInit={props.actions.sendParticipationDataInit}
                    isParticipationDialogOpen={props.actionPage.isParticipationDialogOpen}
                    setParticipationDialogOpen={props.actions.setParticipationDialogOpen}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    actionPage: state.actionPage,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            sendParticipationDataInit,
            sendParticipationData,
            setParticipationDialogOpen,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ParticipationDialogContainer);