import {
    SEND_COMPLAINTS_DATA_INIT,
    SEND_COMPLAINTS_DATA_SUCCESS,
    SEND_COMPLAINTS_DATA_FAILURE
} from "@store/types/complaints";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {getAccessToken} from "@tools/tokens";
import LOADER_PLACES from "@consts/loaderPlaces";

const sendComplaintsDataInit = () => ({
    type: SEND_COMPLAINTS_DATA_INIT
});
const sendComplaintsDataSuccess = (data) => ({
    type: SEND_COMPLAINTS_DATA_SUCCESS,
    payload: data
});

const sendComplaintsDataFailure = (errors) => ({
    type: SEND_COMPLAINTS_DATA_FAILURE,
    payload: errors
});

const sendComplaintsData = (data) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.COMPLAINTS_SEND_DATA));
        await dispatch(sendComplaintsDataInit());

        const path = '/api/complaint';

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client('POST', path, data, headers);
            const responseData = response.data.data;
            await dispatch(sendComplaintsDataSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(sendComplaintsDataFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.COMPLAINTS_SEND_DATA));
    }
}

export {
    sendComplaintsDataInit,
    sendComplaintsData
};