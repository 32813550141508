import backgroundImage from "@assets/landingPage/stepsSection/backgroundImage.jpg";
import {makeStyles} from "tss-react/mui";

const useStylesStepsSection = makeStyles()(theme => ({
    stepsSection: {
        display: "table",
        width: "100%",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    title: {
        fontSize: "20px",
        textAlign: "center",
        fontWeight: 600,
        [theme.breakpoints.up('lg')]: {
            fontSize: "24px",
        },
    },
    stepsContainer: {
        backgroundColor: "rgba(255,255,255,0.5)",
        borderRadius: "15px",
        margin: "60px 0",
        padding: "30px 15px",
    },
    steps: {
        margin: "30px 0 0 0",
        [theme.breakpoints.up('lg')]: {
            padding: "0 150px",
        },
    },
    step: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    image: {
        alignSelf: "center",
    },
    text: {
        padding: "15px 0 0 0",
        textAlign: "center",
        fontWeight: 600,
        fontSize: "16px",
    },

}))

export default useStylesStepsSection;