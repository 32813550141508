import React, {useEffect, useState} from 'react';
import {Button, Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import actionImageMask from "@assets/actions/actionImageMask.png";
import PayoutDataDialogContainer from "@components/views/Payout/PayoutDataDialog";
import Snackbar from "@mui/material/Snackbar";
import SkeletonContentContainer from "@components/shared/SkeletonContent";
import SKELETONS from "@consts/skeletons";
import useStylesPayout from "./styles";
import useStylesMain from "@components/layout/Main/styles";
import {useMount} from "@s-ui/react-hooks";

const Payout = (props) => {
    const {classes: mainStyles} = useStylesMain();
    const {classes: payoutStyles} = useStylesPayout();

    const [payoutItem, setPayoutItem] = useState(null);
    const [successMessageActive, setSuccessMessageActive] = useState(false);

    useMount(() => {
        props.actions.getPayout();
    });

    useEffect(() => {
        if (props.payout.payoutDataSent) {
            setSuccessMessageActive(true);
            setPayoutItem(null);
        }
    }, [props.payout.payoutDataSent]);

    useEffect(() => {
        return () => {
            props.actions.getPayoutInit();
        };
    }, [props.actions]);

    const getContent = () => {
        if (props.payout.payoutItems?.length > 0) {
            return (
                <>
                    <Grid container spacing={3}>
                        {props.payout.payoutItems.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                <div className={payoutStyles.action}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <div className={payoutStyles.topContainer}>
                                                <div className={payoutStyles.imageContainer}>
                                                    <img
                                                        className={payoutStyles.imageMask}
                                                        src={actionImageMask}
                                                        alt={item.action.name}
                                                    />
                                                    <img
                                                        className={payoutStyles.image}
                                                        src={item.action.thumbUrl}
                                                        alt={item.action.name}
                                                    />
                                                </div>

                                                <div className={payoutStyles.imageMessage}>
                                                    <div className={payoutStyles.imageMessageTitle}>
                                                        Dane można uzupełnić do
                                                    </div>
                                                    <div className={payoutStyles.imageMessageDate}>
                                                        {item.action.dataCompletionDateTo}
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={0} className={payoutStyles.actionContent}>
                                        <Grid item xs={12}>
                                            <div className={payoutStyles.name}>
                                                {item.action.name}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className={payoutStyles.parametersContainer}>
                                                <div className={payoutStyles.parameter}>
                                                    <div className={payoutStyles.parameterName}>
                                                        Numer konta bankowego
                                                    </div>
                                                    <div className={payoutStyles.parameterValue}>
                                                        {item.participation?.payoutData?.accountNumber
                                                            ? item.participation?.payoutData?.accountNumber
                                                            : 'Nie uzupełniono'
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className={payoutStyles.ctaContainer}>
                                                <Button
                                                    className={
                                                        item.participation?.payoutData?.accountNumber
                                                            ? payoutStyles.ctaUpdateData
                                                            : payoutStyles.ctaFillData
                                                    }
                                                    onClick={() => {
                                                        setPayoutItem(item);
                                                    }}
                                                >
                                                    {item.participation?.payoutData?.accountNumber
                                                        ? (<>Aktualizuj dane</>)
                                                        : (<>Uzupełnij dane</>)
                                                    }
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        ))}
                    </Grid>

                    {payoutItem && (
                        <PayoutDataDialogContainer
                            payoutItem={payoutItem}
                            setPayoutItem={setPayoutItem}
                        />
                    )}

                    <Snackbar
                        open={successMessageActive}
                        autoHideDuration={5000}
                        onClose={() => setSuccessMessageActive(false)}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        message="Dane do wypłaty zostały zapisane"
                        className={payoutStyles.successSnackbar}
                    />
                </>
            )
        } else if (props.payout.payoutItems?.length === 0) {
            return (
                <div className={mainStyles.contentBodyFull}>
                    <DisplayMessageContainer type={DISPLAY_MESSAGES.PAYOUT_EMPTY}/>
                </div>
            )
        } else if (props.payout.payoutItemsErrors) {
            return (
                <div className={mainStyles.contentBodyFull}>
                    <DisplayMessageContainer type={DISPLAY_MESSAGES.SOMETHING_WENT_WRONG}/>
                </div>
            )
        } else if (!props.payout.payoutItems && !props.payout.payoutItemsErrors) {
            return (
                <SkeletonContentContainer type={SKELETONS.PAYOUT}/>
            )
        }
    }

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Wypłata Bonus</h1>
                <div className={mainStyles.contentBody}>
                    {getContent()}
                </div>
            </div>
        </Container>
    );
};

export default Payout;