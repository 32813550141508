import axios from "axios";
import store from "@store/index";
import {refreshToken} from "@store/actions/user";
import {setMaintenance} from "@store/actions/config";
import {getAccessToken, getRefreshToken} from "@tools/tokens";

const {dispatch} = store;

export const client = (method, path, data, headers) => {
    const requestOptions = {
        url: (window?._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL) + path,
        method: method,
        headers: headers,
        data: data
    };

    const service = axios.create({});

    service.interceptors.response.use(response => {
        return response;
    }, async (error) => {
        const isRetry = store.getState().user.auth.refreshTokenRequest;

        if (error.response.status === 401 && !isRetry && getRefreshToken()) {
            await dispatch(refreshToken());

            const accessToken = getAccessToken();
            if (accessToken) {
                requestOptions.headers['Authorization'] = `Bearer ${accessToken}`;

                return axios(requestOptions);
            }
        }

        if (error.response.status === 503) {
            await dispatch(setMaintenance());
        }

        return Promise.reject(error);
    });

    return service({...requestOptions});
}