import React from 'react';
import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ROUTES from "@consts/routes";
import {Link} from "react-router-dom";
import useStylesLandingPage from "@components/views/StartPage/LandingPage/styles";
import useStylesStepsSection from "./styles";

const StepsSection = (props) => {
    const {classes: stepsSectionStyles} = useStylesStepsSection();
    const {classes: landingPageStyles} = useStylesLandingPage();

    return (
        <div className={stepsSectionStyles.stepsSection}>
            <Container>
                <div className={stepsSectionStyles.stepsContainer}>
                    <div className={stepsSectionStyles.title}>Jak wziąć udział w Akcji Bonus?</div>
                    <div className={stepsSectionStyles.steps}>
                        <Grid container spacing={3}>
                            {props.stepsItems.map((step, index)=>(
                                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                    <div className={stepsSectionStyles.step}>
                                        <img className={stepsSectionStyles.image} src={step.image} alt={`Krok nr ${index + 1}`}/>
                                        <p className={stepsSectionStyles.text}>{step.text}</p>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                        <div className={landingPageStyles.buttonContainer}>
                            <Link to={ROUTES.REGISTER}>
                                <Button className={landingPageStyles.button}>Zarejestruj się</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default StepsSection;