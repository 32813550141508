import React from 'react';
import Grid from "@mui/material/Grid";
import actionImageMask from "@assets/actions/actionImageMask.png";
import useStylesActionThumb from "./styles"

const ActionThumb = (props) => {
    const {classes: actionThumbStyles} = useStylesActionThumb();
    const {name, thumbUrl, bonusText} = props.actionDetails;

    return (
        <div className={actionThumbStyles.action}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <div className={actionThumbStyles.topContainer}>
                        <div className={actionThumbStyles.imageContainer}>
                            <img
                                className={actionThumbStyles.imageMask}
                                src={actionImageMask}
                                alt={name}
                            />
                            <img
                                className={actionThumbStyles.image}
                                src={thumbUrl}
                                alt={name}
                            />
                        </div>

                        {bonusText && (
                            <div
                                className={actionThumbStyles.getBonusText}
                                dangerouslySetInnerHTML={{__html: bonusText}}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default ActionThumb;