import {
    REFRESH_TOKEN_INIT,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {logout} from "@store/actions/user";
import {getRefreshToken, setTokens} from "@tools/tokens";

const refreshTokenInit = () => ({
    type: REFRESH_TOKEN_INIT,
});

const refreshTokenSuccess = () => ({
    type: REFRESH_TOKEN_SUCCESS,
});

const refreshTokenFailure = () => ({
    type: REFRESH_TOKEN_FAILURE,
});

const refreshToken = () => {
    return async dispatch => {
        await dispatch(refreshTokenInit());

        const path = '/api/auth/refresh-token';

        const headers = {};

        const data = {
            refreshToken: getRefreshToken(),
            clientId: window?._env_?.REACT_APP_API_CLIENT_ID || process.env.REACT_APP_API_CLIENT_ID,
        };

        try {
            const response = await client('POST', path, data, headers);
            const responseData = response.data.data;

            setTokens(responseData.accessToken, responseData.refreshToken);

            await dispatch(refreshTokenSuccess());
        } catch (error) {
            if (error.response) {
                await dispatch(logout(false));

                await dispatch(refreshTokenFailure());
            }
        }
    }
}

export default refreshToken;