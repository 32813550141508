import VALIDATORS from "@consts/validators";

const validation = (fields, name, value) => {
    let element = fields.find((field) => field.name === name);
    let validator = element.validator;
    let isRequired = element.isRequired;

    if (!isRequired && !value) { //NOT REQUIRED AND EMPTY
        return true;
    } else { //VALIDATE BY VALIDATOR
        let filter = null;

        switch (validator) {
            case VALIDATORS.SELECT:
                return value !== "";

            case VALIDATORS.CHECKBOX:
                return value;

            case VALIDATORS.FIRSTNAME:
                filter = /^.{2,}$/;
                return filter.test(value);

            case VALIDATORS.LASTNAME:
                filter = /^.{2,}$/;
                return filter.test(value);

            case VALIDATORS.CONTRACT_NUMBER:
                return value !== "";

            case VALIDATORS.REASON:
                return value.length >= 20;

            case VALIDATORS.EMAIL:
                return isValidEmail(value);

            case VALIDATORS.CURRENT_PASSWORD:
                return value.length > 0;

            case VALIDATORS.PASSWORD:
                filter = /^(?=.*\d).{8,}$/;
                return filter.test(value);

            case VALIDATORS.PASSWORD_REPEAT:
                const passwordField = fields.find(field => field.name === 'password');
                const passwordValue = passwordField.value;

                return (value === passwordValue && passwordValue !== '');

            case VALIDATORS.PHONE:
                filter = /^[1-9][0-9]{8}$/;
                return filter.test(value);

            case VALIDATORS.CONTACT_MESSAGE:
                return value.length >= 20;

            case VALIDATORS.REFERRAL_CODE:
                return value.length > 0;

            case VALIDATORS.ACTION_ACCESS_TOKEN:
                return value.length > 0;

            case VALIDATORS.ACCOUNT_NUMBER:
                return isValidAccountNumber(value);

            case VALIDATORS.HIDDEN_INPUT:
                return true;

            default:
                console.log(`Validator '${validator}' is not found.`);
                return false;
        }
    }
}

const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regex.test(email);
};

function isValidAccountNumber(value) {
    value = value.replace(/[^0-9]+/g, '');
    const weights = [1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56, 75, 71, 31, 19, 93, 57];

    if (value.length === 26) {
        value = value + "2521";
        value = value.substr(2) + value.substr(0, 2);
        let Z = 0;
        for (let i = 0; i < 30; i++) {
            Z += value[29 - i] * weights[i];
        }
        return Z % 97 === 1;
    } else {
        return false;
    }
}

export default validation;