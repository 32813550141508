import React, {useEffect, useState} from 'react';
import LandingPage from "@components/views/StartPage/LandingPage/Main";
import {bindActionCreators} from "redux";
import {getActionsList} from "@store/actions/actionsList";
import {connect} from "react-redux";

const LandingPageContainer = (props) => {
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);

    const detectScroll = () => {
        const headerHeight = document.getElementsByTagName("header")[0].offsetHeight;
        const actualPosition = window.scrollY;

        setShowScrollTopButton(actualPosition > headerHeight);
    }

    const scrollTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        window.addEventListener('scroll', detectScroll);

        return () => {
            window.removeEventListener('scroll', detectScroll);
        }
    }, []);

    return (
        <LandingPage
            actionsList={props.actionsList}
            scrollTop={scrollTop}
            showScrollTopButton={showScrollTopButton}
        />
    );
}

const mapStateToProps = (state) => ({
    actionsList: state.actionsList
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getActionsList,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingPageContainer);