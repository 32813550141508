import VALIDATORS from "@consts/validators";

const BASIC_FORM_FIELDS_CONFIG = {
    REGISTER: [
        {
            name: 'firstName',
            value: '',
            label: 'Imię',
            validator: VALIDATORS.FIRSTNAME,
            type: 'text',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
        {
            name: 'lastName',
            value: '',
            label: 'Nazwisko',
            validator: VALIDATORS.LASTNAME,
            type: 'text',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
        {
            name: 'email',
            value: '',
            label: 'Adres email',
            validator: VALIDATORS.EMAIL,
            type: 'email',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
        {
            name: 'password',
            value: '',
            label: 'Hasło',
            validator: VALIDATORS.PASSWORD,
            type: 'password',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Hasło musi zawierać min. 8 znaków, w tym przynajmniej jedna cyfra',
            isTouched: false,
            toSend: true,
        },
        {
            name: 'phone',
            value: '',
            label: 'Nr telefonu',
            validator: VALIDATORS.PHONE,
            type: 'phone',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
    ],
    LOGIN: [
        {
            name: 'email',
            value: '',
            label: 'Adres email',
            validator: VALIDATORS.EMAIL,
            type: 'email',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
        {
            name: 'password',
            value: '',
            label: 'Hasło',
            validator: VALIDATORS.CURRENT_PASSWORD,
            type: 'password',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        }
    ],
    RECOVER_PASSWORD: [
        {
            name: 'email',
            value: '',
            label: 'Adres email',
            validator: VALIDATORS.EMAIL,
            type: 'email',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        }
    ],
    RESET_PASSWORD: [
        {
            name: 'password',
            value: '',
            label: 'Hasło',
            validator: VALIDATORS.PASSWORD,
            type: 'password',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Hasło musi zawierać min. 8 znaków, w tym przynajmniej jedna cyfra',
            isTouched: false,
            toSend: true,
        },
        {
            name: 'passwordRepeat',
            value: '',
            label: 'Powtórz hasło',
            validator: VALIDATORS.PASSWORD_REPEAT,
            type: 'password',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Hasła nie są jednakowe',
            isTouched: false,
            toSend: false,
        },
    ],
    USER_PROFILE: {
        USER_DATA: [
            {
                name: 'firstName',
                value: '',
                label: 'Imię',
                validator: VALIDATORS.FIRSTNAME,
                type: 'text',
                isRequired: true,
                isCorrect: false,
                isDisabled: false,
                errorMessage: 'Uzupełnij poprawnie',
                isTouched: false,
                toSend: true,
            },
            {
                name: 'lastName',
                value: '',
                label: 'Nazwisko',
                validator: VALIDATORS.LASTNAME,
                type: 'text',
                isRequired: true,
                isCorrect: false,
                isDisabled: false,
                errorMessage: 'Uzupełnij poprawnie',
                isTouched: false,
                toSend: true,
            },
            {
                name: 'phone',
                value: '',
                label: 'Nr telefonu',
                validator: VALIDATORS.PHONE,
                type: 'phone',
                isRequired: true,
                isCorrect: false,
                isDisabled: false,
                errorMessage: 'Uzupełnij poprawnie',
                isTouched: false,
                toSend: true,
            },
            {
                name: 'email',
                value: '',
                label: 'Adres email (tego pola nie można zmieniać)',
                validator: VALIDATORS.EMAIL,
                type: 'email',
                isRequired: true,
                isCorrect: false,
                isDisabled: true,
                errorMessage: 'Uzupełnij poprawnie',
                isTouched: false,
                toSend: true,
            },
        ],
        CHANGE_PASSWORD: [
            {
                name: 'currentPassword',
                value: '',
                label: 'Aktualne hasło',
                validator: VALIDATORS.CURRENT_PASSWORD,
                type: 'password',
                isRequired: true,
                isCorrect: false,
                isDisabled: false,
                errorMessage: 'Uzupełnij poprawnie',
                isTouched: false,
                toSend: true,
            },
            {
                name: 'password',
                value: '',
                label: 'Nowe hasło',
                validator: VALIDATORS.PASSWORD,
                type: 'password',
                isRequired: true,
                isCorrect: false,
                isDisabled: false,
                errorMessage: 'Hasło musi zawierać min. 8 znaków, w tym przynajmniej jedna cyfra',
                isTouched: false,
                toSend: true,
            },
            {
                name: 'passwordRepeat',
                value: '',
                label: 'Powtórz nowe hasło',
                validator: VALIDATORS.PASSWORD_REPEAT,
                type: 'password',
                isRequired: true,
                isCorrect: false,
                isDisabled: false,
                errorMessage: 'Hasła nie są jednakowe',
                isTouched: false,
                toSend: false,
            },
        ],
        DELETE_ACCOUNT: [
            {
                name: 'password',
                value: '',
                label: 'Hasło',
                validator: VALIDATORS.CURRENT_PASSWORD,
                type: 'password',
                isRequired: true,
                isCorrect: false,
                isDisabled: false,
                errorMessage: 'Uzupełnij poprawnie',
                isTouched: false,
                toSend: true,
            },
        ],
    },
    CONTACT: [
        {
            name: 'email',
            value: '',
            label: 'Adres email',
            validator: VALIDATORS.EMAIL,
            type: 'email',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
        {
            name: 'message',
            value: '',
            label: 'Wiadomość',
            validator: VALIDATORS.CONTACT_MESSAGE,
            type: 'textarea',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie, min. 20 znaków',
            isTouched: false,
            rows: 7,
            toSend: true,
        },
    ],
    PAYOUT: [
        {
            name: 'firstName',
            value: '',
            label: 'Imię',
            validator: VALIDATORS.FIRSTNAME,
            type: 'text',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
        {
            name: 'lastName',
            value: '',
            label: 'Nazwisko',
            validator: VALIDATORS.LASTNAME,
            type: 'text',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
        {
            name: 'accountNumber',
            value: '',
            label: 'Nr konta bankowego',
            validator: VALIDATORS.ACCOUNT_NUMBER,
            type: 'number',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
    ],
    COMPLAINTS: [
        {
            name: 'participationUuid',
            value: '',
            label: '',
            validator: VALIDATORS.HIDDEN_INPUT,
            type: 'hidden',
            isRequired: true,
            isCorrect: true,
            isDisabled: true,
            errorMessage: '',
            isTouched: true,
            toSend: true,
        },
        {
            name: 'contractNumber',
            value: '',
            label: 'ID',
            validator: VALIDATORS.CONTRACT_NUMBER,
            type: 'text',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
        {
            name: 'reason',
            value: '',
            label: 'Uzasadnienie reklamacji',
            validator: VALIDATORS.REASON,
            type: 'textarea',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie, min. 20 znaków',
            isTouched: false,
            rows: 7,
            toSend: true,
        },
    ],
    ACTION_ACCESS_TOKEN: [
        {
            name: 'actionAccessToken',
            value: '',
            label: 'Kod dostępu',
            validator: VALIDATORS.ACTION_ACCESS_TOKEN,
            type: 'text',
            isRequired: true,
            isCorrect: false,
            isDisabled: false,
            errorMessage: 'Uzupełnij poprawnie',
            isTouched: false,
            toSend: true,
        },
    ]
}

export default BASIC_FORM_FIELDS_CONFIG;