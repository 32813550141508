const SKELETONS = {
    ACTIONS: 'ACTIONS',
    STATISTICS: 'STATISTICS',
    COMPLAINTS: 'COMPLAINTS',
    PAYOUT: 'PAYOUT',
    FAQ: 'FAQ',
    STATIC_PAGE_TITLE: 'STATIC_PAGE_TITLE',
    STATIC_PAGE: 'STATIC_PAGE',
    CONTACT: 'CONTACT',
    REGISTER: 'REGISTER',
};

export default SKELETONS;
