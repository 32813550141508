import reactCookies from "react-cookies";

const actionAccessTokenTool = {
    set(slug, actionAccessToken) {
        reactCookies.save(`actionAccessToken_${slug}`, actionAccessToken, {path: '/', maxAge: null});
    },
    get(slug) {
        return reactCookies.load(`actionAccessToken_${slug}`);
    },
    remove(slug) {
        return reactCookies.remove(`actionAccessToken_${slug}`, {path: '/'});
    }
}

export default actionAccessTokenTool;