import {makeStyles} from "tss-react/mui";

const useStylesActionsList = makeStyles()(theme => ({
    action: {
        fontSize: "14px",
        margin: "0 auto",
        maxWidth: "395px",
        borderRadius: "7px",
        overflow: "hidden",
        boxShadow: `0 1px 2px ${theme.palette.color5}`,
        backgroundColor: "#fff",
    },
    actionContent: {
        width: "100%",
        padding: "0 15px",
    },
    name: {
        fontSize: "16px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 600,
        lineHeight: "1.5em",
        minHeight: "4em",
        color: "#000",
    },
    topContainer: {
        position: "relative",
        backgroundColor: theme.palette.color3Darker,
    },
    futureStatusInfoContainer: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: theme.palette.color2,
        color: "#fff",
        fontSize: "13px",
        fontWeight: 600,
        padding: "0 5px",
    },
    imageContainer: {
        position: "relative",
    },
    imageMask: {
        width: "100%",
    },
    image: {
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    imageCtaContainer: {
        alignSelf: "flex-end",
        padding: "0 15px",
        position: "relative",
        [theme.breakpoints.up('lg')]: {
            display: "none",
        },
    },
    imageCtaContainerWithBottomMargin: {
        marginBottom: "15px",
    },
    ctaActionOngoingMobile: {
        color: "#fff",
        textTransform: "none",
        paddingLeft: "15px",
        paddingRight: "15px",
        animation: "activeActionCtaPulse 2s infinite",
    },
    getBonusText: {
        height: "auto",
        padding: "7px 7px 7px 7px",
        background: "linear-gradient(to bottom, rgba(0,0,0,0) 0%, #000 100%)",
        textShadow: "0 0 5px #000",
        color: "#fff",
        fontWeight: 600,
        textAlign: "center",
        fontSize: "14px",
    },
    imageMessage: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
    },
    imageMessageOnGoing: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
    },
    imageMessageEnded: {
        backgroundColor: "rgba(0,0,0,0.5)",
        padding: "5px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textShadow: "0 0 5px #000",
        color: "#fff",
    },
    imageMessageTitle: {
        fontWeight: 400,
    },
    imageMessageDate: {
        fontWeight: 600,
    },
    ctaContainer: {
        borderTop: `1px solid ${theme.palette.color3Darker}`,
        display: "flex",
        justifyContent: "center",
        padding: "15px 0",
    },
    ctaContainerOnlyLaptop: {
        display: "none",
        [theme.breakpoints.up('lg')]: {
            display: "block",
        },
    },
    ctaActionOngoing: {
        backgroundColor: theme.palette.color1,
        color: "#fff",
        textTransform: "none",
        minWidth: "180px",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        }
    },
    ctaActionEnded: {
        border: `1px solid ${theme.palette.color3Darker}`,
        backgroundColor: "#fff",
        color: "000",
        textTransform: "none",
        minWidth: "180px",
        "&:hover": {
            backgroundColor: "#fff",
        }
    },
    actionAdminMessage: {
        backgroundColor: theme.palette.color7,
        padding: "5px",
        textAlign: "center",
        color: "#fff",
    }
}))

export default useStylesActionsList;