import {
    GET_STATIC_PAGE_INIT,
    GET_STATIC_PAGE_SUCCESS,
    GET_STATIC_PAGE_FAILURE
} from "@store/types/staticPage";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const getStaticPageInit = () => ({
    type: GET_STATIC_PAGE_INIT
});

const getStaticPageSuccess = (data) => ({
    type: GET_STATIC_PAGE_SUCCESS,
    payload: data
});

const getStaticPageFailure = (errors) => ({
    type: GET_STATIC_PAGE_FAILURE,
    payload: errors
});

const getStaticPage = (slug) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.STATIC_PAGE));
        await dispatch(getStaticPageInit());

        const path = `/api/page/${slug}`;

        const headers = {};

        try {
            const response = await client('GET', path, null, headers);
            const responseData = response.data.data;

            await dispatch(getStaticPageSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(getStaticPageFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.STATIC_PAGE));
    }
}

export {
    getStaticPageInit,
    getStaticPage
};