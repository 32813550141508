import React from 'react';
import LogoCircle from "@assets/logos/logo-circle.png";
import LOADER_TYPES from "@consts/loaderTypes";
import LOADER_CONFIG from "@consts/loaderConfig";
import useStylesLoader from "./styles";

const Loader = (props) => {
    const {classes: loaderStyles, cx} = useStylesLoader();

    const showLoader = () => {
        const loaderConfig = LOADER_CONFIG.find(item => item.name === props.name);

        if (loaderConfig.type === LOADER_TYPES.FULLSCREEN_TRANSPARENT) {
            return (
                <div
                    className={cx(
                        loaderStyles.fullScreenTransparentLoader,
                        props.isActive && loaderStyles.fullScreenTransparentLoaderActive
                    )}
                >
                    <img className={loaderStyles.circle} src={LogoCircle} alt="Loader"/>
                </div>
            )
        } else if (loaderConfig.type === LOADER_TYPES.FULLSCREEN_FOR_SKELETON) {
            return (
                <div
                    className={cx(
                        loaderStyles.fullScreenForSkeletonLoader,
                        props.isActive && loaderStyles.fullScreenForSkeletonLoaderActive
                    )}
                />
            )
        } else {
            return (
                <div
                    className={cx(
                        loaderStyles.fullScreenLoader,
                        props.isActive && loaderStyles.fullScreenLoaderActive
                    )}
                >
                    <div className={loaderStyles.circles}>
                        <img className={loaderStyles.circle} src={LogoCircle} alt="Loader"/>
                        <img className={loaderStyles.circle} src={LogoCircle} alt="Loader"/>
                        <img className={loaderStyles.circle} src={LogoCircle} alt="Loader"/>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            {showLoader()}
        </>
    );
}

export default Loader;