import React from "react";
import Layout from "@components/layout/Layout/Main";

const LayoutContainer = (props) => {
    return (
        <Layout {...props}/>
    );
}

export default LayoutContainer;

