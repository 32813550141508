import React from 'react';
import {Container} from "@mui/material";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import SkeletonContentContainer from "@components/shared/SkeletonContent";
import SKELETONS from "@consts/skeletons";
import useStylesMain from "@components/layout/Main/styles";
import ActiveActionsContainer from "@components/views/StartPage/ActionsList/ActiveActions";
import EndedActionsContainer from "@components/views/StartPage/ActionsList/EndedActions";
import Grid from "@mui/material/Grid";

const ActionsList = (props) => {
    const {classes: mainStyles} = useStylesMain();

    const ongoingActionsItems = () => {
        if (props.actionsItems?.future?.length > 0 || props.actionsItems?.ongoing?.length > 0) {
            return (
                <Grid container spacing={3}>
                    {props.actionsItems.future && <ActiveActionsContainer actionsItems={props.actionsItems.future}/>}
                    {props.actionsItems.ongoing && <ActiveActionsContainer actionsItems={props.actionsItems.ongoing}/>}
                </Grid>
            )
        } else if (props.actionsItems?.ongoing?.length === 0) {
            return (
                <div className={mainStyles.contentBodyFull}>
                    <DisplayMessageContainer type={DISPLAY_MESSAGES.ACTIONS_NO_ACTIVE_ACTIONS}/>
                </div>
            )
        } else if (props.errors) {
            return (
                <div className={mainStyles.contentBodyFull}>
                    <DisplayMessageContainer type={DISPLAY_MESSAGES.SOMETHING_WENT_WRONG}/>
                </div>
            )
        } else if (!props.actionsItems && !props.errors) {
            return (
                <SkeletonContentContainer type={SKELETONS.ACTIONS}/>
            )
        }
    }

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Akcje aktywne</h1>
                <div className={mainStyles.contentBody}>
                    {ongoingActionsItems()}
                </div>
            </div>

            {props.actionsItems?.ended?.length > 0 && (
                <div className={mainStyles.content}>
                    <h1 className={mainStyles.contentTitle}>Akcje zakończone</h1>
                    <div className={mainStyles.contentBody}>
                        <Grid container spacing={3}>
                            <EndedActionsContainer actionsItems={props.actionsItems.ended}/>
                        </Grid>
                    </div>
                </div>
            )}
        </Container>
    );
}

export default ActionsList;