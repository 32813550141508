import React from "react";
import Loader from "@components/layout/Loader/Main";
import {connect} from "react-redux";

const LoaderContainer = (props) => {
    return (
        <>
            {props.config.multiLoader.map((loader, index) => (
                <Loader name={loader.name} isActive={loader.isActive} key={index}/>
            ))}
        </>
    )
}

const mapStateToProps = (state) => ({
    config: state.config,
});

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoaderContainer);

