import {makeStyles} from "tss-react/mui";

const useStylesContact = makeStyles()(theme => ({
    contact: {
        width: "100%",
        margin: "30px 0",
    },
    topText: {
        width: "100%",
        textAlign: "center",
    },
    topTextTitle: {
        textAlign: "center",
    },
    bottomText: {
        width: "100%",
        margin: "60px 0 0 0",
    },
    bottomTextTitle: {
        textAlign: "center",
        margin: "15px 0",
    },
    tabButtons: {
        display: "flex",
        justifyContent: "center",
        borderBottom: `1px solid ${theme.palette.color3}`,
        alignItems: "flex-start",
    },
    tabButton: {
        display: "inline-block",
        padding: "10px 5px",
        borderRadius: 0,
        backgroundColor: "#fff",
        color: "#000",
        width: "100%",
        maxWidth: "350px",
        textTransform: "none",
        fontWeight: 600,
        borderBottom: "2px solid transparent",
        "&:hover": {
            backgroundColor: theme.palette.color1Lighter,
        },
        "&:first-of-type": {
            margin: "0 5px 0 0",
        },
        "&:last-of-type": {
            margin: "0 0 0 5px",
        },
        "& span": {
            display: "inline-block",
        },
    },
    tabButtonActive: {
        borderBottom: `2px solid ${theme.palette.color1}`,
        backgroundColor: "transparent",
    },
    icon: {
        margin: "5px auto 0 auto",
        display: "block",
        width: "48px",
        height: "48px",
        color: theme.palette.color1,
    },
    contactType: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "30px 0 0 0",
    },
    messageContainer: {
        backgroundColor: theme.palette.color1Lighter,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        maxWidth: "350px",
        padding: "30px 10px",
        borderRadius: "5px",
        textAlign: "center",
    },
    emailContainer: {
        backgroundColor: theme.palette.color1Lighter,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        maxWidth: "350px",
        padding: "60px 15px",
        borderRadius: "5px",
        textAlign: "center",
    },
    email: {
        fontWeight: 600,
        fontSize: "16px",
    },
    emailDescription: {
        margin: "10px 0 0 0",
        fontSize: "14px",
    },
    noAdminInfoMessage: {
        textAlign: "center",
    },
    adminInfo: {
        width: "100%",
        textAlign: "justify",
        margin: "50px 0 0 0",
        "& p": {
            textAlign: "justify",
            margin: "0 0 10px 0",
        }
    },
}));

export default useStylesContact;