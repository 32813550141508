const ROUTES = {
    HOME: '/',
    LOGIN: '/logowanie',
    REGISTER: '/rejestracja',
    REGISTER_COMPLETED: '/rejestracja-zakonczona',
    ACTIVATE_ACCOUNT: '/aktywuj-konto/:activationToken',
    RESET_PASSWORD: '/zmien-haslo/:resetPasswordToken',
    RECOVER_PASSWORD: '/odzyskaj-haslo',
    STATISTICS: '/statystyki',
    COMPLAINTS: '/reklamacje',
    USER_PROFILE: '/moje-dane',
    PAYOUT: '/wyplata',
    ACTION_PAGE_PARTICIPATION: '/akcja/:slug/:takePart',
    ACTION_PAGE: '/akcja/:slug/',
    CONTACT: '/kontakt',
    FAQ: '/pytania-i-odpowiedzi',
    REGULATION: '/strona/regulamin-serwisu',
    PRIVACY_POLICY: '/strona/polityka-prywatnosci-serwisu',
    STATIC_PAGE: '/strona/:slug',
    NOT_FOUND: "*",
};

export default ROUTES;
