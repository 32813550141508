import React from 'react';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import {ErrorOutline} from "@mui/icons-material";
import API_ERRORS from "@consts/apiErrors";
import {CircularProgress} from "@mui/material";
import actionImageMask from "@assets/actions/actionImageMask.png";
import useStylesParticipationDialog from "./styles";

const ParticipationDialog = (props) => {
    const {classes: participationDialogStyles, cx} = useStylesParticipationDialog();

    const getReferralCodeSwitcherChecked = () => {
        return !!props.fields.find(field => field.name === "referralCode").value;
    }

    const [referralCodeSwitcherChecked, setReferralCodeSwitcherChecked] = React.useState(getReferralCodeSwitcherChecked);

    const setSwitcher = () => {
        props.handleChange("referralCode", '');
        setReferralCodeSwitcherChecked(!referralCodeSwitcherChecked);
    }

    const searchUncheckedAgreement = () => {
        return props.fields.some(field =>
            field.type === "checkbox" &&
            field.agreementType !== "INFORMATION" &&
            !field.value
        );
    }

    const isCheckAllAgreementsButtonVisible = searchUncheckedAgreement();

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={props.isParticipationDialogOpen}
                aria-labelledby="targetDialog"
            >
                {props.participationData ? (
                    <>
                        <div className={participationDialogStyles.redirectContent}>
                            <div className={participationDialogStyles.redirectImageContainer}>
                                <img
                                    className={participationDialogStyles.redirectImageMask}
                                    src={actionImageMask}
                                    alt={props.actionPageDetails.name}
                                />
                                <img
                                    className={participationDialogStyles.redirectImage}
                                    src={props.actionPageDetails.thumbUrl}
                                    alt={props.actionPageDetails.name}
                                />
                            </div>
                            <div className={participationDialogStyles.redirectTitle}>
                                Weźmiesz udział w Akcji Bonus "{props.actionPageDetails.name}"
                            </div>
                            <div className={participationDialogStyles.redirectDescription}>
                                Trwa przekierowanie... <CircularProgress
                                className={participationDialogStyles.redirectLoader}/>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <DialogTitle id="targetDialog" className={participationDialogStyles.title}>
                            <div className={participationDialogStyles.titleText}>
                                Udział w akcji
                            </div>
                            <div className={participationDialogStyles.titleDescription}>
                                {props.user.auth.userData.email} ({props.user.auth.userData.firstName} {props.user.auth.userData.lastName})
                            </div>
                        </DialogTitle>
                        <DialogContent className={participationDialogStyles.participationDialogContent}>
                            {props.actionPageDetails.participation && props.actionPageDetails?.infoTextAfterParticipation && (
                                <div className={participationDialogStyles.participationMessage}>
                                    {props.actionPageDetails?.infoTextAfterParticipation}
                                </div>
                            )}
                            <form onSubmit={props.handleSubmit} noValidate autoComplete={"off"}>
                                {props.actionPageDetails.referralsEnabled && (
                                    <div className={participationDialogStyles.referralCodeContainer}>
                                        <div className={participationDialogStyles.referralCodeTitle}>
                                            Masz kod polecający od innego użytkownika?
                                        </div>

                                        <FormControlLabel
                                            className={participationDialogStyles.referralCodeSwitcherLabel}
                                            control={<Switch
                                                color="default"
                                                className={participationDialogStyles.referralCodeSwitcher}
                                                checked={referralCodeSwitcherChecked}
                                                onChange={() => setSwitcher()}
                                                name="referralCodeSwitch"/>}
                                            label={"Chcę dodać kod"}
                                        />
                                        {referralCodeSwitcherChecked &&
                                            <>
                                                <div className={participationDialogStyles.referralCodeDescription}>
                                                    Pamiętaj, kod nie jest obowiązkowy. Pomiń ten krok jeśli nie
                                                    posiadasz kodu.
                                                </div>

                                                {props.fields && props.fields.filter(field => field.name === "referralCode").map((input, index) => (
                                                    <div
                                                        className={participationDialogStyles.referralCodeFieldContainer}
                                                        key={index}>
                                                        <TextField
                                                            inputProps={{className: participationDialogStyles.referralCodeField}}
                                                            id={input.name}
                                                            name={input.name}
                                                            label={input.label}
                                                            variant="outlined"
                                                            value={input.value}
                                                            onChange={event => props.handleChange(event.target.name, event.target.value)}
                                                        />
                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </div>
                                )}

                                <div className={participationDialogStyles.agreementsContainer}>
                                    <div className={participationDialogStyles.agreementsTitle}>Zgody</div>
                                    <div className={participationDialogStyles.agreements}>
                                        {props.fields && props.fields.filter(field =>
                                            field.type === "checkbox" &&
                                            field.agreementType !== "INFORMATION"
                                        ).map((agreement, index) => (
                                            <div className={
                                                cx(participationDialogStyles.agreementContainer, !agreement.isCorrect && agreement.isTouched && 'not-valid-field-row')
                                            } key={index}>
                                                <FormControlLabel
                                                    className={participationDialogStyles.agreementField}
                                                    control={
                                                        <Checkbox
                                                            color="default"
                                                            className={participationDialogStyles.agreementCheckbox}
                                                            id={agreement.name}
                                                            name={agreement.name}
                                                            checked={agreement.value}
                                                            onChange={event => props.handleChange(event.target.name, event.target.checked)}
                                                            inputProps={{'aria-label': 'primary checkbox'}}
                                                        />
                                                    }
                                                    label={<p className={participationDialogStyles.labelParagraph}
                                                              dangerouslySetInnerHTML={{__html: agreement.label}}/>}
                                                />
                                                {!agreement.isCorrect && agreement.isTouched && (
                                                    <div className={participationDialogStyles.fieldError}>
                                                        <ErrorOutline/>Pole wymagane
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        {isCheckAllAgreementsButtonVisible && (
                                            <div className={participationDialogStyles.checkAllAgreementsContainer}>
                                                <Button
                                                    onClick={() => props.handleChangeCheckAllAgreements()}
                                                    className={participationDialogStyles.checkAllAgreementsButton}
                                                    type={"button"}
                                                >
                                                    Zaznacz wszystkie zgody
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </DialogContent>
                        <DialogActions className={participationDialogStyles.actionsContainer}>
                            {props.participationDataErrors && <div className={participationDialogStyles.serverErrors}>
                                {props.participationDataErrors.map((error, index) => {
                                    const found = API_ERRORS.find((apiError) => apiError.code === error.code);

                                    return (
                                        <div className={participationDialogStyles.serverError} key={index}>
                                            <ErrorOutline/> {found ? found.message : "Nieznany błąd"}
                                        </div>
                                    )
                                })}
                            </div>}

                            <div className={participationDialogStyles.buttonsContainer}>
                                <Button
                                    className={participationDialogStyles.cancelButton}
                                    onClick={() => {
                                        props.setParticipationDialogOpen(false);
                                        props.sendParticipationDataInit();
                                    }}
                                >
                                    Wróć
                                </Button>
                                <Button
                                    className={participationDialogStyles.submitButton}
                                    onClick={props.handleSubmit}
                                    type="submit">
                                    Weź udział
                                </Button>
                            </div>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
}

export default ParticipationDialog;