import {
    RESET_PASSWORD_INIT,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const resetPasswordInit = () => ({
    type: RESET_PASSWORD_INIT
});

const resetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS
});

const resetPasswordFailure = (errors) => ({
    type: RESET_PASSWORD_FAILURE,
    payload: errors
});

const resetPassword = (data) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.PASSWORD_RESET));
        await dispatch(resetPasswordInit());

        const path = '/api/reset-password';

        const headers = {};

        try {
            const response = await client('POST', path, data, headers);
            const responseData = response.data.data;

            await dispatch(resetPasswordSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(resetPasswordFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.PASSWORD_RESET));
    }
}
export {
    resetPassword,
};