import {makeStyles} from "tss-react/mui";

const useStylesFooter = makeStyles()(theme => ({
    footer: {
        color: theme.palette.color4,
        padding: "10px",
        textAlign: "center",
    },
}));

export default useStylesFooter;