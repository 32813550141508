/* eslint-disable */
class Ga4Interface {
    constructor({
                    product,
                    productionUrl = '',
                    level = 'owned',
                    debug = false,
                    categoriesObject = {},
                    watchVirtuals = false,
                    watchClicks = true,
                    highlightEvents = false,
                    isError = false,
                    isLoggedIn = '',
                    platform = 'rankomat',
                }) {

        window.dataLayer = window.dataLayer || [];

        this.product = product;
        this.productionUrl = productionUrl;
        this.level = level;

        this.productionEnvironment = {
            bonusebrokerpartner: 'bonus.ebrokerpartner.pl',
        }

        this.isLoggedIn = isLoggedIn;

        this.platform = platform
        this.step = ''

        this.categoriesObject = categoriesObject;
        this.watchVirtuals = watchVirtuals;
        this.isError = isError;

        this.highlightEvents = highlightEvents;
        this.debug = debug

        this.schema = {
            event: {
                value: '',
                type: String,
                default: null,
                required: true
            },
            event_category: {
                value: '',
                type: String,
                default: null,
                required: false
            },
            event_type: {
                value: '',
                type: String,
                default: null,
                required: false
            },
            event_action: {
                value: '',
                type: String,
                default: null,
                required: false
            },
            page_name: {
                value: '',
                type: String,
                default: this.pageName.bind(this)({}),
                required: true
            },
            country: {
                value: '',
                type: String,
                default: 'pl',
                required: true
            },
            cg1: {
                value: '',
                type: String,
                default: this.getCategoryData({key: 'cg1'}),
                required: true
            },
            cg2: {
                value: '',
                type: String,
                default: this.getCategoryData({key: 'cg2'}),
                required: true
            },
            cg3: {
                value: '',
                type: String,
                default: this.getCategoryData({key: 'cg3'}),
                required: true
            },
            cg4: {
                value: '',
                type: String,
                default: this.getCategoryData({key: 'cg4'}),
                required: true
            },
            cg5: {
                value: '',
                type: String,
                default: this.getCategoryData({key: 'cg5'}),
                required: true
            },
            page_env: {
                value: '',
                type: String,
                default: this.pageEnv,
                required: true
            },
            page_content_type: {
                value: '',
                type: String,
                default: this.getCategoryData({key: 'page_content_type'}),
                required: true
            },
            p_product_family: {
                value: '',
                type: String,
                default: this.getCategoryData({key: 'p_product_family'}),
                required: true
            },
            p_product: {
                value: '',
                type: String,
                default: this.getCategoryData({key: 'p_product'}),
                required: true
            },
            login_status: {
                value: '',
                type: String,
                default: this.getLoginStatus(),
                required: true
            },
            uid: {
                value: '',
                type: String,
                default: '',
                required: true
            },
        }

        this.dataLayerSchema = {
            event: {
                value: '',
                type: String,
                default: null,
                required: true
            },
            event_category: {
                value: '',
                type: String,
                default: null,
                required: true
            },
            event_action: {
                value: '',
                type: String,
                default: null,
                required: true
            },
            event_label: {
                value: '',
                type: String,
                default: null,
                required: true
            },
        }

        this.highlightClass = 'ga4i-highlight-element';

        // window.addEventListener('DOMContentLoaded', (event) => {
        if (this.isError) {
            this.errorEvent();
        } else {
            this.pageView();
        }
        if (this.watchVirtuals) this.watchVirtual({});
        if (watchClicks) {
            this.watchClicks();
        }
        // });

        this.calculatorResultsSettings = {
            offerBoxClass: null,
            offersList: [],
            item: {},
            itemPattern: {},
            btnClosestGa4iIDClass: []
        }

    }

    getLoginStatus() {
        return this.isLoggedIn ? 'logged-in' : 'logged-out';
    }

    deepSearch(obj /* state */, keyPath /* ['nested','key'] */) {
        // Dodać cachowanie
        let lastKeyIndex = keyPath.length - 1;
        for (var i = 0; i < lastKeyIndex; ++i) {
            let key = keyPath[i];
            if (!(key in obj)) {
                obj[key] = {}
            }
            obj = obj[key];
        }
        return obj[keyPath[lastKeyIndex]]
    }

    pageName({cutProductName = false, setStep = null}) {
        if (!this.level) throw `[Ga4Interface] Please set level in constructor`

        // const product = (this.product === 'rankomat' || cutProductName) ? '' : `/${this.product}`;
        if (this.isError) {
            return `rankomat/${this.level}/error`;
        }

        // let prefix = this.getCategoryData({key: 'page_name_prefix'});
        // if (typeof prefix !== 'undefined' && prefix) {
        //     prefix = '/' + prefix;
        // } else {
        //     prefix = '';
        // }
        // let suffix = this.getCategoryData({key: 'page_name_suffix'});
        // if (typeof suffix !== 'undefined' && suffix) {
        //     suffix = '/' + suffix;
        // } else {
        //     suffix = '';
        // }

        let p_product = this.getCategoryData({key: 'p_product'});
        if (typeof p_product !== 'undefined' && p_product !== 'uncategorized' && p_product) {
            p_product = '/' + p_product;
        } else {
            p_product = '';
        }

        let p_product_family = this.getCategoryData({key: 'p_product_family'});
        if (typeof p_product_family !== 'undefined' && p_product_family !== 'uncategorized' && p_product_family) {
            p_product_family = '/' + p_product_family;
        } else {
            p_product_family = '';
        }

        let step = (this.step || setStep !== null) ? `/${setStep || this.step}` : '';

        return `rankomat/${this.level}${p_product_family}${p_product}${step}`
    }

    get pageEnv() {
        if (!this.customProductionEnv() && !this.checkIfEnvExists()) throw `[Ga4Interface] There is no prepared environment in 'productionEnvironment' for ${this.product}`

        const prodEnv = 'prod';

        return (window.location.host === this.productionEnvironment[this.level]) ? prodEnv : 'test'
    }

    customProductionEnv() {
        return (this.productionUrl) ? this.addCustomEnv() : false
    }

    addCustomEnv() {
        this.productionEnvironment[this.level] = String(this.productionUrl);
    }

    checkIfEnvExists() {
        return (this.productionEnvironment.hasOwnProperty(this.level))
    }

    pageView() {
        if (this.highlightEvents) {
            const body = document.querySelector('body');
            this.highlight({element: body, eventType: 'pageView', attributeName: 'pageView', color: 'lightgreen'})
        }
        ;
        this.addEvent({event: 'page_view'})
    }

    errorEvent() {
        let eventData = {
            event: 'customTracking',
            event_type: 'error',
            event_category: window.location.href,
        }
        let eventAction = this.getCategoryData({key: 'referer'});
        if (!(typeof eventAction === 'undefined' || !eventAction || eventAction === 'null')) {
            eventData.event_action = eventAction;
        }
        this.addEvent(eventData)
    }

    addEvent() {
        let [data, ...args] = arguments;

        if (!data) throw '[Ga4Interface] AddEvent: needs some arguments.'

        const schema = data.schema || this.schema;

        data = this.compareToSchema({data, schema});

        const fullSchemaData = this.fillSchema({data: data.schemaCorrect, schema});
        const outOfSchemaData = data.outOfSchema;

        if (this.debug) console.log(`[Ga4Interface Debug] addEvent: Added:`, {...fullSchemaData, ...outOfSchemaData});
        window.dataLayer.push({...fullSchemaData, ...outOfSchemaData})
    }

    compareToSchema({data, schema}) {
        // if (this.debug) console.log(`[Ga4Interface Debug] compareToSchema data:`, data);

        const dataKeys = Object.keys(data);
        let schemaCorrect = {}, outOfSchema = {};

        for (let dataKey of dataKeys) {
            if (schema.hasOwnProperty(dataKey)) schemaCorrect[dataKey] = data[dataKey]
            else outOfSchema[dataKey] = data[dataKey]
        }

        // if (this.debug) console.log(`[Ga4Interface Debug] compareToSchema schemaCorrect:`, schemaCorrect);
        // if (this.debug) console.log(`[Ga4Interface Debug] compareToSchema outOfSchema:`, outOfSchema);

        return {schemaCorrect, outOfSchema}
    }

    fillSchema({data, schema}) {
        const schemaKeys = Object.keys(schema);
        let filledData = {};

        for (let key in schema) {
            const item = schema[key];
            if (data.hasOwnProperty(key)) {
                filledData[key] = item.type(data[key])
            } else {
                const defaultValue = (typeof item.default !== 'function') ? item.default : item.default();
                if (defaultValue !== null || item.required === true) {
                    filledData[key] = item.type(defaultValue);
                }
            }
        }

        return filledData
    }

    isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    getCategoryData({key = null}) {
        if (!this.categoriesObject) throw '[Ga4Interface] getCategoryData: You have to set `categoriesObject` with specific categories in constructor'
        if (!key) throw '[Ga4Interface] getCategoryData: needs `key` param.'

        return this.categoriesObject[key];
    }

    watchClicks() {
        if (this.debug) console.log(`[Ga4Interface Debug] watchClicks: Watching for Clicks`);
        const clickAttributes = ['data-ga4i-click-link', 'data-ga4i-click-inter'];

        clickAttributes.forEach(attribute => {
            const allElements = document.querySelectorAll(`*[${attribute}]`);
            allElements.forEach(element => {
                if (this.highlightEvents) this.highlight({element, eventType: 'click', attributeName: attribute})

                this.addDeepListeners({element, attribute})
            });
        });

    }


    addDeepListeners({element, attribute}) {
        var hrefTo = (element.href) ? element.href : '';
        if (hrefTo) {
            hrefTo = new URL(hrefTo)
        }
        var ctaText = (element.innerText) ? element.innerText : '';

        (function godeep({element, attribute}) {
            if (element.children.length > 0) {
                [...element.children].forEach(element => {
                    godeep.bind(this)({element, attribute})
                });
            }

            element.addEventListener('click', (e) => {
                // e.preventDefault();
                e.stopPropagation();

                let dataLayer;
                switch (attribute) {
                    case 'data-ga4i-click-link':
                        const isInner = (window.location.hostname === hrefTo.hostname);

                        if (isInner) {
                            dataLayer = {
                                event: 'page_internal_links',
                                event_category: `page.${this.schema.cg2.default}`,
                                event_action: `link.from.${window.location.hostname}${window.location.pathname}.to.${hrefTo.hostname}${hrefTo.pathname}`,
                                event_label: `${ctaText}`
                            }
                        } else {
                            dataLayer = {
                                event: 'page_external_links',
                                event_category: `page.${this.schema.cg2.default}`,
                                event_action: `external.link.from.${window.location.hostname}${window.location.pathname}.to.${hrefTo.hostname}${hrefTo.pathname}`,
                                event_label: `${ctaText}`
                            }
                        }
                        break;

                    case 'data-ga4i-click-inter':
                        dataLayer = {
                            event: 'page_clicks',
                            event_category: `page.${this.schema.cg2.default}`,
                            event_action: `click.on.${window.location.hostname}${window.location.pathname}`,
                            event_label: `${ctaText}`
                        }
                        break;
                    default:
                        break;
                }

                this.addDataLayer({...dataLayer})
            })
        }).bind(this)({element, attribute})
    }

    addDataLayer() {
        let [data, ...args] = arguments;
        if (!data) throw '[Ga4Interface] addDataLayer: needs some arguments.'

        this.addEvent({...data, schema: this.dataLayerSchema})
    }

    watchVirtual({elementAttribute = 'data-ga4i-open-virtual', event = {event: 'virtual_page_view'}}) {
        if (Object.keys(event).length === 0) throw '[Ga4Interface] Click: There is no event to add.'
        if (this.debug) console.log(`[Ga4Interface Debug] watchVirtual: Watching for \`${elementAttribute}\``);

        document.addEventListener('click', (e) => {
            if (e.target && e.target.hasAttribute(elementAttribute)) {
                this.addEvent({...event})
                if (this.debug) console.log(`[Ga4Interface Debug] watchVirtual: Click to show Virtual Page:`, window.dataLayer);
            }
        })
    }

    addVirtual() {
        let [event, ...args] = arguments;
        if (!event) event = {event: 'virtual_page_view'};

        this.addEvent({...event})
        if (this.debug) console.log(`[Ga4Interface Debug] Adding Virtual Page:`, window.dataLayer);
    }

    highlight({element, eventType, attributeName, color = 'red', leftOffset = '0px'}) {
        // debugger;
        const newClassName = `${this.highlightClass}__${eventType}--${attributeName}`

        this.addHighlightClass({newClassName, eventType, attributeName, color, leftOffset});

        element.classList.add(newClassName);

        const div = document.createElement("div");
        div.setAttribute("class", `${newClassName}--makeAbsolute`);
        div.innerText = `${eventType}: ${attributeName}`
        element.prepend(div)
    }

    hasThisClass({className}) {
        const styleTags = document.querySelectorAll('style')
        return [...styleTags].find((el) => el.childNodes[0].data.indexOf(className) > -1);
    }

    addHighlightClass({newClassName, eventType, attributeName, color, leftOffset}) {
        if (!this.hasThisClass({className: newClassName})) {
            var css = `.${newClassName} {
                            position: relative;
                            border: 1px solid ${color} !important;
                            z-index: 1;
                        }
                        .${newClassName}--makeAbsolute {
                            height: auto; 
                            width: max-content; 
                            padding: 0px 3px;
                            position: absolute; 
                            background-color: ${color};
                            color: white;
                            font-size: 10px;
                            font-weight: 400;
                            top: 0px;
                            left: ${leftOffset};
                            text-transform: initial;
                            z-index: 9999999;
                            }`,
                head = document.head || document.getElementsByTagName('head')[0],
                style = document.createElement('style');

            head.appendChild(style);

            style.type = 'text/css';
            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }
        }
    }

    calculatorFormEvents({
                             calculatorWrapperClass = '',
                             nextStepButtonClass = '',
                             customInput = '.question-answer .pic',
                             errorWrapperClass = '.error-box',
                             questionClassForId = '.question-box',
                             agreementClassForId = '.agreements-wrapper',
                             step = 'full-application',
                             observeDOM = false
                         }) {

        if (!calculatorWrapperClass) throw new Error('[Ga4Interface Debug] calculatorFormEvents: When u observe some data u should define ;calculatorWrapperClass;')
        if (!nextStepButtonClass) throw new Error('[Ga4Interface Debug] calculatorFormEvents: U need to define next button class')

        this.step = step

        this.calculatorStart({
            questionClassForId,
            agreementClassForId
        });
        this.watchScrollDepth();

        this.calculatorForm({
            calculatorWrapperClass,
            customInput,
            errorWrapperClass,
            questionClassForId,
            agreementClassForId,
            observeDOM,
        });
    }

    calculatorStart({
                        questionClassForId,
                        agreementClassForId
                    }) {
        const bodyElement = document.querySelector('body');

        if (this.highlightEvents) {

            this.highlight({
                element: bodyElement,
                eventType: 'calculatorStart',
                attributeName: 'firstClickInBody',
                color: 'lightpink',
                leftOffset: '100px'
            })
        }
        ;

        const bodyClick = (e) => {
            const getQuestionID = ({element}) => {
                const fieldName = element.closest(questionClassForId) || element.closest(agreementClassForId)
                return (fieldName) ? fieldName.id : null
            }

            const fieldName = getQuestionID({element: e.target});
            this.addEvent({
                event: 'calculator-start',
                event_type: 'calculator',
                event_category: `form.${this.schema.p_product.default}`,
                event_label: (fieldName) ? `form.${this.step}.${fieldName}` : '',
                event_action: (fieldName) ? 'form.text' : '',
                product_family: this.schema.p_product_family.default,
                product: this.schema.p_product.default,
                step: this.step,
                platform: this.platform,
                page_name: this.pageName({cutProductName: true})
            })

            bodyElement.removeEventListener('click', bodyClick)
        }

        bodyElement.addEventListener('click', bodyClick)
    }

    watchScrollDepth() {
        const bodyElement = document.querySelector('body');
        const fullPageHeight = bodyElement.offsetHeight;

        const depth = {
            d25: Math.floor(fullPageHeight * 0.25),
            d50: Math.floor(fullPageHeight * 0.50),
            d75: Math.floor(fullPageHeight * 0.75)
        }

        const activeDepth = {
            d25: true,
            d50: true,
            d75: true,
            d100: true,
        }

        window.addEventListener('scroll', () => {
            const screenHeight = window.innerHeight;
            const topScrollPosition = window.pageYOffset;
            const bottomScrollPosition = screenHeight + topScrollPosition;

            if (bottomScrollPosition >= depth.d25 && activeDepth.d25) {
                this.addEvent({
                    event: 'customTracking',
                    event_type: 'scroll-depth',
                    event_category: `form.${this.schema.p_product.default}`,
                    event_label: 'per-cent',
                    event_action: '25%',
                    product_family: this.schema.p_product_family.default,
                    product: this.schema.p_product.default,
                    step: this.step,
                    platform: this.platform,
                    page_name: this.pageName({cutProductName: true})
                })
                activeDepth.d25 = false
            }

            if (bottomScrollPosition >= depth.d50 && activeDepth.d50) {
                this.addEvent({
                    event: 'customTracking',
                    event_type: 'scroll-depth',
                    event_category: `form.${this.schema.p_product.default}`,
                    event_label: 'per-cent',
                    event_action: '50%',
                    product_family: this.schema.p_product_family.default,
                    product: this.schema.p_product.default,
                    step: this.step,
                    platform: this.platform,
                    page_name: this.pageName({cutProductName: true})
                })
                activeDepth.d50 = false
            }

            if (bottomScrollPosition >= depth.d75 && activeDepth.d75) {
                this.addEvent({
                    event: 'customTracking',
                    event_type: 'scroll-depth',
                    event_category: `form.${this.schema.p_product.default}`,
                    event_label: 'per-cent',
                    event_action: '75%',
                    product_family: this.schema.p_product_family.default,
                    product: this.schema.p_product.default,
                    step: this.step,
                    platform: this.platform,
                    page_name: this.pageName({cutProductName: true})
                })
                activeDepth.d75 = false
            }

            if (bottomScrollPosition >= fullPageHeight && activeDepth.d100) {
                this.addEvent({
                    event: 'customTracking',
                    event_type: 'scroll-depth',
                    event_category: `form.${this.schema.p_product.default}`,
                    event_label: 'per-cent',
                    event_action: '100%',
                    product_family: this.schema.p_product_family.default,
                    product: this.schema.p_product.default,
                    step: this.step,
                    platform: this.platform,
                    page_name: this.pageName({cutProductName: true})
                })
                activeDepth.d100 = false
            }

        })

    }

    afterBodyClickWrapper(fn) {
        setTimeout(fn.bind(this), 0)
    }

    calculatorForm({
                       customInput = '.question-answer .pic',
                       questionClassForId = '.question-box',
                       agreementClassForId = '.agreements-wrapper',
                       errorWrapperClass = '.error-box',
                       observeDOM = false,
                       calculatorWrapperClass = ''
                   }) {

        if (!calculatorWrapperClass) throw new Error('[Ga4Interface Debug] calculatorForm: When u observe some data u should define ;calculatorWrapperClass;')

        const getQuestionID = ({element}) => {
            const fieldName = element.closest(questionClassForId) || element.closest(agreementClassForId)
            return fieldName.id
        }

        const formElementClick = (e) => {
            this.afterBodyClickWrapper(() => {
                const fieldName = getQuestionID({element: e.target});

                this.addEvent({
                    event: 'calculator-form',
                    event_type: 'calculator',
                    event_category: `form.${this.schema.p_product.default}`,
                    event_label: `form.${this.step}.${fieldName}`,
                    event_action: 'form.text',
                    product_family: this.schema.p_product_family.default,
                    product: this.schema.p_product.default,
                    step: this.step,
                    platform: this.platform,
                    page_name: this.pageName({cutProductName: true})
                })
            })
        }

        const trackErrors = ({element, errorWrapperClass}) => {
            const arrayOfErrorClass = (errorWrapperClass.indexOf(',') != -1) ? errorWrapperClass.split(',').map((errorClass) => errorClass.trim(' ')) : [errorWrapperClass]
            for (const errorClass of arrayOfErrorClass) {
                if (element.className === errorClass.substring(1)) {
                    this.afterBodyClickWrapper(() => {
                        const fieldName = getQuestionID({element});

                        this.addEvent({
                            event: 'calculator-form',
                            event_type: 'calculator',
                            event_category: `form.${this.schema.p_product.default}`,
                            event_label: `form-error`,
                            event_action: `form.error-${fieldName}`,
                            product_family: this.schema.p_product_family.default,
                            product: this.schema.p_product.default,
                            step: this.step,
                            platform: this.platform,
                            page_name: this.pageName({cutProductName: true})
                        })
                    })
                }
            }
        }

        const addEventToElement = ({element = document, timeToHighlight = 1500}) => {

            const allInputs = element.querySelectorAll(`input, select, ${customInput}`);

            for (let inputElement of [...allInputs]) {
                if (this.highlightEvents) {
                    setTimeout(() => {
                        this.highlight({
                            element: inputElement,
                            eventType: 'calculatorForm',
                            attributeName: 'Click',
                            color: 'magenta',
                        })
                    }, timeToHighlight)
                }
                ;

                inputElement.addEventListener('click', formElementClick)
            }
        }

        if (observeDOM) {
            const watcher = new MutationObserver((mutationList, observer) => {
                for (const mutation of mutationList) {
                    if (mutation.type === 'childList') {
                        if (mutation.addedNodes.length > 0) {
                            for (const nodeElement of mutation.addedNodes) {
                                if (nodeElement.nodeName != '#comment' && nodeElement.nodeName != '#text') {
                                    addEventToElement({element: nodeElement, timeToHighlight: 0});
                                    trackErrors({element: nodeElement, errorWrapperClass});
                                }
                            }
                        }
                    }
                }
                ;
            })

            watcher.observe(document.querySelector(calculatorWrapperClass), {childList: true, subtree: true})
        }

        addEventToElement({});
    }

    calculatorNextStepClick() {

        this.addEvent({
            event: 'calculator-form',
            event_type: 'calculator',
            event_category: `form.${this.schema.p_product.default}`,
            event_label: `form.${this.step}.nextStep`,
            event_action: `form.button-click`,
            product_family: this.schema.p_product_family.default,
            product: this.schema.p_product.default,
            step: this.step,
            platform: this.platform,
            page_name: this.pageName({cutProductName: true})
        })
    }

    calculatorSuccess() {
        this.calculatorNextStepClick();

        this.addEvent({
            event: 'calculator-successful',
            event_type: 'calculator',
            event_category: `form.${this.schema.p_product.default}`,
            event_label: `form-success`,
            event_action: `form.success-${this.schema.p_product.default}`,
            product_family: this.schema.p_product_family.default,
            product: this.schema.p_product.default,
            step: 'full-application',
            platform: this.platform,
            page_name: this.pageName({cutProductName: true, setStep: 'full-application'})
        })
    }

    calculatorResultsEvents({
                                offerBoxClass = null,
                                btnClass = null,
                                detailClass = null,
                                resultWrapperClass = '',
                                item_name_key = null,
                                item_id_key = null,
                                item_price_key = null,
                                item_category = null,
                                item_category2 = null,
                                item_category3 = null,
                                item_list_id = null,
                                currency = 'PLN',
                                affiliation = 'owned',
                                completion_type = 'online',
                                step = 'result-page',
                                btnClosestGa4iIDClass = []
                            }) {

        if (!btnClass) throw new Error('[Ga4Interface Debug] calculatorResultsEvents: U have to define ;btnClass;')
        if (!resultWrapperClass) throw new Error('[Ga4Interface Debug] calculatorResultsEvents: U have to define ;resultWrapperClass;')
        if (btnClosestGa4iIDClass.length === 0 || !Array.isArray(btnClosestGa4iIDClass)) throw new Error('[Ga4Interface Debug] calculatorResultsEvents: btnClosestGa4iIDClass[Array] is required')

        if (item_name_key === null || !Array.isArray(item_name_key)) throw new Error('[Ga4Interface Debug] calculatorResultsEvents: item_name_key[Array] is required, define key name in offer as array eg. ["key_name"], if nested ["key_name","key_name"]')
        if (item_id_key === null || !Array.isArray(item_id_key)) throw new Error('[Ga4Interface Debug] calculatorResultsEvents: item_id_key[Array] is required, define key name in offer as array eg. ["key_name"], if nested ["key_name","key_name"]')
        // if (item_price_key === null || !Array.isArray(item_price_key)) throw new Error('[Ga4Interface Debug] calculatorResultsEvents: item_price_key[Array] is required, define key name in offer as array eg. ["key_name"], if nested ["key_name","key_name"]')
        if (item_category === null) throw new Error('[Ga4Interface Debug] calculatorResultsEvents: item_category[String] is required, eg. ;insurance;')
        if (item_category2 === null) throw new Error('[Ga4Interface Debug] calculatorResultsEvents: item_category2[String] is required, eg. ;car-insurance;')
        if (item_category3 === null) throw new Error('[Ga4Interface Debug] calculatorResultsEvents: item_category3[String] is required, eg. ;offer;')
        if (item_list_id === null) throw new Error('[Ga4Interface Debug] calculatorResultsEvents: item_list_id[String] is required, unique list number')

        this.step = step

        this.resultListInProgress();

        this.calculatorResultsSettings.offerBoxClass = offerBoxClass;
        this.calculatorResultsSettings.btnClosestGa4iIDClass = btnClosestGa4iIDClass
        this.calculatorResultsSettings.item = {
            item_name_key,
            item_id_key,
            item_price_key,
            item_category,
            item_category2,
            item_category3,
            item_list_name: this.schema.p_product_family.default,
            item_list_id,
            currency,
            affiliation,
            completion_type
        }

        this.resultsProductClick({btnClass, detailClass, resultWrapperClass});
    }

    setEcommerceNull() {
        // dataLayer.push({ecommerce: null})
    }

    resultListInProgress() {
        window.addEventListener('DOMContentLoaded', (event) => {
            this.addEvent({
                event: 'view_item_list',
                event_type: 'calculator',
                event_category: `item-list-in-progress`,
                event_label: `result-page.item-list-progress`,
                event_action: `form.item-list-progress`,
                product_family: this.schema.p_product_family.default,
                product: this.schema.p_product.default,
                step: this.step,
                platform: this.platform,
                amount_offers: 0,
                ecommerce: {
                    items: []
                },
                page_name: this.pageName({cutProductName: true})
            })
        })
    }

    resultListNoOffer() {
        this.addEvent({
            event: 'view_item_list',
            event_type: 'calculator',
            event_category: `item-list-failed`,
            event_label: `result-page.item-list-failed`,
            event_action: `form.item-list-failed`,
            product_family: this.schema.p_product_family.default,
            product: this.schema.p_product.default,
            step: this.step,
            platform: this.platform,
            amount_offers: 0,
            ecommerce: {
                items: []
            },
            page_name: this.pageName({cutProductName: true})
        })
    }

    resultsPickProductList({listName = 'l1', offersList = null}) {
        if (offersList === null) throw new Error('[Ga4Interface Debug] resultsProductListings: U have to add -offersList- parameter')

        offersList.forEach(item => item['ga4i_list_name'] = listName)
        console.log('list', offersList)

        // this.setEcommerceNull()

        this.calculatorResultsSettings.offersList[listName] = offersList

        if (this.highlightEvents) {
            setTimeout(() => {
                if (!this.calculatorResultsSettings.offerBoxClass) console.warn('[Ga4Interface Debug] resultsProductListings: if u want to highlight every offer u have to define offerbox class in resultsProductListings')
                else {
                    const offersInDom = document.querySelectorAll(this.calculatorResultsSettings.offerBoxClass);

                    for (const offerInDom of [...offersInDom]) {
                        this.highlight({
                            element: offerInDom,
                            eventType: 'calculatorResultsEvents',
                            attributeName: 'view_item_list',
                            color: 'orange'
                        })
                    }
                }
            }, 2000)
        }
        ;

        if (offersList.length === 0) this.resultListNoOffer()
        else {
            let items = []

            for (let [offerIndex, offer] of this.calculatorResultsSettings.offersList[listName].entries()) {
                let itemObj = {
                    item_name: this.deepSearch(offer, this.calculatorResultsSettings.item.item_name_key),
                    item_id: this.deepSearch(offer, this.calculatorResultsSettings.item.item_id_key),
                    item_category: this.calculatorResultsSettings.item.item_category,
                    item_category2: this.calculatorResultsSettings.item.item_category2,
                    item_category3: this.calculatorResultsSettings.item.item_category3,
                    item_list_name: this.calculatorResultsSettings.item.item_list_name,
                    item_list_id: this.calculatorResultsSettings.item.item_list_id,
                    index: offerIndex,
                    currency: this.calculatorResultsSettings.item.currency,
                    affiliation: this.calculatorResultsSettings.item.affiliation,
                    completion_type: this.calculatorResultsSettings.item.completion_type
                }
                if (this.calculatorResultsSettings.item.item_price_key) {
                    itemObj.price = this.deepSearch(offer, this.calculatorResultsSettings.item.item_price_key)
                }
                items.push(itemObj)
            }

            this.addEvent({
                event: 'view_item_list',
                event_type: 'calculator',
                event_category: `item-list-successful`,
                event_label: `result-page.item-list-successful`,
                event_action: `form.item-list-successful`,
                product_family: this.schema.p_product_family.default,
                product: this.schema.p_product.default,
                step: this.step,
                platform: this.platform,
                amount_offers: this.calculatorResultsSettings.offersList[listName].length,
                ecommerce: {
                    items
                },
                page_name: this.pageName({cutProductName: true})
            })
        }

    }

    resultsProductClick({resultWrapperClass = '', btnClass = '', detailClass = '', observeDOM = true}) {

        const getOfferList = ({element}) => {
            let closestClass = null;

            for (const nearestClass of this.calculatorResultsSettings.btnClosestGa4iIDClass) {
                if (element.closest(nearestClass) && element.closest(nearestClass).hasAttribute('data-ga4i-ol')) {
                    closestClass = nearestClass
                    break;
                }
            }
            return element.closest(closestClass).dataset['ga4iOl']
        }

        const getOfferId = ({element}) => {
            let closestClass = null;

            for (const nearestClass of this.calculatorResultsSettings.btnClosestGa4iIDClass) {

                if (element.closest(nearestClass) && element.closest(nearestClass).hasAttribute('data-ga4i-id')) {
                    closestClass = nearestClass
                    break;
                }
            }

            return element.closest(closestClass).dataset['ga4iId']
        }
        ////// 2 listy się nadpisują i potem nie znajduje odpowiedniego ID !!!
        const setItemsValues = (e, listName) => {
            const offerID = getOfferId({element: e.target});
            const offer = this.calculatorResultsSettings.offersList[listName].find((offer) => offer.id == offerID)
            const offerIndex = this.calculatorResultsSettings.offersList[listName].indexOf(offer)
            let items = []

            let itemObj = {
                item_name: this.deepSearch(offer, this.calculatorResultsSettings.item.item_name_key),
                item_id: this.deepSearch(offer, this.calculatorResultsSettings.item.item_id_key),
                item_category: this.calculatorResultsSettings.item.item_category,
                item_category2: this.calculatorResultsSettings.item.item_category2,
                item_category3: this.calculatorResultsSettings.item.item_category3,
                item_list_name: this.calculatorResultsSettings.item.item_list_name,
                item_list_id: this.calculatorResultsSettings.item.item_list_id,
                index: offerIndex,
                currency: this.calculatorResultsSettings.item.currency,
                affiliation: this.calculatorResultsSettings.item.affiliation,
                completion_type: this.calculatorResultsSettings.item.completion_type
            }
            if (this.calculatorResultsSettings.item.item_price_key) {
                itemObj.price = this.deepSearch(offer, this.calculatorResultsSettings.item.item_price_key)
            }

            items.push(itemObj)

            return items
        }

        const resultElementAddToCartClick = (e) => {
            const listName = getOfferList({element: e.target})
            const items = setItemsValues(e, listName)

            this.addEvent({
                event: 'add_to_cart',
                event_type: this.calculatorResultsSettings.item.item_category,
                event_category: this.calculatorResultsSettings.item.item_category2,
                event_label: `form.${this.step}.`,
                event_action: `form.button-click`,
                product_family: this.schema.p_product_family.default,
                product: this.schema.p_product.default,
                step: 'add-2-cart-click',
                platform: this.platform,
                ecommerce: {
                    items
                },
                page_name: this.pageName({cutProductName: true})
            })
        }

        const resultElementViewItemClick = (e) => {
            const listName = getOfferList({element: e.target})
            const items = setItemsValues(e, listName)

            this.addEvent({
                event: 'view_item',
                event_type: this.calculatorResultsSettings.item.item_category,
                event_category: this.calculatorResultsSettings.item.item_category2,
                event_label: `form.${this.step}.offer-details`,
                event_action: `form.button-click`,
                product_family: this.schema.p_product_family.default,
                product: this.schema.p_product.default,
                step: 'offer-detail-page',
                platform: this.platform,
                ecommerce: {
                    items
                },
                page_name: this.pageName({cutProductName: true, setStep: 'offer-detail-page'})
            })
        }


        const addEventToElement = ({element = document, timeToHighlight = 1500}) => {

            const btnElements = (element.querySelector(`${btnClass}`)) ? element.querySelectorAll(`${btnClass}`) : [];

            for (let btnElement of [...btnElements]) {
                if (this.highlightEvents) {
                    setTimeout(() => {
                        this.highlight({
                            element: btnElement,
                            eventType: 'resultsProductClick__add-to-cart',
                            attributeName: 'Click',
                            color: 'pink',
                        })
                    }, timeToHighlight)
                }
                ;

                btnElement.addEventListener('click', resultElementAddToCartClick)
            }

            if (detailClass) {
                const detailElements = (element.querySelector(`${detailClass}`)) ? element.querySelectorAll(`${detailClass}`) : [];

                for (let detailElement of [...detailElements]) {
                    if (this.highlightEvents) {
                        setTimeout(() => {
                            this.highlight({
                                element: detailElement,
                                eventType: 'resultsProductClick__view_item',
                                attributeName: 'Click',
                                color: 'red',
                            })
                        }, timeToHighlight)
                    }
                    ;

                    detailElement.addEventListener('click', resultElementViewItemClick)
                }
            }

        }

        if (observeDOM) {
            const watcher = new MutationObserver((mutationList, observer) => {
                for (const mutation of mutationList) {
                    if (mutation.type === 'childList') {
                        if (mutation.addedNodes.length > 0) {
                            for (const nodeElement of mutation.addedNodes) {
                                if (nodeElement.nodeName != '#comment' && nodeElement.nodeName != '#text') {
                                    addEventToElement({element: nodeElement, timeToHighlight: 0});
                                }
                            }
                        }
                    }
                }
                ;
            })

            watcher.observe(document.querySelector(`${resultWrapperClass}`), {childList: true, subtree: true})
        }

        addEventToElement({});
    }
}


export default Ga4Interface;
