import React, {useCallback, useEffect, useState} from 'react';
import Register from "@components/views/Register/Main";
import {connect} from "react-redux";
import {getRegisterAgreements, registerInit, register} from "@store/actions/user";
import {bindActionCreators} from "redux";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import VALIDATORS from "@consts/validators";
import {useMount} from "@s-ui/react-hooks";

const RegisterContainer = (props) => {
    const [formFields, setFormFields] = useState(BASIC_FORM_FIELDS_CONFIG.REGISTER);
    const [agreementsBuildFinished, setAgreementsBuildFinished] = useState(false);

    const buildAgreements = useCallback(() => {
        let agreements = [];

        props.user.profile.registerAgreementsItems.forEach(agreement => {
            agreements.push({
                agreementType: agreement.type,
                name: agreement.uuid,
                value: agreement.type === 'INFORMATION',
                label: agreement.content,
                validator: VALIDATORS.CHECKBOX,
                type: 'checkbox',
                class: 'checkbox-field',
                isRequired: agreement.required,
                isCorrect: agreement.type === 'INFORMATION',
                isDisabled: false,
                errorMessage: 'Zgoda wymagana',
                isTouched: false,
                toSend: true,
            });
        });

        setFormFields([...formFields, ...agreements]);
        setAgreementsBuildFinished(true);
    }, [formFields, props.user.profile.registerAgreementsItems]);

    const handleFormValid = (data) => {
        props.actions.register(data);
    }

    useEffect(() => {
        if (props.user.profile.registerAgreementsItems && !agreementsBuildFinished) {
            buildAgreements();
        }
    }, [agreementsBuildFinished, props.user.profile.registerAgreementsItems, buildAgreements]);

    useMount(() => {
        props.actions.getRegisterAgreements();
        props.actions.registerInit();
    });

    return (
        <Register
            fields={formFields}
            user={props.user}
            handleFormValid={handleFormValid}
        />
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getRegisterAgreements,
            registerInit,
            register,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterContainer);