import React from 'react';
import useStylesMain from "./styles";

const Main = (props) => {
    const {classes: mainStyles} = useStylesMain();

    return (
        <main className={mainStyles.main}>
            <props.content {...props} />
        </main>
    );
}

export default Main;