import React from 'react';
import {Container} from "@mui/material";
import useStylesLogosSection from "./styles";

const LogosSection = (props) => {
    const {classes: logosSectionStyles} = useStylesLogosSection();

    return (
        <div className={logosSectionStyles.logosSection}>
            <Container>
                <div className={logosSectionStyles.title}>Zorganizowaliśmy Akcje Bonus z:</div>
                <div className={logosSectionStyles.logos}>
                    <div className={logosSectionStyles.logosList} id="logos-list">
                        {props.logosItems.map((logo, index) => (
                            <div key={index} className={logosSectionStyles.logoContainer}>
                                <img className={logosSectionStyles.logo}
                                     src={logo.image}
                                     alt={logo.name}
                                     onLoad={() => props.handleLogoLoaded(logo.name)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default LogosSection;