import ROUTES from "@consts/routes";
import {
    Home,
    MenuBook,
    TrendingUp,
    Star, PlaylistAdd, Help, AlternateEmail, Payment, Assignment
} from "@mui/icons-material";

const MENU_ITEMS = {
    notLoggedIn: [
        {
            link: ROUTES.HOME,
            text: 'Start',
            icon: Home,
        },
        {
            link: ROUTES.FAQ,
            text: 'Pytania i odpowiedzi',
            icon: Help,
        },
        {
            link: ROUTES.REGULATION,
            text: 'Regulamin',
            icon: Assignment,
        },
        {
            link: ROUTES.PRIVACY_POLICY,
            text: 'Polityka prywatności',
            icon: MenuBook,
        },
        {
            link: ROUTES.CONTACT,
            text: 'Kontakt',
            icon: AlternateEmail,
        }
    ],
    loggedIn: [
        {
            link: ROUTES.HOME,
            text: 'Akcje',
            icon: Star,
        },
        {
            link: ROUTES.STATISTICS,
            text: 'Statystyki',
            icon: TrendingUp,
        },
        {
            link: ROUTES.COMPLAINTS,
            text: 'Reklamacje',
            icon: PlaylistAdd,
        },
        {
            link: ROUTES.PAYOUT,
            text: 'Wypłata',
            icon: Payment,
        },
        {
            link: ROUTES.FAQ,
            text: 'Pytania i odpowiedzi',
            icon: Help,
        },
        {
            link: ROUTES.REGULATION,
            text: 'Regulamin',
            icon: Assignment,
        },
        {
            link: ROUTES.PRIVACY_POLICY,
            text: 'Polityka prywatności',
            icon: MenuBook,
        },
        {
            link: ROUTES.CONTACT,
            text: 'Kontakt',
            icon: AlternateEmail,
        }
    ]
}

export default MENU_ITEMS;