import React from 'react';
import Logo from "@assets/logos/logo.png";
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import {Container} from "@mui/material";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import DisplayMessage from "@components/shared/DisplayMessage/Main";
import useStylesMain from "@components/layout/Main/styles";

const ResetPassword = (props) => {
    const {fields, user, handleFormValid} = props;
    const {classes: mainStyles} = useStylesMain();

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Resetowanie hasła</h1>
                <div className={mainStyles.contentBody}>
                    <div className={mainStyles.contentBodyFull}>
                        {user.profile.getRecoverPasswordTokenErrors ? (
                            <DisplayMessage type={DISPLAY_MESSAGES.RECOVER_PASSWORD_TOKEN_ERROR}/>
                        ) : (
                            <>
                                {user.profile.resetPasswordSuccess ? (
                                    <DisplayMessageContainer type={DISPLAY_MESSAGES.RESET_PASSWORD_PASSWORD_CHANGED}/>
                                ) : (
                                    <>
                                        <img className={mainStyles.logo} src={Logo} alt="Logo"/>
                                        <p className={mainStyles.formDescription}>Utwórz nowe hasło do Konta Bonus</p>
                                        <BasicFormBuilderContainer
                                            fields={fields}
                                            errors={user.profile.resetPasswordErrors}
                                            handleFormValid={handleFormValid}
                                            btnText="Utwórz nowe hasło"
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ResetPassword;