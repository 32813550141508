import React from 'react';
import {NavLink, Link} from "react-router-dom";
import logo from '@assets/logos/logo.png';
import ROUTES from "@consts/routes";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import {AccountCircle, Close, ExitToApp, Person, PersonAdd} from "@mui/icons-material";
import {Container} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import useStylesHeader from "./styles";

const Header = (props) => {
    const {classes: headerStyles, cx} = useStylesHeader();

    return (
        <header className={headerStyles.header}>
            <Container className={headerStyles.container}>
                <Link to="/" className={headerStyles.logoContainer}>
                    <img src={logo} className={headerStyles.logo} alt="Logo Bonus"/>
                </Link>
                <div className={cx(headerStyles.navOverlay, props.mobileMenuVisible && headerStyles.navOverlayActive)}
                     onClick={props.hideMenu}>

                    <IconButton className={headerStyles.closeButton} size="large">
                        <Close className={headerStyles.closeIcon}/>
                    </IconButton>
                </div>
                <nav className={cx(headerStyles.nav, props.mobileMenuVisible && headerStyles.navActive)}>
                    <Link
                        to={ROUTES.HOME}
                        className={headerStyles.navMobileLogo}
                    >
                        <img src={logo} className={headerStyles.logo}  alt="Logo Bonus"/>
                    </Link>

                    <ul className={headerStyles.navItems} id="navigation-items-list">
                        {props.menuItems.map((item, index) => (
                            <li key={index} className={headerStyles.navItem}>
                                <NavLink
                                    to={item.link}
                                    className={({isActive}) =>
                                        [
                                            headerStyles.navItemLink,
                                            isActive ? headerStyles.navItemLinkActive : null
                                        ]
                                            .filter(Boolean)
                                            .join(" ")
                                    }
                                    onClick={props.hideMenu}>
                                    <item.icon className={headerStyles.navItemLinkIcon}/>
                                    <span className={headerStyles.navItemLinkText}>{item.text}</span>
                                </NavLink>
                            </li>
                        ))}
                    </ul>

                    {props.user.auth.isLoggedIn &&
                        <div className={headerStyles.logoutButtonContainer}>
                            <Button
                                variant="contained"
                                className={headerStyles.logoutButton}
                                disableElevation
                                onClick={() => {
                                    props.logout();
                                    props.setMorePanelVisible(false);
                                    props.hideMenu();
                                }}>Wyloguj się</Button>
                        </div>}
                </nav>
                {props.user.auth.isLoggedIn ? (
                    <ClickAwayListener onClickAway={() => props.setMorePanelVisible(false)}>
                        <div className={headerStyles.loggedUserInfo}>
                            <Button
                                variant="contained"
                                className={headerStyles.userInfoButton}
                                disableElevation
                                onClick={() => props.setMorePanelVisible(true)}>
                                <span
                                    className={headerStyles.userInfoButtonName}>{props.user.auth.userData.firstName}</span>
                                <AccountCircle className={headerStyles.userInfoButtonIcon}/>
                            </Button>

                            <div
                                className={cx(headerStyles.moreInfoPanel, props.morePanelVisible && headerStyles.moreInfoPanelActive)}>
                                <div className={headerStyles.shortData}>
                                    <div className={headerStyles.fullName}>
                                        {props.user.auth.userData.firstName} {props.user.auth.userData.lastName}
                                    </div>
                                    {props.user.auth.userData.waitingForEditAcceptance && (
                                        <div className={headerStyles.acceptanceInfo}>
                                            (Dane wysłane do weryfikacji)
                                        </div>
                                    )}
                                    <div className={headerStyles.email}>
                                        {props.user.auth.userData.email}
                                    </div>
                                </div>
                                <Link
                                    to={ROUTES.USER_PROFILE}
                                    className={headerStyles.userProfileLink}
                                    onClick={() => props.setMorePanelVisible(false)}>
                                    <Person className={headerStyles.userProfileLinkIcon}/> Ustawienia profilu
                                </Link>
                                <Button
                                    variant="contained"
                                    className={headerStyles.moreInfoPanelLogoutButton}
                                    disableElevation
                                    onClick={() => {
                                        props.logout();
                                        props.setMorePanelVisible(false);
                                    }}>Wyloguj się</Button>
                            </div>
                        </div>
                    </ClickAwayListener>
                ) : (
                    <div className={headerStyles.notLoggedUserLinks}>
                        <Link
                            to={ROUTES.REGISTER}
                            className={headerStyles.notLoggedUserLink}
                        >
                            <Button
                                variant="contained"
                                className={cx(headerStyles.notLoggedUserButton, headerStyles.registerButton)}
                                disableElevation>
                                <PersonAdd className={headerStyles.notLoggedUserIcon}/> Rejestracja
                            </Button>
                        </Link>
                        <Link
                            to={ROUTES.LOGIN}
                            className={headerStyles.notLoggedUserLink}
                        >
                            <Button
                                variant="contained"
                                className={cx(headerStyles.notLoggedUserButton, headerStyles.loginButton)}
                                disableElevation>
                                <ExitToApp className={headerStyles.notLoggedUserIcon}/> Logowanie
                            </Button>
                        </Link>
                    </div>
                )}
                <IconButton className={headerStyles.burger} onClick={props.showMenu} size="large">
                    <MenuIcon className={headerStyles.burgerIcon}/>
                </IconButton>
            </Container>
        </header>
    );
}

export default Header;