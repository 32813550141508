import React from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Container} from "@mui/material";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import SkeletonContentContainer from "@components/shared/SkeletonContent";
import SKELETONS from "@consts/skeletons";
import useStylesFaq from "./styles";
import useStylesMain from "@components/layout/Main/styles";

const Faq = (props) => {
    const {classes: mainStyles} = useStylesMain();
    const {classes: faqStyles} = useStylesFaq();

    const [expanded, setExpanded] = React.useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const faqItems = () => {
        if (props.faqItems) {
            return (
                <>
                    {props.faqItems.map((item, index) => (
                        <Accordion
                            key={index}
                            square
                            className={faqStyles.faqItem}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                className={faqStyles.faqQuestionContainer}
                            >
                                <div
                                    className={faqStyles.faqQuestionText}
                                    dangerouslySetInnerHTML={{__html: item.title}}
                                />
                            </AccordionSummary>
                            <AccordionDetails
                                className={faqStyles.faqAnswerContainer}
                            >
                                <div
                                    className={faqStyles.faqAnswerText}
                                    dangerouslySetInnerHTML={{__html: item.content}}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </>
            )
        } else if (props.errors) {
            return (
                <div className={mainStyles.contentBodyFull}>
                    <DisplayMessageContainer type={DISPLAY_MESSAGES.SOMETHING_WENT_WRONG}/>
                </div>
            )
        } else if (!props.faqItems && !props.errors) {
            return (
                <div className={mainStyles.contentBodyFull}>
                    <SkeletonContentContainer type={SKELETONS.FAQ}/>
                </div>
            )
        }
    }

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Pytania i odpowiedzi</h1>
                <div className={mainStyles.contentBody}>
                    {faqItems()}
                </div>
            </div>
        </Container>
    );
};

export default Faq;