import {
    LOGIN_USER_INIT,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {setTokens} from "@tools/tokens";
import redirectAfterLogin from "@tools/redirectAfterLogin";
import LOADER_PLACES from "@consts/loaderPlaces";
import ROUTES from "@consts/routes";

const loginInit = () => ({
    type: LOGIN_USER_INIT,
});

const loginSuccess = (data) => ({
    type: LOGIN_USER_SUCCESS,
    payload: data
});

const loginFailure = (errors) => ({
    type: LOGIN_USER_FAILURE,
    payload: errors
});

const login = (data, navigate) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.LOGIN));
        await dispatch(loginInit());

        const path = '/api/auth/login';

        const headers = {};

        data = {
            ...data,
            clientId: window?._env_?.REACT_APP_API_CLIENT_ID || process.env.REACT_APP_API_CLIENT_ID,
            activationUrlPattern: `${window.location.origin}${ROUTES.ACTIVATE_ACCOUNT}`,
        };

        try {
            const response = await client('POST', path, data, headers);
            const responseData = response.data.data;
            const userData = responseData.user;

            setTokens(responseData.accessToken, responseData.refreshToken);
            await dispatch(loginSuccess(userData));

            const pathAfterLogin = redirectAfterLogin.get();
            navigate(pathAfterLogin);
            redirectAfterLogin.remove();
        } catch (error) {
            if (error.response) {
                await dispatch(loginFailure(error.response.data.errors));
            }
        }
        await dispatch(resetMultiLoader(LOADER_PLACES.LOGIN));
    }
}

export {
    loginInit,
    login,
};