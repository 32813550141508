import {makeStyles} from "tss-react/mui";

const useStylesStatistics = makeStyles()(theme => ({
    action: {
        fontSize: "14px",
        margin: "0 auto",
        maxWidth: "395px",
        overflow: "hidden",
        borderRadius: "7px",
        boxShadow: `0 1px 2px ${theme.palette.color5}`,
        backgroundColor: "#fff",
    },
    actionContent: {
        width: "100%",
        padding: "0 15px",
    },
    name: {
        fontSize: "16px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 600,
        lineHeight: "1.5em",
        minHeight: "4em",
        color: "#000",
    },
    topContainer: {
        position: "relative",
        backgroundColor: theme.palette.color3Darker,
    },
    imageContainer: {
        position: "relative",
    },
    imageMask: {
        width: "100%",
    },
    image: {
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    parametersContainer: {
        width: "100%",
        padding: "15px 0",
        borderTop: `1px solid ${theme.palette.color3Darker}`,
    },
    parameter: {
        width: "100%",
        display: "table",
        textAlign: "center",
        margin: "0 0 15px 0",
        "&:last-of-type": {
            margin: "0",
        }
    },
    parameterName: {
        textTransform: "uppercase",
        color: theme.palette.color4,
    },
    parameterValue: {
        fontWeight: 600,
        color: "#000",
    },
    parameterValueRow: {},
    regulationsContainer: {
        padding: "15px 0",
        borderTop: `1px solid ${theme.palette.color3Darker}`,
    },
    regulationContainer: {
        textAlign: "center",
        margin: "0 0 10px 0",
        "&:last-of-type": {
            margin: "0",
        }
    },
    regulationOpenLink: {
        textDecoration: "none",
        color: theme.palette.color1,
        "&:hover": {
            textDecoration: "underline",
        }
    },
    dialogTitle: {
        borderBottom: `1px solid ${theme.palette.color3}`,
    },
    buttonsContainer: {
        backgroundColor: theme.palette.color6,
        borderTop: `1px solid ${theme.palette.color3}`,
    },
    regulationCloseButton: {
        backgroundColor: theme.palette.color1,
        color: "#fff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        }
    },
    regulationContent: {
        fontSize: "14px",
        color: "#000",
        margin: "0 0 30px 0",
    },
    regulationAttachments: {
        width: "100%",
    },
    regulationAttachmentsTitle: {
        fontWeight: 600,
    },
    regulationAttachment: {
        color: theme.palette.color1,
    },
}))

export default useStylesStatistics;