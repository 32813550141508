import {
    GET_FAQ_ITEMS_INIT,
    GET_FAQ_ITEMS_SUCCESS,
    GET_FAQ_ITEMS_FAILURE
} from "@store/types/faq";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const getFaqItemsInit = () => ({
    type: GET_FAQ_ITEMS_INIT
});

const getFaqItemsSuccess = (data) => ({
    type: GET_FAQ_ITEMS_SUCCESS,
    payload: data
});

const getFaqItemsFailure = (errors) => ({
    type: GET_FAQ_ITEMS_FAILURE,
    payload: errors
});

const getFaqItems = () => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.FAQ_ITEMS));
        await dispatch(getFaqItemsInit());

        const path = '/api/special-page/faq';

        const headers = {};

        try {
            const response = await client('GET', path, null, headers);
            const responseData = response.data.data;

            await dispatch(getFaqItemsSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(getFaqItemsFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.FAQ_ITEMS));
    }
}

export {
    getFaqItemsInit,
    getFaqItems
};