import {makeStyles} from "tss-react/mui";

const useStylesHeader = makeStyles()(theme => ({
    header: {
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        height: "70px",
        borderBottom: `1px solid ${theme.palette.color6}`,
        position: "relative",
        zIndex: 10,
        [theme.breakpoints.up("lg")]: {
            height: "90px",
        },
    },
    container: {
        height: "100%",
        position: "relative",
        display: "flex!important",
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.up("lg")]: {
            justifyContent: "space-between",
        },
    },
    burger: {
        margin: "0 -15px 0 0",
        color: theme.palette.color1,
        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },
    burgerIcon: {
        width: "24px",
        height: "24px",
    },
    logoContainer: {
        marginRight: "auto",
        [theme.breakpoints.up("lg")]: {
            margin: 0,
        },
    },
    logo: {
        height: "50px",
    },
    navOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        backgroundColor: "rgba(0, 0, 0, 0.85)",
        overflow: "hidden",
        opacity: 0,
        transition: "height 0s .5s, width 0s .5s, opacity .5s 0s",
        zIndex: 10,
        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },
    navOverlayActive: {
        width: "100%",
        height: "100%",
        opacity: 1,
        transition: "width 0s 0s, height 0s 0s, opacity .5s 0s",
    },
    closeButton: {
        position: "absolute",
        top: 0,
        right: 0,
        width: "48px",
        height: "70px",
    },
    closeIcon: {
        color: "#fff",
        width: "24px",
        height: "24px",
    },
    nav: {
        top: 0,
        left: "-250px",
        width: "250px",
        height: "100%",
        position: "fixed",
        transition: ".5s",
        zIndex: 11,
        overflow: "auto",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.up("lg")]: {
            justifyContent: "center",
            top: "auto",
            left: "auto",
            width: "auto",
            position: "relative",
            backgroundColor: "transparent",
            zIndex: 0,
        },
    },
    navActive: {
        left: 0,
        [theme.breakpoints.up("lg")]: {
            left: "auto",
        },
    },
    navMobileLogo: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70px",
        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },
    navItems: {
        width: "100%",
        position: "relative",
        listStyle: "none",
        margin: 0,
        padding: "10px 0",
        display: "flex",
        flexDirection: "column",
        borderTop: `1px solid ${theme.palette.color3}`,
        borderBottom: `1px solid ${theme.palette.color3}`,
        [theme.breakpoints.up("lg")]: {
            flexDirection: "row",
            padding: 0,
            backgroundColor: "transparent",
            animation: "none",
            width: "auto",
            height: "auto",
            left: "auto",
            border: "none",
        },
    },
    navItem: {
        margin: "0 0 5px 10px",
        [theme.breakpoints.up("lg")]: {
            margin: "0 5px",
            display: "inline-block",
            "&:last-of-type": {
                margin: "0 5px",
            },
        },
        "&:last-of-type": {
            margin: "0 0 0 10px",
        },
    },
    navItemLink: {
        fontSize: "14px",
        fontWeight: 600,
        padding: "0 15px 0 20px",
        height: "50px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: "#000",
        borderRadius: "25px 0 0 25px",
        "&:hover": {
            backgroundColor: theme.palette.color1Lighter,
            color: "#000",
        },
        [theme.breakpoints.up("lg")]: {
            minWidth: "80px",
            height: "auto",
            padding: "10px",
            flexDirection: "column",
            borderRadius: "5px",
        },
    },
    navItemLinkActive: {
        backgroundColor: theme.palette.color1Lighter,
        "& svg": {
            color: theme.palette.color1,
        }
    },
    navItemLinkIcon: {
        color: theme.palette.color5,
        width: "24px",
        height: "24px",
        transition: "0.1s",
    },
    navItemLinkText: {
        margin: "0 0 0 20px",
        [theme.breakpoints.up("lg")]: {
            margin: "5px 0 0 0",
        },
    },
    logoutButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70px",
        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },
    logoutButton: {
        backgroundColor: theme.palette.color1,
        color: "#fff",
        margin: "0 auto",
        display: "table",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        },
    },
    notLoggedUserLinks: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "120px",
        padding: 0,
        height: "100%",
    },
    notLoggedUserLink: {
        height: "28px",
        fontSize: "13px",
        margin: "2px 0",
    },
    notLoggedUserButton: {
        width: "100%",
        height: "100%",
        fontSize: "14px",
        padding: 0,
        textTransform: "none",
    },
    registerButton: {
        backgroundColor: "transparent",
        color: theme.palette.color2,
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    loginButton: {
        backgroundColor: theme.palette.color1,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        },
    },
    notLoggedUserIcon: {
        width: "24px",
        height: "24px",
        margin: "0 5px 0 0",
    },
    loggedUserInfo: {
        position: "relative",
    },
    userInfoButton: {
        position: "relative",
        textTransform: "none",
        border: `1px solid ${theme.palette.color1}`,
        backgroundColor: "#fff",
        color: theme.palette.color1,
        padding: "5px",
        height: "35px",
        borderRadius: "calc(35px / 2)",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "#fff",
        }
    },
    userInfoButtonName: {
        margin: "0 5px",
        fontWeight: 600,
        textAlign: "center",
        lineHeight: "1.0",
    },
    userInfoButtonIcon: {
        width: "24px",
        height: "24px",
    },
    moreInfoPanel: {
        display: "none",
        width: "250px",
        padding: 0,
        borderRadius: "15px",
        position: "absolute",
        top: "100%",
        right: 0,
        border: `1px solid ${theme.palette.color1}`,
        boxShadow: `0 0 10px ${theme.palette.color3}`,
        backgroundColor: "#fff",
        cursor: "default",
    },
    moreInfoPanelActive: {
        display: "block",
    },
    shortData: {
        textAlign: "center",
        padding: "20px 10px",
    },
    fullName: {
        fontSize: "16px",
        fontWeight: 600,
    },
    email: {
        fontSize: "13px",
        fontWeight: 600,
        color: theme.palette.color4,
    },
    acceptanceInfo: {
        fontSize: "13px",
        fontWeight: 600,
        color: theme.palette.color7,
    },
    userProfileLink: {
        fontWeight: 600,
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        padding: "15px 10px",
        color: theme.palette.color1,
        borderTop: `1px solid ${theme.palette.color3}`,
        borderBottom: `1px solid ${theme.palette.color3}`,
        "&:hover": {
            color: theme.palette.color1,
            backgroundColor: theme.palette.color6,
        },
    },
    userProfileLinkIcon: {
        width: "24px",
        height: "24px",
    },
    moreInfoPanelLogoutButton: {
        backgroundColor: theme.palette.color1,
        color: "#fff",
        margin: "20px auto",
        display: "table",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        },
    },
}))

export default useStylesHeader;