import React from 'react';
import {Container} from "@mui/material";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import useStylesMain from "@components/layout/Main/styles";

const NotFoundPage = () => {
    const {classes: mainStyles} = useStylesMain();

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Strona nie istnieje</h1>
                <div className={mainStyles.contentBody}>
                    <div className={mainStyles.contentBodyFull}>
                        <DisplayMessageContainer type={DISPLAY_MESSAGES.PAGE_NOT_FOUND}/>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default NotFoundPage;