import {
    RECOVER_PASSWORD_INIT,
    RECOVER_PASSWORD_SUCCESS,
    RECOVER_PASSWORD_FAILURE
} from "@store/types/user";

import {client} from "@api/client";
import ROUTES from "@consts/routes";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const recoverPasswordInit = () => ({
    type: RECOVER_PASSWORD_INIT
});

const recoverPasswordSuccess = () => ({
    type: RECOVER_PASSWORD_SUCCESS
});

const recoverPasswordFailure = (errors) => ({
    type: RECOVER_PASSWORD_FAILURE,
    payload: errors
});

const recoverPassword = (data) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.PASSWORD_RECOVER));
        await dispatch(recoverPasswordInit());

        const path = '/api/recover-password';

        const headers = {};

        data = {
            ...data,
            resetPasswordUrlPattern: `${window.location.origin}${ROUTES.RESET_PASSWORD}`,
        }

        try {
            await client('POST', path, data, headers);

            await dispatch(recoverPasswordSuccess());
        } catch (error) {
            if (error.response) {
                await dispatch(recoverPasswordFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.PASSWORD_RECOVER));
    }
}
export {
    recoverPasswordInit,
    recoverPassword,
};