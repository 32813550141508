import {makeStyles} from "tss-react/mui";

const useStylesMaintenance = makeStyles()(theme => ({
    maintenance: {
        background: theme.palette.color6,
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        overflow: "hidden",
    },
    logoCircle: {
        position: "fixed",
        width: "550px",
        animation: "rotation 180s infinite linear",
    },
    content: {
        width: "300px",
        height: "300px",
        borderRadius: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        boxShadow: `0 1px 2px ${theme.palette.color5}`,
    },
    logo: {
        display: "table",
        height: "75px",
        shapeRendering: "geometricPrecision"
    },
    title: {
        margin: "15px 0 0 0",
        fontSize: "20px",
    },
    description: {
        margin: "5px 0 0 0",
        fontSize: "14px",
    },
}));

export default useStylesMaintenance;