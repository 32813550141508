import {
    SEND_PARTICIPATION_DATA_INIT,
    SEND_PARTICIPATION_DATA_SUCCESS,
    SEND_PARTICIPATION_DATA_FAILURE
} from "@store/types/actionPage";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {getAccessToken} from "@tools/tokens";
import partnerLeadId from "@tools/partnerLeadId";
import LOADER_PLACES from "@consts/loaderPlaces";

const sendParticipationDataInit = () => ({
    type: SEND_PARTICIPATION_DATA_INIT
});
const sendParticipationDataSuccess = (data) => ({
    type: SEND_PARTICIPATION_DATA_SUCCESS,
    payload: data
});

const sendParticipationDataFailure = (errors) => ({
    type: SEND_PARTICIPATION_DATA_FAILURE,
    payload: errors
});

const sendParticipationData = (data) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.PARTICIPATION_SEND_DATA));
        await dispatch(sendParticipationDataInit());

        const path = '/api/participation';

        let headers = {
            Authorization: `Bearer ${getAccessToken()}`
        };

        try {
            const response = await client('POST', path, data, headers);
            const responseData = response.data.data;

            partnerLeadId.removeAll();

            await dispatch(sendParticipationDataSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(sendParticipationDataFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.PARTICIPATION_SEND_DATA));
    }
}

export {
    sendParticipationDataInit,
    sendParticipationData
};