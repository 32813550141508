import React from 'react';
import Logo from "@assets/logos/logo.png";
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import {Container} from "@mui/material";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import useStylesMain from "@components/layout/Main/styles";

const RecoverPassword = (props) => {
    const {fields, user, handleFormValid} = props;
    const {classes: mainStyles} = useStylesMain();

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Odzyskiwanie hasła</h1>
                <div className={mainStyles.contentBody}>
                    <div className={mainStyles.contentBodyFull}>
                        {user.profile.recoverPasswordSuccess ? (
                            <DisplayMessageContainer type={DISPLAY_MESSAGES.RECOVER_PASSWORD_CHECK_EMAIL}/>
                        ) : (
                            <>
                                <img className={mainStyles.logo} src={Logo} alt="Logo"/>
                                <p className={mainStyles.formDescription}>Odzyskaj hasło do Konta Bonus</p>
                                <BasicFormBuilderContainer
                                    fields={fields}
                                    errors={user.profile.recoverPasswordErrors}
                                    handleFormValid={handleFormValid}
                                    btnText="Odzyskaj hasło"
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default RecoverPassword;