import React from 'react';
import Logo from "@assets/logos/logo.png";
import ROUTES from "@consts/routes";
import {Link} from "react-router-dom";
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import {Container} from "@mui/material";
import ActionsSectionContainer from "@components/views/shared/ActionsSection";
import useStylesMain from "@components/layout/Main/styles";

const Login = (props) => {
    const {fields, errors, handleFormValid} = props;
    const {classes: mainStyles, cx} = useStylesMain();

    return (
        <>
            <Container>
                <div className={mainStyles.content}>
                    <h1 className={mainStyles.contentTitle}>Logowanie</h1>
                    <div className={mainStyles.contentBody}>
                        <div className={mainStyles.contentBodyFull}>
                            <img className={mainStyles.logo} src={Logo} alt="Logo"/>
                            <p className={mainStyles.formDescription}>Zaloguj się do profilu uczestnika</p>
                            <BasicFormBuilderContainer
                                fields={fields}
                                errors={errors}
                                handleFormValid={handleFormValid}
                                btnText="Zaloguj się"
                            />

                            <div className={mainStyles.loginFormLinks}>
                                <p className={mainStyles.loginFormLinkParagraph}>
                                    Nie pamiętasz hasła? <Link to={ROUTES.RECOVER_PASSWORD}>Odzyskaj je tutaj</Link>
                                </p>
                                <p className={cx(mainStyles.loginFormLinkParagraph, mainStyles.loginFormLinkToRegisterParagraph)}>
                                    Nie masz jeszcze profilu uczestnika? <Link to={ROUTES.REGISTER}>Zarejestruj
                                    się</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <div className={mainStyles.actionsSectionContainer}>
                <ActionsSectionContainer/>
            </div>
        </>
    );
}

export default Login;