import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getActionPage, getActionPageInit} from "@store/actions/actionPage";
import ActionPage from "@components/views/ActionPage/Main";

const ActionPageContainer = (props) => {
    return (
        <ActionPage {...props}/>
    );
}

const mapStateToProps = (state) => ({
    actionPage: state.actionPage,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getActionPageInit,
            getActionPage,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionPageContainer);
