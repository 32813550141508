import {
    DELETE_ACCOUNT_INIT,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {getAccessToken} from "@tools/tokens";
import {logout} from "@store/actions/user";
import LOADER_PLACES from "@consts/loaderPlaces";

const deleteAccountInit = () => ({
    type: DELETE_ACCOUNT_INIT,
});

const deleteAccountSuccess = (data) => ({
    type: DELETE_ACCOUNT_SUCCESS,
    payload: data
});

const deleteAccountFailure = (errors) => ({
    type: DELETE_ACCOUNT_FAILURE,
    payload: errors
});

const deleteAccount = (data, navigate) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.PROFILE_DELETE));
        await dispatch(deleteAccountInit());

        const path = '/api/profile';

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client('DELETE', path, data, headers);
            const responseData = response.data.data;

            await dispatch(deleteAccountSuccess(responseData));

            await dispatch(logout(true));

            alert('Profil uczestnika został usunięty');
        } catch (error) {
            if (error.response) {
                await dispatch(deleteAccountFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.PROFILE_DELETE));
    }
}

export {
    deleteAccountInit,
    deleteAccount,
};