import React from "react";
import Main from "@components/layout/Main/Main";

const MainContainer = (props) => {
    return (
        <Main {...props}/>
    )
}

export default MainContainer;

