import {
    GET_FAQ_ITEMS_INIT,
    GET_FAQ_ITEMS_SUCCESS,
    GET_FAQ_ITEMS_FAILURE
} from "@store/types/faq";

const initialState = {
    faqItems: null,
    errors: null,
}

const faqReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FAQ_ITEMS_INIT: {
            return initialState;
        }
        case GET_FAQ_ITEMS_SUCCESS: {
            return {
                faqItems: action.payload,
                errors: initialState.errors,
            };
        }
        case GET_FAQ_ITEMS_FAILURE: {
            return {
                faqItems: initialState.faqItems,
                errors: action.payload
            };
        }
        default:
            return state;
    }
}

export default faqReducer;


