import React, {useEffect, useState} from 'react';
import {Button, Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import actionImageMask from "@assets/actions/actionImageMask.png";
import ComplaintsDataDialogContainer
    from "@components/views/Complaints/ComplaintsDataDialog";
import Snackbar from "@mui/material/Snackbar";
import SkeletonContentContainer from "@components/shared/SkeletonContent";
import SKELETONS from "@consts/skeletons";
import useStylesMain from "@components/layout/Main/styles";
import useStylesComplaints from "./styles";
import {useMount} from "@s-ui/react-hooks";

const Complaints = (props) => {
    const {classes: mainStyles} = useStylesMain();
    const {classes: complaintsStyles} = useStylesComplaints();

    const [complaintsItem, setComplaintsItem] = useState(null);
    const [successMessageActive, setSuccessMessageActive] = useState(false);

    useMount(() => {
        props.actions.getComplaints();
    });

    useEffect(() => {
        if (props.complaints.complaintsDataSent) {
            setSuccessMessageActive(true);
            setComplaintsItem(null);
        }
    }, [props.complaints.complaintsDataSent]);

    useEffect(() => {
        return () => {
            props.actions.getComplaintsInit();
        };
    }, [props.actions]);

    const getContent = () => {
        if (props.complaints.complaintsItems?.length > 0) {
            return (
                <>
                    <Grid container spacing={3}>
                        {props.complaints.complaintsItems.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                <div className={complaintsStyles.action}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <div className={complaintsStyles.topContainer}>
                                                <div className={complaintsStyles.imageContainer}>
                                                    <img
                                                        className={complaintsStyles.imageMask}
                                                        src={actionImageMask}
                                                        alt={item.action.name}
                                                    />
                                                    <img
                                                        className={complaintsStyles.image}
                                                        src={item.action.thumbUrl}
                                                        alt={item.action.name}
                                                    />
                                                </div>

                                                <div className={complaintsStyles.imageMessage}>
                                                    <div className={complaintsStyles.imageMessageTitle}>
                                                        Reklamacje można składać do
                                                    </div>
                                                    <div className={complaintsStyles.imageMessageDate}>
                                                        {item.action.complaintsDateTo}
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={0} className={complaintsStyles.actionContent}>
                                        <Grid item xs={12}>
                                            <div className={complaintsStyles.name}>
                                                {item.action.name}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className={complaintsStyles.parametersContainer}>
                                                <div className={complaintsStyles.parameter}>
                                                    <div className={complaintsStyles.parameterName}>
                                                        Data ostatniej reklamacji
                                                    </div>
                                                    <div className={complaintsStyles.parameterValue}>
                                                        {item.lastComplaintDate
                                                            ? item.lastComplaintDate
                                                            : 'Nie złożono reklamacji'
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div className={complaintsStyles.ctaContainer}>
                                                <Button
                                                    className={
                                                        item.lastComplaintDate
                                                            ? complaintsStyles.ctaAddNextData
                                                            : complaintsStyles.ctaAddData
                                                    }
                                                    onClick={() => {
                                                        setComplaintsItem(item);
                                                    }}
                                                >
                                                    {item.lastComplaintDate
                                                        ? (<>Dodaj kolejną reklamację</>)
                                                        : (<>Dodaj nową reklamację</>)
                                                    }
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        ))}
                    </Grid>

                    {complaintsItem && (
                        <ComplaintsDataDialogContainer
                            complaintsItem={complaintsItem}
                            setComplaintsItem={setComplaintsItem}
                        />
                    )}

                    <Snackbar
                        open={successMessageActive}
                        autoHideDuration={5000}
                        onClose={() => setSuccessMessageActive(false)}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        message="Reklamacja została wysłana"
                        className={complaintsStyles.successSnackbar}
                    />
                </>
            )
        } else if (props.complaints.complaintsItems?.length === 0) {
            return (
                <div className={mainStyles.contentBodyFull}>
                    <DisplayMessageContainer type={DISPLAY_MESSAGES.COMPLAINTS_EMPTY}/>
                </div>
            )
        } else if (props.complaints.complaintsItemsErrors) {
            return (
                <div className={mainStyles.contentBodyFull}>
                    <DisplayMessageContainer type={DISPLAY_MESSAGES.SOMETHING_WENT_WRONG}/>
                </div>
            )
        } else if (!props.complaints.complaintsItems && !props.complaints.complaintsItemsErrors) {
            return (
                <SkeletonContentContainer type={SKELETONS.COMPLAINTS}/>
            )
        }
    }

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Reklamacje</h1>
                <div className={mainStyles.contentBody}>
                    {getContent()}
                </div>
            </div>
        </Container>
    );
};

export default Complaints;