import {
    ACTIVATE_ACCOUNT_INIT,
    ACTIVATE_ACCOUNT_SUCCESS,
    ACTIVATE_ACCOUNT_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const activateAccountInit = () => ({
    type: ACTIVATE_ACCOUNT_INIT
});

const activateAccountSuccess = () => ({
    type: ACTIVATE_ACCOUNT_SUCCESS,
});

const activateAccountFailure = (errors) => ({
    type: ACTIVATE_ACCOUNT_FAILURE,
    payload: errors
});

const activateAccount = (data) => {
    return async (dispatch) => {
        await dispatch(setMultiLoader(LOADER_PLACES.PROFILE_ACTIVATE));
        await dispatch(activateAccountInit());

        const path = '/api/auth/activate';

        const headers = {};

        try {
            await client('POST', path, data, headers);

            await dispatch(activateAccountSuccess());
        } catch (error) {
            if (error.response) {
                await dispatch(activateAccountFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.PROFILE_ACTIVATE));
    }
}

export default activateAccount;