import React, {useState} from 'react';
import InternetConnection from "@components/layout/InternetConnection/Main";
import {useMount} from "@s-ui/react-hooks";

const InternetConnectionContainer = () => {
    const [connectionStatus, setConnectionStatus] = useState("");

    useMount(() => {
        window.addEventListener('online', () => {
            setConnectionStatus("online");
        });
        window.addEventListener('offline', () => {
            setConnectionStatus("offline");
        });
    });

    const reload = () => {
        window.location.reload(true);
    }

    return (
        <InternetConnection
            connectionStatus={connectionStatus}
            handleReload={reload}
            handleSetConnectionStatus={setConnectionStatus}
        />
    )
}

export default InternetConnectionContainer;