import {
    GET_CONTACT_ADMIN_INFO_INIT,
    GET_CONTACT_ADMIN_INFO_SUCCESS,
    GET_CONTACT_ADMIN_INFO_FAILURE,
} from "@store/types/contact";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const getAdminInfoInit = () => ({
    type: GET_CONTACT_ADMIN_INFO_INIT
});
const getAdminInfoSuccess = (data) => ({
    type: GET_CONTACT_ADMIN_INFO_SUCCESS,
    payload: data
});

const getAdminInfoFailure = (errors) => ({
    type: GET_CONTACT_ADMIN_INFO_FAILURE,
    payload: errors
});

const getAdminInfo = () => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.CONTACT_ADMIN_INFO));
        await dispatch(getAdminInfoInit());

        const path = '/api/agreements/CONTACT';

        const headers = {};

        try {
            const response = await client('GET', path, null, headers);
            const responseData = response.data.data;
            await dispatch(getAdminInfoSuccess(responseData));
        } catch (error) {
            if (error) {
                await dispatch(getAdminInfoFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.CONTACT_ADMIN_INFO));
    }
}

export {
    getAdminInfoInit,
    getAdminInfo
};