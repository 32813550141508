import React, {useEffect, useState} from 'react';
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {Container} from "@mui/material";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import Snackbar from "@mui/material/Snackbar";
import useStylesUserProfile from "./styles";
import useStylesMain from "@components/layout/Main/styles";

const UserProfile = (props) => {
    const {classes: mainStyles, cx} = useStylesMain();
    const {classes: userProfileStyles} = useStylesUserProfile();

    const {
        user,
        formFields,
        initActions,
        handleUpdateProfileFormValid,
        handleChangePasswordFormValid,
        handleDeleteAccountFormValid,
    } = props;

    const {updateProfileSuccess, changePasswordSuccess} = user.profile;

    const [activeContent, setActiveContent] = useState('user-data');
    const [deleteAccountSwitchActive, setDeleteAccountSwitchActive] = useState(false);
    const [successMessageType, setSuccessMessageType] = useState('');

    const handleTabBtnClick = (contentType) => {
        initActions();
        setSuccessMessageType('');
        setActiveContent(contentType);
    }

    const handleDeleteAccountSwitchChange = () => {
        setDeleteAccountSwitchActive(!deleteAccountSwitchActive);
    }

    useEffect(() => {
        updateProfileSuccess && setSuccessMessageType('user-data');
        changePasswordSuccess && setSuccessMessageType('change-password');
    }, [updateProfileSuccess, changePasswordSuccess]);

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Ustawienia profilu</h1>
                <div className={mainStyles.contentBody}>
                    <div className={mainStyles.contentBodyFull}>
                        <ButtonGroup aria-label="outlined primary button group"
                                     className={userProfileStyles.tabsButtons}>
                            <Button
                                onClick={() => handleTabBtnClick('user-data')}
                                className={cx(userProfileStyles.tabButton, activeContent === 'user-data' && userProfileStyles.tabButtonActive)}
                            >Moje dane</Button>

                            <Button
                                onClick={() => handleTabBtnClick('change-password')}
                                className={cx(userProfileStyles.tabButton, activeContent === 'change-password' && userProfileStyles.tabButtonActive)}
                            >Zmień hasło</Button>

                            <Button
                                onClick={() => handleTabBtnClick('delete-account')}
                                className={cx(userProfileStyles.tabButton, activeContent === 'delete-account' && userProfileStyles.tabButtonActive)}
                            >Usuń profil</Button>
                        </ButtonGroup>

                        <div className={userProfileStyles.tabsContent}>
                            {activeContent === 'user-data' && (
                                <div className={userProfileStyles.tabContent}>
                                    <Snackbar
                                        open={successMessageType === 'user-data'}
                                        autoHideDuration={5000}
                                        onClose={() => {setSuccessMessageType(''); initActions()}}
                                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                        message="Dane zostały zaktualizowane"
                                        className={userProfileStyles.successSnackbar}
                                    />

                                    <div className={userProfileStyles.messageContainer}>
                                        <DisplayMessageContainer type={DISPLAY_MESSAGES.USER_PROFILE_UPDATE_PROFILE}/>
                                    </div>

                                    {user.auth.userData.waitingForEditAcceptance && (
                                        <div className={userProfileStyles.warningInfo}>
                                            Niektóre dane zostały wysłane do weryfikacji i nie można ich zmienić
                                        </div>
                                    )}

                                    <BasicFormBuilderContainer
                                        fields={formFields.userData}
                                        errors={user.profile.updateProfileErrors}
                                        handleFormValid={handleUpdateProfileFormValid}
                                        btnText="Aktualizuj dane"
                                    />
                                </div>
                            )}

                            {activeContent === 'change-password' && (
                                <div className={userProfileStyles.tabContent}>
                                    <Snackbar
                                        open={successMessageType === 'change-password'}
                                        autoHideDuration={5000}
                                        onClose={() => {setSuccessMessageType(''); initActions()}}
                                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                        message="Hasło zostało zmienione"
                                        className={userProfileStyles.successSnackbar}
                                    />

                                    <div className={userProfileStyles.messageContainer}>
                                        <DisplayMessageContainer type={DISPLAY_MESSAGES.USER_PROFILE_CHANGE_PASSWORD}/>
                                    </div>

                                    <div className={userProfileStyles.warningInfo}>
                                        Zalogowany
                                        użytkownik: {user.auth.userData.email} ({user.auth.userData.firstName} {user.auth.userData.lastName})
                                    </div>

                                    <BasicFormBuilderContainer
                                        fields={formFields.changePassword}
                                        errors={user.profile.changePasswordErrors}
                                        handleFormValid={handleChangePasswordFormValid}
                                        btnText="Zmień hasło"
                                    />
                                </div>
                            )}

                            {activeContent === 'delete-account' && (
                                <div className={userProfileStyles.tabContent}>
                                    <div className={userProfileStyles.messageContainer}>
                                        <DisplayMessageContainer type={DISPLAY_MESSAGES.USER_PROFILE_DELETE_PROFILE}/>
                                    </div>

                                    <div className={userProfileStyles.warningInfo}>
                                        UWAGA: tego procesu nie można cofnąć
                                    </div>

                                    <FormControlLabel
                                        className={userProfileStyles.deleteAccountSwitcherLabel}
                                        control={<Switch
                                            color="default"
                                            className={userProfileStyles.deleteAccountSwitcher}
                                            checked={deleteAccountSwitchActive}
                                            onChange={handleDeleteAccountSwitchChange}
                                            name="deleteAccountSwitch"/>}
                                        label={`Chcę usunąć profil uczestnika ${user.auth.userData.email} (${user.auth.userData.firstName} ${user.auth.userData.lastName})`}
                                    />
                                    {deleteAccountSwitchActive && (
                                        <BasicFormBuilderContainer
                                            fields={formFields.deleteAccount}
                                            errors={user.profile.deleteAccountErrors}
                                            handleFormValid={handleDeleteAccountFormValid}
                                            btnText="Usuń profil uczestnika"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default UserProfile;