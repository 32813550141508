import {
    GET_STATISTICS_INIT,
    GET_STATISTICS_SUCCESS,
    GET_STATISTICS_FAILURE,
} from "@store/types/statistics";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {getAccessToken} from "@tools/tokens";
import LOADER_PLACES from "@consts/loaderPlaces";

const getStatisticsInit = () => ({
    type: GET_STATISTICS_INIT
});
const getStatisticsSuccess = (data) => ({
    type: GET_STATISTICS_SUCCESS,
    payload: data
});

const getStatisticsFailure = (errors) => ({
    type: GET_STATISTICS_FAILURE,
    payload: errors
});

const getStatistics = () => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.STATISTICS));
        await dispatch(getStatisticsInit());

        const path = '/api/action-stats';

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client('GET', path, null, headers);
            const responseData = response.data.data;
            await dispatch(getStatisticsSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(getStatisticsFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.STATISTICS));
    }
}

export {
    getStatisticsInit,
    getStatistics
};