import LOADER_TYPES from "@consts/loaderTypes";
import LOADER_PLACES from "@consts/loaderPlaces";

const LOADER_CONFIG = [
    {
        name: LOADER_PLACES.IS_SITE_ENABLED,
        type: LOADER_TYPES.FULLSCREEN,
    },
    {
        name: LOADER_PLACES.CONTACT_ADMIN_INFO,
        type: LOADER_TYPES.FULLSCREEN_FOR_SKELETON,
    },
    {
        name: LOADER_PLACES.CONTACT_SEND_MESSAGE,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.REGISTER_AGREEMENTS,
        type: LOADER_TYPES.FULLSCREEN_FOR_SKELETON,
    },
    {
        name: LOADER_PLACES.ACTION_PAGE,
        type: LOADER_TYPES.FULLSCREEN,
    },
    {
        name: LOADER_PLACES.ACTIONS_LIST_PAGE,
        type: LOADER_TYPES.FULLSCREEN_FOR_SKELETON,
    },
    {
        name: LOADER_PLACES.ACTIONS_LIST_SECTION,
        type: LOADER_TYPES.FULLSCREEN,
    },
    {
        name: LOADER_PLACES.FAQ_ITEMS,
        type: LOADER_TYPES.FULLSCREEN_FOR_SKELETON,
    },
    {
        name: LOADER_PLACES.PROFILE_ACTIVATE,
        type: LOADER_TYPES.FULLSCREEN,
    },
    {
        name: LOADER_PLACES.PROFILE_UPDATE,
        type: LOADER_TYPES.FULLSCREEN_FOR_SKELETON,
    },
    {
        name: LOADER_PLACES.PROFILE_DELETE,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.PASSWORD_RESET_CHECK_TOKEN,
        type: LOADER_TYPES.FULLSCREEN,
    },
    {
        name: LOADER_PLACES.PASSWORD_RESET,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.PASSWORD_CHANGE,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.REGISTER,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.PASSWORD_RECOVER,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.LOGIN,
        type: LOADER_TYPES.FULLSCREEN,
    },
    {
        name: LOADER_PLACES.AUTOLOGIN,
        type: LOADER_TYPES.FULLSCREEN,
    },
    {
        name: LOADER_PLACES.LOGOUT,
        type: LOADER_TYPES.FULLSCREEN,
    },
    {
        name: LOADER_PLACES.STATISTICS,
        type: LOADER_TYPES.FULLSCREEN_FOR_SKELETON,
    },
    {
        name: LOADER_PLACES.STATIC_PAGE,
        type: LOADER_TYPES.FULLSCREEN_FOR_SKELETON,
    },
    {
        name: LOADER_PLACES.PAYOUT_ITEMS,
        type: LOADER_TYPES.FULLSCREEN_FOR_SKELETON,
    },
    {
        name: LOADER_PLACES.PAYOUT_SEND_DATA,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.COMPLAINTS_ITEMS,
        type: LOADER_TYPES.FULLSCREEN_FOR_SKELETON,
    },
    {
        name: LOADER_PLACES.COMPLAINTS_SEND_DATA,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
    {
        name: LOADER_PLACES.PARTICIPATION_SEND_DATA,
        type: LOADER_TYPES.FULLSCREEN_TRANSPARENT,
    },
]

export default LOADER_CONFIG;
