import React, {useCallback, useEffect} from 'react';
import StaticPage from "@components/views/StaticPage/Main";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getMetaDescription, getMetaTitle, setMetaDescription, setMetaTitle} from "@tools/metaTags";
import {getStaticPageInit, getStaticPage} from "@store/actions/staticPage";
import NotFoundPageContainer from "@components/views/NotFoundPage";
import PAGES from "@consts/pages";
import {usePrevious} from "@s-ui/react-hooks";
import {useLocation} from "react-router";

const StaticPageContainer = (props) => {
    const {staticPageData, errors} = props.staticPage;

    const location = useLocation();
    const prevLocation = usePrevious(location);

    const getData = useCallback(() => {
        const slug = props.params.slug;
        props.actions.getStaticPage(slug);
    }, [props]);

    const setPageMeta = (title, description) => {
        setMetaTitle(title);
        setMetaDescription(description);
    }

    const goToSection = () => {
        const hash = window.location.hash.substr(1);

        if (hash) {
            const element = document.getElementById(hash);

            if (element) {
                element.scrollIntoView();
            }
        }
    }

    useEffect(() => {
        if (location?.pathname !== prevLocation?.pathname) {
            getData();
        }

        if (props.staticPage.staticPageData) {
            const seoMeta = props.staticPage.staticPageData.seoMeta;

            setPageMeta(seoMeta.title, seoMeta.description);

            goToSection(); //if hash
        }

        if (props.staticPage?.errors?.find(error => error.code === 'PAGE:PAGE_NOT_FOUND')) {
            setPageMeta(getMetaTitle(PAGES.NOT_FOUND), getMetaDescription(PAGES.NOT_FOUND));
        }
    }, [getData, location, prevLocation, props.staticPage]);

    useEffect(() => {
        return () => {
            props.actions.getStaticPageInit();
        }
    }, [props.actions]);

    return (
        <>
            {errors && errors.find(error => error.code === 'PAGE:PAGE_NOT_FOUND') ? (
                <NotFoundPageContainer/>
            ) : (
                <StaticPage
                    staticPageData={staticPageData}
                    errors={errors}
                />
            )}
        </>

    )
}

const mapStateToProps = (state) => ({
    staticPage: state.staticPage,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getStaticPageInit,
            getStaticPage,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StaticPageContainer);