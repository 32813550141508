import React from 'react';
import LandingPageContainer from "@components/views/StartPage/LandingPage";
import ActionsListContainer from "@components/views/StartPage/ActionsList";

const StartPage = (props) => {
    return (
        <>
            {props.user.auth.isLoggedIn ? <ActionsListContainer/> : <LandingPageContainer/>}
        </>
    );
}

export default StartPage;