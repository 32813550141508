import React, {useEffect} from 'react';
import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import actionImageMask from "@assets/actions/actionImageMask.png";
import SKELETONS from "@consts/skeletons";
import SkeletonContentContainer from "@components/shared/SkeletonContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {getParticipationStatusText} from "@tools/participationStatuses";
import PARTICIPATION_STATUSES from "@consts/participationStatuses";
import useStylesStatistics from "./styles";
import useStylesMain from "@components/layout/Main/styles";
import {useMount} from "@s-ui/react-hooks";

const Statistics = (props) => {
    const {classes: mainStyles} = useStylesMain();
    const {classes: statisticsStyles} = useStylesStatistics();

    // regulation
    const [openedRegulation, setOpenedRegulation] = React.useState(null);
    const openRegulationDialog = (uuid) => {
        setOpenedRegulation(uuid);
    };
    const closeRegulationDialog = () => {
        setOpenedRegulation(null);
    };

    useMount(() => {
        props.actions.getStatistics();
    });

    useEffect(() => {
        return () => {
            props.actions.getStatisticsInit();
        };
    }, [props.actions]);

    const getContent = () => {
        if (props.statistics.statisticsItems?.length > 0) {
            return (
                <Grid container spacing={3}>
                    {props.statistics.statisticsItems.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <div className={statisticsStyles.action}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <div className={statisticsStyles.topContainer}>
                                            <div className={statisticsStyles.imageContainer}>
                                                <img
                                                    className={statisticsStyles.imageMask}
                                                    src={actionImageMask}
                                                    alt={item.name}
                                                />
                                                <img
                                                    className={statisticsStyles.image}
                                                    src={item.thumbUrl}
                                                    alt={item.name}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={0} className={statisticsStyles.actionContent}>
                                    <Grid item xs={12}>
                                        <div className={statisticsStyles.name}>
                                            {item.name}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={statisticsStyles.parametersContainer}>
                                            <div className={statisticsStyles.parameter}>
                                                <div className={statisticsStyles.parameterName}>
                                                    Status
                                                </div>
                                                <div className={statisticsStyles.parameterValue}>
                                                    {getParticipationStatusText(item.participation.status)}
                                                </div>
                                            </div>


                                            {[PARTICIPATION_STATUSES.ACCEPTED, PARTICIPATION_STATUSES.PAID].includes(item.participation.status) && (
                                                <div className={statisticsStyles.parameter}>
                                                    <div className={statisticsStyles.parameterName}>
                                                        Bonus
                                                    </div>
                                                    <div className={statisticsStyles.parameterValue}>
                                                        <div className={statisticsStyles.parameterValueRow}>
                                                            Kwota bonusu: {item.cashReward} zł
                                                        </div>
                                                        {item.voucherRewards > 0 && (
                                                            <div className={statisticsStyles.parameterValueRow}>
                                                                Kupony (np. bon, e-kod): {item.voucherRewards}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {item.referralsEnabled && (<>
                                                <div className={statisticsStyles.parameter}>
                                                    <div className={statisticsStyles.parameterName}>
                                                        Ilość poleceń
                                                    </div>
                                                    <div className={statisticsStyles.parameterValue}>
                                                        <div className={statisticsStyles.parameterValueRow}>
                                                            Liczba wszystkich: {item.totalReferrals}
                                                        </div>
                                                        <div className={statisticsStyles.parameterValueRow}>
                                                            Zaakceptowane: {item.referralsAccepted} {item.referralsLimit && <>(limit: {item.referralsLimit})</>}
                                                        </div>
                                                    </div>
                                                </div>

                                                {[PARTICIPATION_STATUSES.ACCEPTED, PARTICIPATION_STATUSES.PAID].includes(item.participation.status) && (
                                                    <div className={statisticsStyles.parameter}>
                                                        <div className={statisticsStyles.parameterName}>
                                                            Premia za polecenie
                                                        </div>
                                                        <div className={statisticsStyles.parameterValue}>
                                                            <div className={statisticsStyles.parameterValueRow}>
                                                                Kwota premii: {item.referralCashReward} zł
                                                            </div>
                                                            {item.referralVoucherRewards > 0 && (
                                                                <div className={statisticsStyles.parameterValueRow}>
                                                                    Kupony (np. bon,
                                                                    e-kod): {item.referralVoucherRewards}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </>)}

                                            <div className={statisticsStyles.parameter}>
                                                <div className={statisticsStyles.parameterName}>
                                                    Data rozliczenia akcji
                                                </div>
                                                <div className={statisticsStyles.parameterValue}>
                                                    {item.settlementDate}
                                                </div>
                                            </div>
                                        </div>

                                        <Grid item xs={12}>
                                            <div className={statisticsStyles.regulationsContainer}>
                                                {item?.regulationsPages.map((regulationPage, index) => (
                                                    <div className={statisticsStyles.regulationContainer} key={index}>
                                                        <Link
                                                            to={`/strona/${regulationPage.slug}`}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                openRegulationDialog(regulationPage.uuid);
                                                            }}
                                                            className={statisticsStyles.regulationOpenLink}
                                                        >
                                                            {regulationPage.title}
                                                        </Link>

                                                        <Dialog
                                                            fullWidth={false}
                                                            maxWidth={"lg"}
                                                            open={openedRegulation === regulationPage.uuid}
                                                            onClose={closeRegulationDialog}
                                                            aria-labelledby={regulationPage.uuid}
                                                        >
                                                            <DialogTitle id={regulationPage.uuid}
                                                                         className={statisticsStyles.dialogTitle}>
                                                                {regulationPage.title}
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <div
                                                                    className={statisticsStyles.regulationContent}
                                                                    dangerouslySetInnerHTML={{__html: regulationPage.content}}
                                                                />
                                                                {regulationPage.attachments.length > 0 && (
                                                                    <div
                                                                        className={statisticsStyles.regulationAttachments}>
                                                                        <div
                                                                            className={statisticsStyles.attachmentsTitle}>
                                                                            Załączniki
                                                                        </div>
                                                                        <ul>
                                                                            {regulationPage.attachments.map((attachment, index) => (
                                                                                <li key={index}>
                                                                                    <Link
                                                                                        to={{pathname: attachment.url}}
                                                                                        target="_blank"
                                                                                        className={statisticsStyles.regulationAttachment}
                                                                                    >
                                                                                        {attachment.name}
                                                                                    </Link>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </DialogContent>
                                                            <DialogActions
                                                                className={statisticsStyles.buttonsContainer}>
                                                                <Button onClick={closeRegulationDialog}
                                                                        className={statisticsStyles.regulationCloseButton}>
                                                                    Zamknij
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </div>
                                                ))}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            )
        } else if (props.statistics.statisticsItems?.length === 0) {
            return (
                <div className={mainStyles.contentBodyFull}>
                    <DisplayMessageContainer type={DISPLAY_MESSAGES.STATISTICS_EMPTY}/>
                </div>
            )
        } else if (props.statistics.errors) {
            return (
                <div className={mainStyles.contentBodyFull}>
                    <DisplayMessageContainer type={DISPLAY_MESSAGES.SOMETHING_WENT_WRONG}/>
                </div>
            )
        } else if (!props.statistics.statisticsItems && !props.statistics.errors) {
            return (
                <SkeletonContentContainer type={SKELETONS.STATISTICS}/>
            )
        }
    }

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Statystyki</h1>
                <div className={mainStyles.contentBody}>
                    {getContent()}
                </div>
            </div>
        </Container>
    );
};

export default Statistics;