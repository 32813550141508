const PAGE_META_DETAILS = {
    HOME: {
        title: "Strona główna",
        description: "Dołącz do nas i zyskaj BONUS!",
    },
    LOGIN: {
        title: "Logowanie",
        description: "Zaloguj się do profilu uczestnika i sprawdź aktywne Akcje Bonus.",
    },
    REGISTER: {
        title: "Rejestracja",
        description: "Zarejestruj swój profil uczestnika i weź udział w Akcji Bonus.",
    },
    REGISTER_COMPLETED: {
        title: "Rejestracja zakończona",
        description: "",
    },
    ACTIVATE_ACCOUNT: {
        title: "Aktywacja konta",
        description: "",
    },
    RESET_PASSWORD: {
        title: "Zmiana hasła",
        description: "",
    },
    RECOVER_PASSWORD: {
        title: "Odzyskiwanie hasła",
        description: "Odzyskaj lub zmień hasło do profilu uczestnika.",
    },
    STATISTICS: {
        title: "Statystyki",
        description: "",
    },
    STATIC_PAGE: {
        title: "Strona",
        description: "Strona statyczna",
    },
    COMPLAINTS: {
        title: "Reklamacje",
        description: "",
    },
    USER_PROFILE: {
        title: "Ustawienia profilu",
        description: "",
    },
    PAYOUT: {
        title: "Wypłaty",
        description: "",
    },
    ACTION_PAGE: {
        title: "Akcja Bonus",
        description: "Sprawdź aktywne Akcje Bonus i weź udział.",
    },
    ACTION_PAGE_PARTICIPATION: {
        title: "Udział w akcji",
        description: "",
    },
    CONTACT: {
        title: "Kontakt",
        description: "Masz do nas pytanie? Napisz wiadomość lub uzupełnij formularz kontaktowy.",
    },
    FAQ: {
        title: "Pytania i odpowiedzi",
        description: "Sprawdź najczęściej pojawiające się pytania i odpowiedzi do nich.",
    },
    NOT_FOUND: {
        title: "Nie znaleziono strony",
        description: "Strona nie jest dostępna.",
    },
};

export default PAGE_META_DETAILS;