import {combineReducers} from "redux";
import configReducer from "@store/reducers/config";
import authReducer from "@store/reducers/user/auth";
import profileReducer from "@store/reducers/user/profile";
import faqReducer from "@store/reducers/faq";
import staticPageReducer from "@store/reducers/staticPage";
import contactReducer from "@store/reducers/contact";
import statisticsReducer from "@store/reducers/statistics";
import actionsListReducer from "@store/reducers/actionsList";
import actionPageReducer from "@store/reducers/actionPage";
import payoutReducer from "@store/reducers/payout";
import complaintsReducer from "@store/reducers/complaints";

const rootReducer = combineReducers({
    config: configReducer,
    user: combineReducers({
        auth: authReducer,
        profile: profileReducer,
    }),
    statistics: statisticsReducer,
    payout: payoutReducer,
    complaints: complaintsReducer,
    actionsList: actionsListReducer,
    actionPage: actionPageReducer,
    faq: faqReducer,
    staticPage: staticPageReducer,
    contact: contactReducer,
})

export default rootReducer;