import React from 'react';
import {Container} from "@mui/material";
import API_ERRORS from "@consts/apiErrors";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import useStylesMain from "@components/layout/Main/styles";

const ActivateAccount = (props) => {
    const {user} = props;
    const {classes: mainStyles} = useStylesMain();

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Aktywacja konta</h1>
                <div className={mainStyles.contentBody}>
                    <div className={mainStyles.contentBodyFull}>
                        {user.profile.activateAccountSuccess ? (
                            <DisplayMessageContainer type={DISPLAY_MESSAGES.ACTIVATE_ACCOUNT_SUCCESS}/>
                        ) : (
                            <>
                                {user.profile.activateAccountErrors && user.profile.activateAccountErrors.map((error, index) => {
                                    const found = API_ERRORS.find((apiError) => apiError.code === error.code);

                                    return (
                                        <DisplayMessageContainer
                                            key={index}
                                            type={DISPLAY_MESSAGES.ACTIVATE_ACCOUNT_ERROR}
                                            customTitle={found ? found.message : "Wystąpił nieznany błąd"}
                                        />
                                    )
                                })}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ActivateAccount;