import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import ActionAccessToken from "@components/views/ActionPage/ActionAccessToken/Main";
import {useParams} from "react-router";
import {getActionPage} from "@store/actions/actionPage";

const ActionAccessTokenContainer = (props) => {
    const formFields = BASIC_FORM_FIELDS_CONFIG.ACTION_ACCESS_TOKEN;

    const params = useParams();
    const {slug} = params;

    const handleFormValid = (data) => {
        props.actions.getActionPage(slug, data.actionAccessToken);
    }

    return (
        <ActionAccessToken
            fields={formFields}
            actionPage={props.actionPage}
            handleFormValid={handleFormValid}
        />
    );
}

const mapStateToProps = (state) => ({
    actionPage: state.actionPage,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getActionPage,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionAccessTokenContainer);