import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PAGES from "@consts/pages";
import {logout, resetRedirectUserAfterLogoutTo} from "@store/actions/user";
import redirectAfterLogin from "@tools/redirectAfterLogin";
import Authorization from "@components/shared/Authorization/Authorization";
import {getMetaDescription, getMetaTitle, setMetaDescription, setMetaTitle} from "@tools/metaTags";
import {useMount} from "@s-ui/react-hooks";

const AuthorizationContainer = (props) => {
    const [isBuilderReady, setIsBuilderReady] = useState(false);

    const init = async () => {
        const {user, routeConfig} = props;

        if (user.auth.isLoggedIn && routeConfig.logoutBeforeMount) {
            await props.actions.logout(false);
        }

        if (!user.auth.isLoggedIn) {
            if (routeConfig.authRequired || routeConfig.name === PAGES.ACTION_PAGE) {
                redirectAfterLogin.set();
            }
        }
    }

    useMount(() => {
        window.scrollTo(0, 0);

        init().then(() => {
            setIsBuilderReady(true);
        });
    });

    useEffect(() => {
        const setMetaTags = () => {
            const routeConfig = props.routeConfig;

            const title = getMetaTitle(routeConfig.name);
            const description = getMetaDescription(routeConfig.name);

            setMetaTitle(title);
            setMetaDescription(description);
        }

        setMetaTags();
    }, [props.routeConfig]);

    return (
        <>{isBuilderReady && <Authorization {...props}/>}</>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
    staticPage: state.staticPage,
    actionPage: state.actionPage,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            logout,
            resetRedirectUserAfterLogoutTo
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthorizationContainer);