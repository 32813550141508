import {makeStyles} from "tss-react/mui";

const useStylesBasicFormBuilder = makeStyles()(theme => ({
    form: {
        width: "100%",
        maxWidth: "400px",
        margin: "0 auto",
        display: "block",
    },
    fieldRow: {
        width: "100%",
        margin: "0 0 15px 0",
        position: "relative",
    },
    textField: {
        width: "100%",
    },
    textareaField: {
        width: "100%",
    },
    selectField: {
        width: "100%",
    },
    checkboxField: {
        width: "calc(100% + 11px)",
        marginRight: 0,
        "& [class*=-checked]": {
            "& .MuiSvgIcon-root": {
                color: theme.palette.color1,
            },
        },
        "& .MuiSvgIcon-root": {
            color: theme.palette.color5,
        },
        "& .MuiTypography-root": {
            fontSize: "13px",
            textAlign: "justify",
        }
    },
    labelParagraph: {
        margin: 0,
        "& a": {
            textDecoration: "underline",
            color: theme.palette.color1,
        }
    },
    fieldError: {
        fontSize: "13px",
        fontWeight: 600,
        color: theme.palette.color7,
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "20px",
            margin: "0 5px 0 0",
        }
    },
    serverErrors: {
        margin: "0 0 15px 0",
        padding: "5px",
        borderRadius: "5px",
        backgroundColor: theme.palette.color7,
    },
    serverError: {
        fontSize: "13px",
        fontWeight: 600,
        color: "#fff",
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "20px",
            margin: "0 5px 0 0",
        }
    },
    submitBtn: {
        backgroundColor: theme.palette.color1,
        color: "#fff",
        width: "100%",
        height: "50px",
        textTransform: "uppercase",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        }
    },
}));

export default useStylesBasicFormBuilder;