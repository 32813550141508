import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";
import actionImageMask from "@assets/actions/actionImageMask.png";
import Button from "@mui/material/Button";
import React from "react";
import useStylesActionsList from "@components/views/StartPage/ActionsList/styles";

const EndedActions = (props) => {
    const {classes: actionsListStyles, cx} = useStylesActionsList();

    const {actionsItems} = props;

    return (
        <>
            {actionsItems.map((action, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Link to={`/akcja/${action.slug}/`}>
                        <div className={actionsListStyles.action}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <div className={actionsListStyles.topContainer}>
                                        <div className={actionsListStyles.imageContainer}>
                                            <img
                                                className={actionsListStyles.imageMask}
                                                src={actionImageMask}
                                                alt={action.name}
                                            />
                                            <img
                                                className={actionsListStyles.image}
                                                src={action.thumbUrl}
                                                alt={action.name}
                                            />
                                        </div>

                                        <div
                                            className={cx(
                                                actionsListStyles.imageMessage,
                                                actionsListStyles.imageMessageEnded
                                            )}
                                        >
                                            <div className={actionsListStyles.imageMessageTitle}>
                                                Rozliczenie akcji do
                                            </div>
                                            <div className={actionsListStyles.imageMessageDate}>
                                                {action.settlementDate}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={0} className={actionsListStyles.actionContent}>
                                <Grid item xs={12}>
                                    <div className={actionsListStyles.name}>
                                        {action.name}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={actionsListStyles.ctaContainer}>
                                        <Button
                                            className={actionsListStyles.ctaActionEnded}
                                        >Pokaż szczegóły</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Link>
                </Grid>
            ))}
        </>
    );
}

export default EndedActions;