import {
    SEND_PAYOUT_DATA_INIT,
    SEND_PAYOUT_DATA_SUCCESS,
    SEND_PAYOUT_DATA_FAILURE,
} from "@store/types/payout";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {getAccessToken} from "@tools/tokens";
import LOADER_PLACES from "@consts/loaderPlaces";

const sendPayoutDataInit = () => ({
    type: SEND_PAYOUT_DATA_INIT
});
const sendPayoutDataSuccess = (data) => ({
    type: SEND_PAYOUT_DATA_SUCCESS,
    payload: data
});

const sendPayoutDataFailure = (errors) => ({
    type: SEND_PAYOUT_DATA_FAILURE,
    payload: errors
});

const sendPayoutData = (data, participationUuid) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.PAYOUT_SEND_DATA));
        await dispatch(sendPayoutDataInit());

        const path = `/api/payout-data/${participationUuid}`;

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client('PUT', path, data, headers);
            const responseData = response.data.data;
            await dispatch(sendPayoutDataSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(sendPayoutDataFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.PAYOUT_SEND_DATA));
    }
}

export {
    sendPayoutDataInit,
    sendPayoutData
};