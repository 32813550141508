import React, {useEffect, useState} from 'react';
import Header from "@components/layout/Header/Main";
import {connect} from "react-redux";
import MENU_ITEMS from "@consts/menuItems";
import {logout} from "@store/actions/user";
import {bindActionCreators} from "redux";

const HeaderContainer = (props) => {
    const [menuItems, setMenuItems] = useState(null);

    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [morePanelVisible, setMorePanelVisible] = useState(false);

    const setItems = () => {
        const userLoginStatus = props.user.auth.isLoggedIn ? 'loggedIn' : 'notLoggedIn';

        setMenuItems(MENU_ITEMS[userLoginStatus]);
    }

    const showMenu = () => {
        document.getElementById('navigation-items-list').scrollTo(0, 0);

        setMobileMenuVisible(true);
    }

    const hideMenu = () => {
        setMobileMenuVisible(false);
    }

    const logout = () => {
        props.actions.logout(true);
    }

    useEffect(() => {
        setItems();
    });

    return (
        <>
            {menuItems && <Header
                user={props.user}
                morePanelVisible={morePanelVisible}
                setMorePanelVisible={setMorePanelVisible}
                logout={logout}
                menuItems={menuItems}
                mobileMenuVisible={mobileMenuVisible}
                showMenu={showMenu}
                hideMenu={hideMenu}
            />}
        </>

    );
}


function mapStateToProps(state) {
    return {
        user: state.user
    };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            logout,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderContainer);