import PARTICIPATION_STATUSES from "@consts/participationStatuses";

export const getParticipationStatusText = (status) => {
    switch (status) {
        case PARTICIPATION_STATUSES.CLICK: {
            return "Klik";
        }
        case PARTICIPATION_STATUSES.LEAD: {
            return "Oczekujący - Wniosek złożony";
        }
        case PARTICIPATION_STATUSES.ACCEPTED: {
            return "Zaakceptowany - Bonus przyznany";
        }
        case PARTICIPATION_STATUSES.REJECTED: {
            return "Odrzucony - Bonus nieprzyznany";
        }
        case PARTICIPATION_STATUSES.PAID: {
            return "Bonus wypłacony";
        }
        default: {
            return "Nieznany"
        }
    }
}