import reactCookies from "react-cookies";

const partnerLeadId = {
    set(actionUuid, partnerLeadId) {
        reactCookies.save(`partnerLeadId_${actionUuid}`, partnerLeadId, {path: '/', maxAge: null});
    },
    get(actionUuid) {
        return reactCookies.load(`partnerLeadId_${actionUuid}`);
    },
    removeAll() {
        const regex = /partnerLeadId/;

        if (document.cookie && document.cookie !== '') {
            const split = document.cookie.split(';');

            split.forEach((cookie, index) => {
                let cookieArray = split[index].split("=");
                let cookieName = cookieArray[0].trim();
                if (cookieName.match(regex)) {
                    reactCookies.remove(cookieName, {path: '/'});
                }
            })
        }
    }
}

export default partnerLeadId;