import React from 'react';
import Statistics from "@components/views/Statistics/Main";
import {connect} from "react-redux";
import {getStatistics, getStatisticsInit} from "@store/actions/statistics";
import {bindActionCreators} from "redux";

const StatisticsContainer = (props) => {
    return (
        <Statistics {...props}/>
    )
}

const mapStateToProps = (state) => ({
    statistics: state.statistics
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getStatisticsInit,
            getStatistics
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StatisticsContainer);