import React from 'react';
import Logo from "@assets/logos/logo.png";
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import {Container} from "@mui/material";
import useStylesMain from "@components/layout/Main/styles";
import {useParams} from "react-router";

const ActionAccessToken = (props) => {
    const {fields, actionPage, handleFormValid} = props;
    const {classes: mainStyles} = useStylesMain();

    const params = useParams();
    const {slug} = params;

    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Dostęp do treści</h1>
                <div className={mainStyles.contentBody}>
                    <div className={mainStyles.contentBodyFull}>
                        <img className={mainStyles.logo} src={Logo} alt="Logo"/>
                        <p className={mainStyles.formDescription}>Dostęp do <i>{slug}</i></p>
                        <BasicFormBuilderContainer
                            fields={fields}
                            errors={actionPage.hiddenActionPageDetailsErrors}
                            handleFormValid={handleFormValid}
                            btnText="Zatwierdź"
                        />
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ActionAccessToken;