import React, {useEffect} from 'react';
import {connect} from "react-redux";
import MaintenanceContainer from "@components/views/Maintenance";
import LoaderContainer from "@components/layout/Loader";
import {autoLogin} from "@store/actions/user";
import {isSiteEnabled} from "@store/actions/config";
import {bindActionCreators} from "redux";
import {getAccessToken} from "@tools/tokens";
import CssBaseline from "@mui/material/CssBaseline";
import TagManager from 'react-gtm-module';
import LayoutContainer from "@components/layout/Layout";
import InternetConnectionContainer from "@components/layout/InternetConnection";

TagManager.initialize({
    gtmId: "GTM-5ZMVGRJ"
});

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
};
TagManager.initialize(tagManagerArgs);

const App = ({actions, config}) => {
    useEffect(() => {
        if (getAccessToken()) {
            actions.autoLogin();
        } else {
            actions.isSiteEnabled();
        }
    }, [actions]);

    return (
        <>
            <CssBaseline/>
            <LoaderContainer/>
            <InternetConnectionContainer/>

            {config.maintenance ?
                <MaintenanceContainer/>
                : (
                    <>
                        {config.appReady && <LayoutContainer/>}
                    </>
                )}
        </>
    );
}

const mapStateToProps = (state) => ({
    config: state.config,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            autoLogin,
            isSiteEnabled,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);