import React, {useState} from 'react';
import PayoutDataDialog from "@components/views/Payout/PayoutDataDialog/Main";
import {bindActionCreators} from "redux";
import {
    sendPayoutDataInit,
    sendPayoutData,
} from "@store/actions/payout";
import {connect} from "react-redux";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import {useMount} from "@s-ui/react-hooks";

const PayoutDataDialogContainer = (props) => {
    const [fields, setFields] = useState(BASIC_FORM_FIELDS_CONFIG.PAYOUT);

    const updateFormFields = () => {
        const userData = props.user.auth.userData;

        const updatedFields = fields.map(field => {
            if (field.name === "firstName" || field.name === "lastName") {
                return {
                    ...field,
                    value: userData[field.name],
                    isDisabled: true,
                }
            }
            return field;
        });

        setFields([
            ...updatedFields,
        ]);
    }

    const handleFormValid = (data) => {
        props.actions.sendPayoutData(data, props.payoutItem.participation.uuid);
    }

    useMount(() => {
        props.actions.sendPayoutDataInit();
        updateFormFields();
    });

    return (
        <>
            {fields && (
                <PayoutDataDialog
                    setPayoutItem={props.setPayoutItem}
                    fields={fields}
                    errors={props.payout.payoutDataSentErrors}
                    payoutItem={props.payoutItem}
                    user={props.user}
                    handleFormValid={handleFormValid}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    payout: state.payout,
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            sendPayoutDataInit,
            sendPayoutData
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayoutDataDialogContainer);