import React from 'react';
import logo from "@assets/logos/logo.png";
import LogoCircle from "@assets/logos/maintenance-logo-circle.svg";
import useStylesMaintenance from "./styles";

const Maintenance = () => {
    const {classes: maintenanceStyles} = useStylesMaintenance();

    return (
        <div className={maintenanceStyles.maintenance}>
            <img src={LogoCircle} className={maintenanceStyles.logoCircle} alt={"Logo Bonus"}/>
            <div className={maintenanceStyles.content}>
                <img className={maintenanceStyles.logo} src={logo} alt="Logo Bonus"/>
                <h1 className={maintenanceStyles.title}>Trwa aktualizacja systemu</h1>
                <p className={maintenanceStyles.description}>Zespół Bonus eBrokerPartner</p>
            </div>
        </div>
    );
}

export default Maintenance;