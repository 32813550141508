import {makeStyles} from "tss-react/mui";

const useStylesMain = makeStyles()(theme => ({
    main: {
        position: "relative",
        flex: 1,
    },
    content: {
        width: "100%",
        display: "table",
        margin: "15px 0",
        [theme.breakpoints.up("lg")]: {
            margin: "30px 0",
        },
    },
    contentTitle: {
        fontWeight: 600,
        padding: "0",
        fontSize: "20px",
        margin: 0,
        color: "#000",
    },
    contentBody: {
        width: "100%",
        display: "block",
        margin: "15px 0",
        [theme.breakpoints.up("lg")]: {
            margin: "30px 0",
        },
    },
    contentBodyFull: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        backgroundColor: "#fff",
        borderRadius: "7px",
        boxShadow: `0 1px 2px ${theme.palette.color5}`,
        minHeight: "300px",
        [theme.breakpoints.up("lg")]: {
            padding: "30px",
        },
    },

    logo: {
        height: "75px",
    },
    formDescription: {
        fontWeight: 600,
        margin: "30px 0 15px 0",
        textAlign: "center",
    },
    helpMessageContainer: {
    },
    helpMessageIcon: {
        marginLeft: "5px",
        color: theme.palette.color2,
        cursor: "pointer",
    },
    helpMessageText: {
        display: "block",
        color: "#fff",
        margin: "5px 0 0 0",
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: theme.palette.color2,
    },
    successMessage: {
        margin: "15px 0 0 0",
        textAlign: "center",
    },
    successMessageParagraph: {
        margin: "0",
    },
    registerFormLinks: {
        margin: "15px 0 0 0",
    },
    registerFormLinkParagraph: {
        textAlign: "center",
        margin: "0 0 10px 0",
        "& a": {
            textDecoration: "underline",
            color: theme.palette.color1,
        }
    },
    registerFormLinkToLoginParagraph: {
        fontSize: "16px",
        fontWeight: 700,
    },
    loginFormLinks: {
        margin: "30px 0 0 0",
    },
    loginFormLinkParagraph: {
        textAlign: "center",
        margin: "0 0 10px 0",
        "& a": {
            textDecoration: "underline",
            color: theme.palette.color1,
        }
    },
    loginFormLinkToRegisterParagraph: {
        fontSize: "16px",
        fontWeight: 700,
    },
    adminInfo: {
        width: "100%",
        textAlign: "justify",
        margin: "50px 0 0 0",
        "& p": {
            textAlign: "justify",
            margin: "0 0 10px 0",
        }
    },
    actionsSectionContainer: {
        margin: "0 0 30px 0",
    },
}));

export default useStylesMain;