import backgroundImage from "@assets/landingPage/topSection/backgroundImage.jpg";
import {makeStyles} from "tss-react/mui";

const useStylesTopSection = makeStyles()(theme => ({
    topSection: {
        display: "flex",
        alignItems: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "auto 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        height: "280px",
        position: "relative",
        [theme.breakpoints.up('sm')]: {
            height: "350px",
        },
        [theme.breakpoints.up('md')]: {
            height: "450px",
        },
        [theme.breakpoints.up('lg')]: {
            height: "610px",
            overflow: "hidden",
            "&:before": {
                zIndex: "-1",
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "linear-gradient(to right, #473A74 0%, #473A74 calc(50% - 1920px / 2), #ABA9A6 calc(50% + 1920px / 2), #ABA9A6 100%)",
            },
            "&:after": {
                zIndex: 0,
                content: "''",
                position: "absolute",
                top: 0,
                left: "calc(50% - 1920px / 2)",
                width: "1920px",
                height: "100%",
                background: "linear-gradient(to right, #473A74 0, rgba(0,0,0,0) 200px, rgba(0,0,0,0) calc(100% - 200px), #ABA9A6 100%)",
            },
        },
    },
    container: {
        zIndex: 1,
    },
    title: {
        fontSize: "26px",
        fontWeight: 900,
        color: "#fff",
        lineHeight: "1.2",
        [theme.breakpoints.up('sm')]: {
        },
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "64px",
        },
    },
    buttonContainer: {
        margin: "20px 0",
        [theme.breakpoints.up('sm')]: {
        },
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            margin: "60px 0",
        },
    },
    loginLinkContainer: {
    },
    loginLinkTitle: {
        color: "#fff",
        fontSize: "18px",
        fontWeight: 600,
        [theme.breakpoints.up('sm')]: {
        },
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "26px",
        },
    },
    loginLink: {
        fontSize: "18px",
        color: "#fff",
        textDecoration: "underline",
        "&:hover": {
            color: "#fff",
            textDecoration: "underline",
        },
        [theme.breakpoints.up('sm')]: {
        },
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "26px",
        },
    }
}))

export default useStylesTopSection;