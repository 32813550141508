import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {ErrorOutline} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useStylesBasicFormBuilder from "./styles";
import validation from "@tools/validation";
import API_ERRORS from "@consts/apiErrors";
import {useMount, usePrevious} from "@s-ui/react-hooks";

const BasicFormBuilder = (props) => {
    const {errors, btnText} = props;
    const [fields, setFields] = useState(props.fields);
    const {classes: basicFormBuilderStyles, cx} = useStylesBasicFormBuilder();

    const prevFields = usePrevious(props.fields);

    useMount(() => {
        setFields(props.fields);
    });

    useEffect(() => {
        if (prevFields !== props.fields) {
            setFields(props.fields);
        }
    }, [prevFields, props.fields]);

    const handleSubmit = e => {
        e.preventDefault();

        const isFormValid = fields.every((field) => validation(fields, field.name, field.value));

        if (isFormValid) {
            let data = {};
            let agreements = [];

            fields.forEach((field) => {
                if (field.toSend) {
                    if (field.agreementType) {
                        if (field.value) {
                            agreements.push(field.name);
                        }
                    } else {
                        data[field.name] = field.value;
                    }
                }
            });

            if (agreements.length) {
                data['agreements'] = agreements;
            }

            props.handleFormValid(data);
        } else {
            allFieldsValidation();
        }
    }

    const handleChange = async (name, value) => {
        const updatedFields = fields.map((field) => {
            if (field.name === name) {
                return {
                    ...field,
                    value: value,
                    isCorrect: validation(fields, name, value),
                    isTouched: true
                };
            }

            return field;
        });

        await setFields(updatedFields);

        if (name === 'password') {
            validatePasswordRepeatFieldByPasswordField(updatedFields);
        }
    }

    const validatePasswordRepeatFieldByPasswordField = (fields) => {
        const updatedFields = fields.map((field) => {
            if (field.name === 'passwordRepeat' && field.value !== '') {
                return {
                    ...field,
                    isCorrect: validation(fields, field.name, field.value),
                    isTouched: true
                };
            }

            return field;
        });

        setFields(updatedFields);
    }

    const allFieldsValidation = async () => {
        const updatedFields = fields.map((field) => {
            return {
                ...field,
                value: field.value,
                isCorrect: validation(fields, field.name, field.value),
                isTouched: true
            };
        });

        await setFields(updatedFields);

        const notValidField = document.querySelector('.not-valid-field-row');
        if (notValidField) {
            notValidField.scrollIntoView();
        }
    }

    const getField = (field) => {
        if (field.type === "checkbox") {
            return (
                <>
                    {field.agreementType && field.agreementType !== 'INFORMATION' &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={field.isDisabled}
                                    color="default"
                                    id={field.name}
                                    name={field.name}
                                    checked={field.value}
                                    onChange={event => handleChange(event.target.name, event.target.checked)}
                                    inputProps={{'aria-label': 'primary checkbox'}}
                                />
                            }
                            label={<p className={basicFormBuilderStyles.labelParagraph}
                                      dangerouslySetInnerHTML={{__html: field.label}}/>}
                            className={basicFormBuilderStyles.checkboxField}
                        />
                    }
                </>
            )
        } else if (field.type === "textarea") {
            return (
                <TextField
                    disabled={field.isDisabled}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    variant="outlined"
                    defaultValue={field.value}
                    className={basicFormBuilderStyles.textareaField}
                    onChange={event => handleChange(event.target.name, event.target.value)}
                    multiline
                    rows={field.rows}
                />
            )
        } else if (field.type === "select") {
            return (
                <FormControl variant="outlined" className={basicFormBuilderStyles.selectField}>
                    <InputLabel id={field.name}>{field.label}</InputLabel>
                    <Select
                        labelId={field.name}
                        id={field.name}
                        name={field.name}
                        value={field.value}
                        onChange={event => handleChange(event.target.name, event.target.value)}
                        label={field.label}
                    >
                        <MenuItem value="">
                            <em>Wybierz element listy</em>
                        </MenuItem>
                        {field.values.map((value, index) => (
                            <MenuItem value={value.value} key={index}>{value.text}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
        } else {
            return (
                <TextField
                    disabled={field.isDisabled}
                    type={field.type}
                    id={field.name}
                    name={field.name}
                    label={field.label}
                    variant="outlined"
                    value={field.value}
                    className={basicFormBuilderStyles.textField}
                    onChange={event => handleChange(event.target.name, event.target.value)}
                />
            )
        }
    }

    return (
        <form onSubmit={handleSubmit} noValidate className={basicFormBuilderStyles.form} autoComplete={"off"}>
            {fields.map((field, index) => (
                <React.Fragment key={index}>
                    {field.type !== "hidden" && (
                        <div
                            className={cx(basicFormBuilderStyles.fieldRow, !field.isCorrect && field.isTouched && 'not-valid-field-row')}>
                            {getField(field)}

                            {!field.isCorrect && field.isTouched &&
                                <div className={basicFormBuilderStyles.fieldError}>
                                    <ErrorOutline/><span dangerouslySetInnerHTML={{__html: field.errorMessage}}/>
                                </div>
                            }
                        </div>
                    )}
                </React.Fragment>
            ))}

            {errors && <div className={basicFormBuilderStyles.serverErrors}>
                {errors.map((error, index) => {
                    const found = API_ERRORS.find((apiError) => apiError.code === error.code);

                    return (
                        <span className={basicFormBuilderStyles.serverError} key={index}>
                            <ErrorOutline/> {found ? found.message : "Nieznany błąd"}
                        </span>
                    )
                })}
            </div>}

            <Button
                type="submit"
                variant="contained"
                className={basicFormBuilderStyles.submitBtn}
                disableElevation>{btnText}</Button>
        </form>
    );
}

export default BasicFormBuilder;