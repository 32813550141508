import PAGE_META_DETAILS from "@consts/metaTags";

const pageName = 'Bonus eBrokerPartner';

export const setMetaTitle = (title) => {
    document.title = `${title} - ${pageName}`;
}

export const setMetaDescription = (description) => {
    const metaDescriptionTag = document.querySelector("meta[name=description]");

    metaDescriptionTag.content = description ? description : "";
}

export const getMetaTitle = (name) => {
    return PAGE_META_DETAILS[name]?.title;
}

export const getMetaDescription = (name) => {
    return PAGE_META_DETAILS[name]?.description;
}