import {
    AUTO_LOGIN_USER_INIT,
    AUTO_LOGIN_USER_SUCCESS,
    AUTO_LOGIN_USER_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {setAppReady, setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {getAccessToken} from "@tools/tokens";
import {logout} from "@store/actions/user";
import LOADER_PLACES from "@consts/loaderPlaces";

const autoLoginInit = () => ({
    type: AUTO_LOGIN_USER_INIT,
});

const autoLoginSuccess = (data) => ({
    type: AUTO_LOGIN_USER_SUCCESS,
    payload: data
});

const autoLoginFailure = (errors) => ({
    type: AUTO_LOGIN_USER_FAILURE,
    payload: errors
});

const autoLogin = () => {
    return async (dispatch) => {
        await dispatch(setMultiLoader(LOADER_PLACES.AUTOLOGIN));
        await dispatch(autoLoginInit());

        const path = '/api/profile/me';

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client('GET', path, null, headers);
            const responseData = response.data.data;

            await dispatch(autoLoginSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(autoLoginFailure(error.response.data.errors));
            }

            await dispatch(logout( false));
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.AUTOLOGIN));
        await dispatch(setAppReady());
    }
}

export default autoLogin;