import {
    GET_REGISTER_AGREEMENTS_INIT,
    GET_REGISTER_AGREEMENTS_SUCCESS,
    GET_REGISTER_AGREEMENTS_FAILURE,

    REGISTER_INIT,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,

    ACTIVATE_ACCOUNT_INIT,
    ACTIVATE_ACCOUNT_SUCCESS,
    ACTIVATE_ACCOUNT_FAILURE,

    RECOVER_PASSWORD_INIT,
    RECOVER_PASSWORD_SUCCESS,
    RECOVER_PASSWORD_FAILURE,

    RESET_PASSWORD_INIT,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

    UPDATE_PROFILE_INIT,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,

    CHANGE_PASSWORD_INIT,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,

    DELETE_ACCOUNT_INIT,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAILURE,

    GET_RESET_PASSWORD_TOKEN_INIT,
    GET_RESET_PASSWORD_TOKEN_SUCCESS,
    GET_RESET_PASSWORD_TOKEN_FAILURE,
} from "@store/types/user";

const initialState = {
    registerAgreementsItems: null,
    registerAgreementsErrors: null,

    registerSuccess: null,
    registerErrors: null,

    activateAccountSuccess: null,
    activateAccountErrors: null,

    getRecoverPasswordTokenSuccess: null,
    getRecoverPasswordTokenErrors: null,

    recoverPasswordSuccess: null,
    recoverPasswordErrors: null,

    resetPasswordSuccess: null,
    resetPasswordErrors: null,

    updateProfileSuccess: null,
    updateProfileErrors: null,

    changePasswordSuccess: null,
    changePasswordErrors: null,

    deleteAccountSuccess: null,
    deleteAccountErrors: null,
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REGISTER_AGREEMENTS_INIT: {
            return {
                ...state,
                registerAgreementsItems: initialState.registerAgreementsItems,
                registerAgreementsErrors: initialState.registerAgreementsErrors,
            };
        }
        case GET_REGISTER_AGREEMENTS_SUCCESS: {
            return {
                ...state,
                registerAgreementsItems: action.payload,
                registerAgreementsErrors: initialState.registerAgreementsErrors,
            };
        }
        case GET_REGISTER_AGREEMENTS_FAILURE: {
            return {
                ...state,
                registerAgreementsItems: initialState.registerAgreementsItems,
                registerAgreementsErrors: action.payload,
            };
        }

        case REGISTER_INIT: {
            return {
                ...state,
                registerSuccess: initialState.registerSuccess,
                registerErrors: initialState.registerErrors,
            };
        }
        case REGISTER_SUCCESS: {
            return {
                ...state,
                registerSuccess: true,
                registerErrors: initialState.registerErrors,
            };
        }
        case REGISTER_FAILURE: {
            return {
                ...state,
                registerSuccess: initialState.registerSuccess,
                registerErrors: action.payload,
            };
        }

        case ACTIVATE_ACCOUNT_INIT: {
            return {
                ...state,
                activateAccountSuccess: initialState.activateAccountSuccess,
                activateAccountErrors: initialState.activateAccountErrors,
            };
        }
        case ACTIVATE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                activateAccountSuccess: true,
                activateAccountErrors: initialState.activateAccountErrors,
            };
        }
        case ACTIVATE_ACCOUNT_FAILURE: {
            return {
                ...state,
                activateAccountSuccess: initialState.activateAccountSuccess,
                activateAccountErrors: action.payload,
            };
        }

        case GET_RESET_PASSWORD_TOKEN_INIT: {
            return {
                ...state,
                getRecoverPasswordTokenSuccess: initialState.getRecoverPasswordTokenSuccess,
                getRecoverPasswordTokenErrors: initialState.getRecoverPasswordTokenErrors,
            };
        }
        case GET_RESET_PASSWORD_TOKEN_SUCCESS: {
            return {
                ...state,
                getRecoverPasswordTokenSuccess: true,
                getRecoverPasswordTokenErrors: initialState.getRecoverPasswordTokenErrors,
            };
        }
        case GET_RESET_PASSWORD_TOKEN_FAILURE: {
            return {
                ...state,
                getRecoverPasswordTokenSuccess: initialState.getRecoverPasswordTokenSuccess,
                getRecoverPasswordTokenErrors: action.payload,
            };
        }

        case RECOVER_PASSWORD_INIT: {
            return {
                ...state,
                recoverPasswordSuccess: initialState.recoverPasswordSuccess,
                recoverPasswordErrors: initialState.recoverPasswordErrors,
            };
        }
        case RECOVER_PASSWORD_SUCCESS: {
            return {
                ...state,
                recoverPasswordSuccess: true,
                recoverPasswordErrors: initialState.recoverPasswordErrors,
            };
        }
        case RECOVER_PASSWORD_FAILURE: {
            return {
                ...state,
                recoverPasswordSuccess: initialState.recoverPasswordSuccess,
                recoverPasswordErrors: action.payload,
            };
        }

        case RESET_PASSWORD_INIT: {
            return {
                ...state,
                resetPasswordSuccess: initialState.resetPasswordSuccess,
                resetPasswordErrors: initialState.resetPasswordErrors,
            };
        }
        case RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                resetPasswordSuccess: true,
                resetPasswordErrors: initialState.resetPasswordErrors,
            };
        }
        case RESET_PASSWORD_FAILURE: {
            return {
                ...state,
                resetPasswordSuccess: initialState.resetPasswordSuccess,
                resetPasswordErrors: action.payload,
            };
        }

        case UPDATE_PROFILE_INIT: {
            return {
                ...state,
                updateProfileSuccess: initialState.updateProfileSuccess,
                updateProfileErrors: initialState.updateProfileErrors,
            };
        }
        case UPDATE_PROFILE_SUCCESS: {
            return {
                ...state,
                updateProfileSuccess: true,
                updateProfileErrors: initialState.updateProfileErrors,
            };
        }
        case UPDATE_PROFILE_FAILURE: {
            return {
                ...state,
                updateProfileSuccess: initialState.updateProfileSuccess,
                updateProfileErrors: action.payload,
            };
        }

        case CHANGE_PASSWORD_INIT: {
            return {
                ...state,
                changePasswordSuccess: initialState.changePasswordSuccess,
                changePasswordErrors: initialState.changePasswordErrors,
            };
        }
        case CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                changePasswordSuccess: true,
                changePasswordErrors: initialState.changePasswordErrors,
            };
        }
        case CHANGE_PASSWORD_FAILURE: {
            return {
                ...state,
                changePasswordSuccess: initialState.changePasswordSuccess,
                changePasswordErrors: action.payload,
            };
        }

        case DELETE_ACCOUNT_INIT: {
            return {
                ...state,
                deleteAccountSuccess: initialState.deleteAccountSuccess,
                deleteAccountErrors: initialState.deleteAccountErrors,
            };
        }
        case DELETE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                deleteAccountSuccess: true,
                deleteAccountErrors: initialState.deleteAccountErrors,
            };
        }
        case DELETE_ACCOUNT_FAILURE: {
            return {
                ...state,
                deleteAccountSuccess: initialState.deleteAccountSuccess,
                deleteAccountErrors: action.payload,
            };
        }
        default:
            return state;
    }
}

export default profileReducer;


