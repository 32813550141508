const accessTokenName = 'accessToken';
const refreshTokenName = 'refreshToken';

export const getAccessToken = () => {
    return localStorage.getItem(accessTokenName);
}

export const getRefreshToken = () => {
    return localStorage.getItem(refreshTokenName);
}

export const setTokens = (accessTokenValue, refreshTokenValue) => {
    localStorage.setItem(accessTokenName, accessTokenValue);
    localStorage.setItem(refreshTokenName, refreshTokenValue);
}

export const removeTokens = () => {
    localStorage.removeItem(accessTokenName);
    localStorage.removeItem(refreshTokenName);
}