import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getFaqItems, getFaqItemsInit} from "@store/actions/faq";
import {bindActionCreators} from "redux";
import Faq from "@components/views/Faq/Main";
import {useMount} from "@s-ui/react-hooks";

const FaqContainer = (props) => {
    useMount(() => {
        props.actions.getFaqItems();
    });

    useEffect(() => {
        return () => {
            props.actions.getFaqItemsInit();
        };
    }, [props.actions]);

    return (
        <Faq
            faqItems={props.faq.faqItems}
            errors={props.faq.errors}
        />
    )
}

const mapStateToProps = (state) => ({
    faq: state.faq
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getFaqItemsInit,
            getFaqItems,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FaqContainer);