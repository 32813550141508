import React from 'react';
import StepsSection from "@components/views/StartPage/LandingPage/StepsSection/Main";
import firstStep from "@assets/landingPage/stepsSection/numbersImages/1.png";
import secondStep from "@assets/landingPage/stepsSection/numbersImages/2.png";
import thirdStep from "@assets/landingPage/stepsSection/numbersImages/3.png";
import fourthStep from "@assets/landingPage/stepsSection/numbersImages/4.png";
import fifthStep from "@assets/landingPage/stepsSection/numbersImages/5.png";
import sixthStep from "@assets/landingPage/stepsSection/numbersImages/6.png";

const StepsSectionContainer = (props) => {
    const stepsItems = [
        {
            image: firstStep,
            text: "Zarejestruj się w Bonus eBrokerPartner lub zaloguj się do swojego profilu uczestnika",
        },
        {
            image: secondStep,
            text: "Sprawdź Aktywne Akcje Bonus",
        },
        {
            image: thirdStep,
            text: "Zapoznaj się z regulaminem akcji",
        },
        {
            image: fourthStep,
            text: "Wypełnij wniosek o produkt objęty Akcją Bonus",
        },
        {
            image: fifthStep,
            text: "Spełnij wszystkie warunki opisane w regulaminie",
        },
        {
            image: sixthStep,
            text: "Odbierz Bonus",
        },
    ]


    return (
        <StepsSection stepsItems={stepsItems}/>
    );
}

export default StepsSectionContainer;