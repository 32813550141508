const VALIDATORS = {
    SELECT: 'SELECT',
    CHECKBOX: 'CHECKBOX',
    FIRSTNAME: 'FIRSTNAME',
    LASTNAME: 'LASTNAME',
    CONTRACT_NUMBER: 'CONTRACT_NUMBER',
    REASON: 'REASON',
    EMAIL: 'EMAIL',
    CURRENT_PASSWORD: 'CURRENT_PASSWORD',
    PASSWORD: 'PASSWORD',
    PASSWORD_REPEAT: 'PASSWORD_REPEAT',
    PHONE: 'PHONE',
    CONTACT_MESSAGE: 'CONTACT_MESSAGE',
    REFERRAL_CODE: 'REFERRAL_CODE',
    ACCOUNT_NUMBER: 'ACCOUNT_NUMBER',
    ACTION_ACCESS_TOKEN: 'ACTION_ACCESS_TOKEN',
    HIDDEN_INPUT: 'HIDDEN_INPUT',
};

export default VALIDATORS;
