import React from 'react';
import LogosSectionContainer from "@components/views/StartPage/LandingPage/LogosSection";
import StepsSectionContainer from "@components/views/StartPage/LandingPage/StepsSection";
import TopSectionContainer from "@components/views/StartPage/LandingPage/TopSection";
import ActionsSectionContainer from "@components/views/shared/ActionsSection";
import {KeyboardArrowUp} from "@mui/icons-material";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import ROUTES from "@consts/routes";
import useStylesLandingPage from "./styles";

const LandingPage = (props) => {
    const {classes: landingPageStyles, cx} = useStylesLandingPage();

    return (
        <div className={landingPageStyles.landingPage}>
            <TopSectionContainer/>

            <div className={cx(
                landingPageStyles.actionsSectionContainer,
                props.actionsList?.actionsItems?.ongoing?.length > 0 && landingPageStyles.actionsSectionContainerActive
            )}>
                <ActionsSectionContainer/>
                <div className={landingPageStyles.buttonContainer}>
                    <Link to={ROUTES.REGISTER}>
                        <Button className={landingPageStyles.button}>Zarejestruj się</Button>
                    </Link>
                </div>
            </div>

            <StepsSectionContainer/>
            <LogosSectionContainer/>

            <Button
                className={cx(
                    landingPageStyles.scrollTopButton,
                    props.showScrollTopButton && landingPageStyles.scrollTopButtonVisible
                )}
                onClick={() => props.scrollTop()}>
                <KeyboardArrowUp/>
            </Button>
        </div>
    );
}

export default LandingPage;