import {
    CHANGE_PASSWORD_INIT,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {getAccessToken} from "@tools/tokens";
import LOADER_PLACES from "@consts/loaderPlaces";

const changePasswordInit = () => ({
    type: CHANGE_PASSWORD_INIT,
});

const changePasswordSuccess = (data) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: data
});

const changePasswordFailure = (errors) => ({
    type: CHANGE_PASSWORD_FAILURE,
    payload: errors
});

const changePassword = (data) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.PASSWORD_CHANGE));
        await dispatch(changePasswordInit());

        const path = '/api/profile/change-password';

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client('PATCH', path, data, headers);
            const responseData = response.data.data;

            await dispatch(changePasswordSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(changePasswordFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.PASSWORD_CHANGE));
    }
}

export {
    changePasswordInit,
    changePassword,
};