import {
    REGISTER_INIT,
    REGISTER_SUCCESS,
    REGISTER_FAILURE
} from "@store/types/user";

import {client} from "@api/client";
import ROUTES from "@consts/routes";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const registerInit = () => ({
    type: REGISTER_INIT
});

const registerSuccess = () => ({
    type: REGISTER_SUCCESS
});

const registerFailure = (errors) => ({
    type: REGISTER_FAILURE,
    payload: errors
});

const register = (data) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.REGISTER));
        await dispatch(registerInit());

        const path = '/api/auth/register';

        const headers = {};

        data = {
            ...data,
            activationUrlPattern: `${window.location.origin}${ROUTES.ACTIVATE_ACCOUNT}`,
        };

        try {
            await client('POST', path, data, headers);

            await dispatch(registerSuccess());
        } catch (error) {
            if (error.response) {
                await dispatch(registerFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.REGISTER));
    }
}

export {
    registerInit,
    register
};