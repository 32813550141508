import {makeStyles} from "tss-react/mui";

const useStylesLandingPage = makeStyles()(theme => ({
    landingPage: {},
    actionsSectionContainer: {
        padding: "60px 0",
        display: "none",
        width: "100%",
    },
    actionsSectionContainerActive: {
        display: "table",
    },
    buttonContainer: {
        margin: "30px auto 0 auto",
        display: "table",
    },
    button: {
        backgroundColor: theme.palette.color1,
        color: "#fff",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: 600,
        padding: "10px 30px",
        minWidth: "220px",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        },
        [theme.breakpoints.up('sm')]: {},
        [theme.breakpoints.up('md')]: {},
        [theme.breakpoints.up('lg')]: {
            padding: "15px 30px",
            minWidth: "270px",
            fontSize: "18px",
        },
    },
    scrollTopButton: {
        minWidth: "auto",
        width: "50px",
        height: "50px",
        backgroundColor: theme.palette.color1,
        borderRadius: "50%",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        cursor: "pointer",
        position: "fixed",
        zIndex: 1,
        boxShadow: "0 2px 4px #fff",
        transform: "scale(0)",
        transition: "0.3s",
        bottom: "10px",
        right: "10px",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        },
        [theme.breakpoints.up('lg')]: {
            bottom: "50px",
            right: "50px",
        },
    },
    scrollTopButtonVisible: {
        transform: "scale(1)",
    }
}))

export default useStylesLandingPage;