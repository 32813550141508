import {
    GET_ACTIONS_LIST_INIT,
    GET_ACTIONS_LIST_SUCCESS,
    GET_ACTIONS_LIST_FAILURE,
} from "@store/types/actionsList";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {getAccessToken} from "@tools/tokens";
import LOADER_PLACES from "@consts/loaderPlaces";

const getActionsListInit = () => ({
    type: GET_ACTIONS_LIST_INIT
});
const getActionsListSuccess = (data) => ({
    type: GET_ACTIONS_LIST_SUCCESS,
    payload: data
});

const getActionsListFailure = (errors) => ({
    type: GET_ACTIONS_LIST_FAILURE,
    payload: errors
});

const getActionsList = () => {
    return async dispatch => {
        const accessToken = getAccessToken();

        await dispatch(setMultiLoader(accessToken ? LOADER_PLACES.ACTIONS_LIST_PAGE : LOADER_PLACES.ACTIONS_LIST_SECTION));

        await dispatch(getActionsListInit());

        const path = '/api/action';

        let headers = {};

        if (accessToken) {
            headers = {
                ...headers,
                Authorization: `Bearer ${accessToken}`
            };
        }

        try {
            const response = await client('GET', path, null, headers);
            const responseData = response.data.data;
            await dispatch(getActionsListSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(getActionsListFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(accessToken ? LOADER_PLACES.ACTIONS_LIST_PAGE : LOADER_PLACES.ACTIONS_LIST_SECTION));
    }
}

export {
    getActionsListInit,
    getActionsList
};