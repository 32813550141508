import {
    GET_STATISTICS_INIT,
    GET_STATISTICS_SUCCESS,
    GET_STATISTICS_FAILURE
} from "@store/types/statistics";

const initialState = {
    statisticsItems: null,
    errors: null,
}

const statisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STATISTICS_INIT: {
            return initialState;
        }
        case GET_STATISTICS_SUCCESS: {
            return {
                ...state,
                statisticsItems: action.payload,
                errors: initialState.errors,
            };
        }
        case GET_STATISTICS_FAILURE: {
            return {
                ...state,
                statisticsItems: initialState.statisticsItems,
                errors: action.payload
            };
        }
        default:
            return state;
    }
}

export default statisticsReducer;


