import React from 'react';
import {Container} from "@mui/material";
import useStylesFooter from "./styles";

const Footer = () => {
    const year = new Date().getFullYear();
    const {classes: footerStyles} = useStylesFooter();

    return (
        <footer className={footerStyles.footer}>
            <Container>
                &copy; {year} Bonus eBrokerPartner
            </Container>
        </footer>
    );
}

export default Footer;