import {
    GET_ACTION_PAGE_INIT,
    GET_ACTION_PAGE_SUCCESS,
    GET_ACTION_PAGE_FAILURE,
    SEND_PARTICIPATION_DATA_INIT,
    SEND_PARTICIPATION_DATA_SUCCESS,
    SEND_PARTICIPATION_DATA_FAILURE,
    SET_PARTICIPATION_DIALOG_OPEN, GET_HIDDEN_ACTION_PAGE_FAILURE
} from "@store/types/actionPage";

const initialState = {
    actionPageDetails: null,
    actionPageDetailsErrors: null,
    hiddenActionPageDetailsErrors: null,
    participationData: null,
    participationDataErrors: null,
    isParticipationDialogOpen: false,
}

const actionPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTION_PAGE_INIT: {
            return initialState;
        }
        case GET_ACTION_PAGE_SUCCESS: {
            return {
                ...state,
                actionPageDetails: action.payload,
                actionPageDetailsErrors: initialState.actionPageDetailsErrors,
            };
        }
        case GET_ACTION_PAGE_FAILURE: {
            return {
                ...state,
                actionPageDetails: initialState.actionPageDetails,
                actionPageDetailsErrors: action.payload
            };
        }
        case GET_HIDDEN_ACTION_PAGE_FAILURE: {
            return {
                ...state,
                hiddenActionPageDetailsErrors: action.payload
            };
        }

        case SEND_PARTICIPATION_DATA_INIT: {
            return {
                ...state,
                participationData: initialState.participationData,
                participationDataErrors: initialState.participationDataErrors,
            };
        }
        case SEND_PARTICIPATION_DATA_SUCCESS: {
            return {
                ...state,
                participationData: action.payload,
                participationDataErrors: initialState.participationDataErrors,
            };
        }
        case SEND_PARTICIPATION_DATA_FAILURE: {
            return {
                ...state,
                participationData: initialState.participationData,
                participationDataErrors: action.payload,
            };
        }

        case SET_PARTICIPATION_DIALOG_OPEN: {
            return {
                ...state,
                isParticipationDialogOpen: action.payload,
            };
        }

        default:
            return state;
    }
}

export default actionPageReducer;


