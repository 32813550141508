const LOADER_PLACES = {
    IS_SITE_ENABLED: 'IS_SITE_ENABLED',
    CONTACT_ADMIN_INFO: 'CONTACT_ADMIN_INFO',
    CONTACT_SEND_MESSAGE: 'CONTACT_SEND_MESSAGE',
    REGISTER: 'REGISTER',
    REGISTER_AGREEMENTS: 'REGISTER_AGREEMENTS',
    ACTION_PAGE: 'ACTION_PAGE',
    ACTIONS_LIST_PAGE: 'ACTIONS_LIST_PAGE',
    ACTIONS_LIST_SECTION: 'ACTIONS_LIST_SECTION',
    FAQ_ITEMS: 'FAQ_ITEMS',
    PROFILE_ACTIVATE: 'PROFILE_ACTIVATE',
    PROFILE_UPDATE: 'PROFILE_UPDATE',
    PROFILE_DELETE: 'PROFILE_DELETE',
    PASSWORD_RESET_CHECK_TOKEN: 'PASSWORD_RESET_CHECK_TOKEN',
    PASSWORD_RESET: 'PASSWORD_RESET',
    PASSWORD_RECOVER: 'PASSWORD_RECOVER',
    PASSWORD_CHANGE: 'PASSWORD_CHANGE',
    LOGIN: 'LOGIN',
    AUTOLOGIN: 'AUTOLOGIN',
    LOGOUT: 'LOGOUT',
    STATISTICS: 'STATISTICS',
    STATIC_PAGE: 'STATIC_PAGE',
    PAYOUT_ITEMS: 'PAYOUT_ITEMS',
    PAYOUT_SEND_DATA: 'PAYOUT_SEND_DATA',
    COMPLAINTS_ITEMS: 'COMPLAINTS_ITEMS',
    COMPLAINTS_SEND_DATA: 'COMPLAINTS_SEND_DATA',
    PARTICIPATION_SEND_DATA: 'PARTICIPATION_SEND_DATA',
}

export default LOADER_PLACES;
