import {
    GET_STATIC_PAGE_INIT,
    GET_STATIC_PAGE_SUCCESS,
    GET_STATIC_PAGE_FAILURE
} from "@store/types/staticPage";

const initialState = {
    staticPageData: null,
    errors: null,
}

const staticPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STATIC_PAGE_INIT: {
            return initialState;
        }
        case GET_STATIC_PAGE_SUCCESS: {
            return {
                staticPageData: action.payload,
                errors: initialState.errors,
            };
        }
        case GET_STATIC_PAGE_FAILURE: {
            return {
                staticPageData: initialState.staticPageData,
                errors: action.payload
            };
        }
        default:
            return state;
    }
}

export default staticPageReducer;


