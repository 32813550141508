import reactCookies from "react-cookies";
import ROUTES from "@consts/routes";

const redirectAfterLogin = {
    set() {
        const maxAge = null;
        const cookieName = 'pathAfterLogin';
        const cookieValue = window.location.pathname + window.location.search;
        reactCookies.save(cookieName, cookieValue, {path: '/', maxAge});
    },
    get() {
        return reactCookies.load('pathAfterLogin') || ROUTES.HOME;
    },
    remove() {
        reactCookies.remove('pathAfterLogin', {path: '/'});
    }
}

export default redirectAfterLogin;