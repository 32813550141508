import {makeStyles} from "tss-react/mui";

const useStylesLogosSection = makeStyles()(theme => ({
    logosSection: {
        padding: "60px 0",
        backgroundColor: "#fff",
    },
    title: {
        fontSize: "20px",
        textAlign: "center",
        fontWeight: 600,
        [theme.breakpoints.up('lg')]: {
            fontSize: "24px",
        },
    },
    logos: {
        margin: "30px 0 0 0",
        overflow: "hidden",
        position: "relative",
        "&:before": {
            content: "''",
            zIndex: 1,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "linear-gradient(to right, #fff 0, rgba(0,0,0,0) 50px, rgba(0,0,0,0) calc(100% - 50px), #fff 100%)",
            [theme.breakpoints.up('lg')]: {
                background: "linear-gradient(to right, #fff 0, rgba(0,0,0,0) 100px, rgba(0,0,0,0) calc(100% - 100px), #fff 100%)",
            },
        }
    },
    logosList: {
        height: "50px",
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
    },
    logoContainer: {
        padding: "0 45px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
    },
    logo: {
        maxHeight: "100%",
    },
}))

export default useStylesLogosSection;