export const LOGIN_USER_INIT = 'LOGIN_USER_INIT';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const AUTO_LOGIN_USER_INIT = 'AUTO_LOGIN_USER_INIT';
export const AUTO_LOGIN_USER_SUCCESS = 'AUTO_LOGIN_USER_SUCCESS';
export const AUTO_LOGIN_USER_FAILURE = 'AUTO_LOGIN_USER_FAILURE';

export const REFRESH_TOKEN_INIT = 'REFRESH_TOKEN_INIT';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const RECOVER_PASSWORD_INIT = 'RECOVER_PASSWORD_INIT';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';

export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const GET_RESET_PASSWORD_TOKEN_INIT = 'GET_RESET_PASSWORD_TOKEN_INIT';
export const GET_RESET_PASSWORD_TOKEN_SUCCESS = 'GET_RESET_PASSWORD_TOKEN_SUCCESS';
export const GET_RESET_PASSWORD_TOKEN_FAILURE = 'GET_RESET_PASSWORD_TOKEN_FAILURE';

export const UPDATE_PROFILE_INIT = 'UPDATE_PROFILE_INIT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const DELETE_ACCOUNT_INIT = 'DELETE_ACCOUNT_INIT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const GET_REGISTER_AGREEMENTS_INIT = 'GET_REGISTER_AGREEMENTS_INIT';
export const GET_REGISTER_AGREEMENTS_SUCCESS = 'GET_REGISTER_AGREEMENTS_SUCCESS';
export const GET_REGISTER_AGREEMENTS_FAILURE = 'GET_REGISTER_AGREEMENTS_FAILURE';

export const REGISTER_INIT = 'REGISTER_INIT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const ACTIVATE_ACCOUNT_INIT = 'ACTIVATE_ACCOUNT_INIT';
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS';
export const ACTIVATE_ACCOUNT_FAILURE = 'ACTIVATE_ACCOUNT_FAILURE';

export const LOGOUT = 'LOGOUT';

export const RESET_REDIRECT_USER_AFTER_LOGOUT_TO = 'RESET_REDIRECT_USER_AFTER_LOGOUT_TO';