import {
    GET_ACTION_PAGE_INIT,
    GET_ACTION_PAGE_SUCCESS,
    GET_ACTION_PAGE_FAILURE,
    GET_HIDDEN_ACTION_PAGE_FAILURE
} from "@store/types/actionPage";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";
import actionAccessTokenTool from "@tools/actionAccessToken";
import {getAccessToken} from "@tools/tokens";
const getActionPageInit = () => ({
    type: GET_ACTION_PAGE_INIT
});
const getActionPageSuccess = (data) => ({
    type: GET_ACTION_PAGE_SUCCESS,
    payload: data
});

const getActionPageFailure = (errors) => ({
    type: GET_ACTION_PAGE_FAILURE,
    payload: errors
});

const getHiddenActionPageFailure = (errors) => ({
    type: GET_HIDDEN_ACTION_PAGE_FAILURE,
    payload: errors
});

const getActionPage = (slug, dataActionAccessToken) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.ACTION_PAGE));

        const actionAccessToken = dataActionAccessToken || actionAccessTokenTool.get(slug);

        const path = actionAccessToken ? `/api/action/${slug}?accessToken=${actionAccessToken}` : `/api/action/${slug}`;

        let headers = {};

        const accessToken = getAccessToken();

        if (accessToken) {
            headers = {
                ...headers,
                Authorization: `Bearer ${accessToken}`
            };
        }

        try {
            const response = await client('GET', path, null, headers);
            const responseData = response.data.data;

            if(dataActionAccessToken) {
                actionAccessTokenTool.set(slug, dataActionAccessToken);
            }

            await dispatch(getActionPageSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(getActionPageFailure(error.response.data.errors));

                if(actionAccessToken) {
                    actionAccessTokenTool.remove(slug);
                    await dispatch(getHiddenActionPageFailure(error.response.data.errors));
                }
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.ACTION_PAGE));
    }
}

export {
    getActionPageInit,
    getActionPage
};