import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ActionThumb from "@components/views/Register/ActionThumb/Main";
import queryString from "query-string";

const ActionThumbContainer = (props) => {
    const {actionsItems} = props.actionsList;
    const parsedSearch = queryString.parse(window.location.search);
    const searchActionSlug = parsedSearch['akcja'];

    const foundActionDetails = actionsItems?.ongoing?.find(action => action.slug === searchActionSlug);

    return (
        <>{foundActionDetails && <ActionThumb actionDetails={foundActionDetails}/>}</>
    );
}

const mapStateToProps = (state) => ({
    actionsList: state.actionsList
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {},
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionThumbContainer);