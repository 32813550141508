import {
    GET_CONTACT_ADMIN_INFO_INIT,
    GET_CONTACT_ADMIN_INFO_SUCCESS,
    GET_CONTACT_ADMIN_INFO_FAILURE,

    SEND_CONTACT_MESSAGE_INIT,
    SEND_CONTACT_SUCCESS,
    SEND_CONTACT_FAILURE
} from "@store/types/contact";

const initialState = {
    adminInfo: null,
    adminInfoErrors: null,
    messageSent: false,
    messageSentErrors: null,
}

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTACT_ADMIN_INFO_INIT: {
            return initialState;
        }
        case SEND_CONTACT_MESSAGE_INIT: {
            return {
                ...state,
                messageSent: initialState.messageSent,
                messageSentErrors: initialState.messageSentErrors,
            };
        }
        case GET_CONTACT_ADMIN_INFO_SUCCESS: {
            return {
                ...state,
                adminInfo: action.payload,
                adminInfoErrors: initialState.adminInfoErrors,
            };
        }
        case GET_CONTACT_ADMIN_INFO_FAILURE: {
            return {
                ...state,
                adminInfo: initialState.adminInfo,
                adminInfoErrors: action.payload
            };
        }
        case SEND_CONTACT_SUCCESS: {
            return {
                ...state,
                messageSent: true,
                messageSentErrors: initialState.messageSentErrors,
            };
        }
        case SEND_CONTACT_FAILURE: {
            return {
                ...state,
                messageSent: initialState.messageSent,
                messageSentErrors: action.payload
            };
        }
        default:
            return state;
    }
}

export default contactReducer;


