const MAINTENANCE = 'MAINTENANCE';
const SET_APP_READY = 'SET_APP_READY';
const SET_MULTI_LOADER = 'SET_MULTI_LOADER';
const RESET_MULTI_LOADER = 'RESET_MULTI_LOADER';

export {
    MAINTENANCE,
    SET_APP_READY,
    SET_MULTI_LOADER,
    RESET_MULTI_LOADER,
}