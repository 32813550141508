import React, {useState} from 'react';
import ROUTES from "@consts/routes";
import {Link} from "react-router-dom";
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import {MailOutline, Message} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {Container} from "@mui/material";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import SkeletonContentContainer from "@components/shared/SkeletonContent";
import SKELETONS from "@consts/skeletons";
import useStylesMain from "@components/layout/Main/styles";
import useStylesContact from "./styles";

const Contact = (props) => {
    const {classes: contactStyles, cx} = useStylesContact();
    const {classes: mainStyles} = useStylesMain();

    const [contactType, setContactType] = useState('');

    const {fields, adminInfo, messageSent, messageSentErrors, handleFormValid} = props;


    const getContent = () => {
        if (props.adminInfo) {
            return (
                <div className={contactStyles.contact}>
                    <div className={contactStyles.topText}>
                        <h2 className={contactStyles.topTextTitle}>
                            Masz pytanie?
                        </h2>
                        <p>
                            Zachęcamy do sprawdzenia <Link to={ROUTES.FAQ}>zakładki FAQ</Link> w menu
                            głównym oraz "Pytania&nbsp;i&nbsp;odpowiedzi" w konkretnej Akcji Bonus.
                        </p>
                    </div>

                    <div className={contactStyles.bottomText}>
                        <h3 className={contactStyles.bottomTextTitle}>
                            Wybierz jak się z nami chcesz skontaktować
                        </h3>

                        <div className={contactStyles.tabButtons}>
                            <Button
                                variant="contained"
                                className={cx(contactStyles.tabButton, contactType === 'contactForm' && contactStyles.tabButtonActive)}
                                onClick={() => setContactType('contactForm')}
                                disableElevation><Message className={contactStyles.icon}/> Formularz kontaktowy</Button>
                            <Button
                                variant="contained"
                                className={cx(contactStyles.tabButton, contactType === 'contactEmail' && contactStyles.tabButtonActive)}
                                onClick={() => setContactType('contactEmail')}
                                disableElevation><MailOutline className={contactStyles.icon}/> Wysyłając bezpośredni <span>e-mail</span></Button>
                        </div>

                        <div className={contactStyles.tabContent}>
                            <div className={contactStyles.contactType}>
                                {contactType === 'contactForm' && (
                                    <>
                                        {messageSent ? (
                                            <div className={contactStyles.messageContainer}>
                                                <DisplayMessageContainer
                                                    type={DISPLAY_MESSAGES.CONTACT_MESSAGE_SENT}/>
                                            </div>
                                        ) : (
                                            <BasicFormBuilderContainer
                                                fields={fields}
                                                errors={messageSentErrors}
                                                handleFormValid={handleFormValid}
                                                btnText="Wyślij wiadomość"
                                            />
                                        )}
                                        <>
                                            {adminInfo && adminInfo.map((info, index) => {
                                                return (
                                                    <div key={index}
                                                         className={contactStyles.adminInfo}
                                                         dangerouslySetInnerHTML={{__html: info.content}}
                                                    />
                                                )
                                            })}
                                        </>
                                    </>
                                )}

                                {contactType === 'contactEmail' &&
                                <>
                                    <div className={contactStyles.emailContainer}>
                                        <span className={contactStyles.email}>bonus@ebrokerpartner.pl</span>
                                        <p className={contactStyles.emailDescription}>
                                            Wyślij do nas wiadomość, szczegółowo opisując jak możemy Ci pomóc.
                                        </p>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (props.adminInfoErrors) {
            return <DisplayMessageContainer type={DISPLAY_MESSAGES.SOMETHING_WENT_WRONG}/>;
        } else if (!props.adminInfo && !props.adminInfoErrors) {
            return <SkeletonContentContainer type={SKELETONS.CONTACT}/>;
        }
    }


    return (
        <Container>
            <div className={mainStyles.content}>
                <h1 className={mainStyles.contentTitle}>Kontakt</h1>
                <div className={mainStyles.contentBody}>
                    <div className={mainStyles.contentBodyFull}>
                        {getContent()}
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Contact;