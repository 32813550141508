import {makeStyles} from "tss-react/mui";

const useStylesActionsSection = makeStyles()(theme => ({
    actionsSection: {},
    actionsTitle: {
        fontSize: "20px",
        textAlign: "center",
        fontWeight: 600,
        [theme.breakpoints.up('lg')]: {
            fontSize: "24px",
        },
    },
    actionsList: {
        margin: "30px 0 0 0",
    },
    action: {
        fontSize: "14px",
        margin: "0 auto",
        maxWidth: "395px",
        borderRadius: "7px",
        overflow: "hidden",
        boxShadow: `0 1px 2px ${theme.palette.color5}`,
        backgroundColor: "#fff",
    },
    actionContent: {
        width: "100%",
        padding: "0 15px",
    },
    name: {
        fontSize: "16px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 600,
        lineHeight: "1.5em",
        minHeight: "4em",
        color: "#000",
    },
    topContainer: {
        position: "relative",
        backgroundColor: theme.palette.color3Darker,
    },
    imageContainer: {
        position: "relative",
    },
    imageMask: {
        width: "100%",
    },
    image: {
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    getBonusText: {
        position: "absolute",
        bottom: "0",
        right: "0",
        width: "100%",
        padding: "14px 7px 7px 7px",
        background: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, #000 100%)`,
        textShadow: "0 0 5px #000",
        color: "#fff",
        fontWeight: 600,
        textAlign: "center",
        fontSize: "14px",
    },
}))

export default useStylesActionsSection;