const PAGES = {
    HOME: 'HOME',
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    REGISTER_COMPLETED: 'REGISTER_COMPLETED',
    ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
    RESET_PASSWORD: 'RESET_PASSWORD',
    RECOVER_PASSWORD: 'RECOVER_PASSWORD',
    STATISTICS: 'STATISTICS',
    COMPLAINTS: 'COMPLAINTS',
    USER_PROFILE: 'USER_PROFILE',
    PAYOUT: 'PAYOUT',
    ACTION_PAGE: 'ACTION_PAGE',
    ACTION_PAGE_PARTICIPATION: 'ACTION_PAGE_PARTICIPATION',
    CONTACT: 'CONTACT',
    FAQ: 'FAQ',
    REGULATION: 'REGULATION',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    STATIC_PAGE: 'STATIC_PAGE',
    NOT_FOUND: 'NOT_FOUND',
};

export default PAGES;
