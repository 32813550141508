import React from 'react';
import LogosSection from "@components/views/StartPage/LandingPage/LogosSection/Main";
import SantanderBankPolskaLogo from "@assets/landingPage/logosSection/institutionsLogos/SantanderBank Polska.png";
import mBankLogo from "@assets/landingPage/logosSection/institutionsLogos/mBank.png";
import MillenniumLogo from "@assets/landingPage/logosSection/institutionsLogos/Millennium.png";
import NestBankLogo from "@assets/landingPage/logosSection/institutionsLogos/NestBank.png";
import AliorBankLogo from "@assets/landingPage/logosSection/institutionsLogos/AliorBank.png";

const LogosSectionContainer = () => {
    const logosItems = [
        {
            name: 'Santander Bank Polska',
            image: SantanderBankPolskaLogo,
        },
        {
            name: 'mBank',
            image: mBankLogo,
        },
        {
            name: 'Millennium',
            image: MillenniumLogo,
        },
        {
            name: 'Nest Bank',
            image: NestBankLogo,
        },
        {
            name: 'Alior Bank',
            image: AliorBankLogo,
        },
    ];

    let loadedLogosItems = [];
    const handleLogoLoaded = (name) => {
        loadedLogosItems = [...loadedLogosItems, name];

        if (logosItems.length === loadedLogosItems.length) {
            buildCarousel();
        }
    }

    const buildCarousel = () => {
        const logosList = document.getElementById('logos-list');
        const logosListWidth = logosList.offsetWidth;

        logosList.innerHTML += logosList.innerHTML;

        const moveDistance = logosListWidth;
        const duration = 3000 / 100; // X ms for X px
        const time = duration * moveDistance;

        logosList.animate([
            {transform: `translateX(-${moveDistance}px)`}
        ], {
            duration: time,
            iterations: Infinity
        });
    }

    return (
        <LogosSection logosItems={logosItems} handleLogoLoaded={handleLogoLoaded}/>
    );
}

export default LogosSectionContainer;