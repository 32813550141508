import React, {useEffect} from 'react';
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import {Container, Typography} from "@mui/material";
import ParticipationDialogContainer
    from "@components/views/ActionPage/Participation/ParticipationDialog";
import LazyLoad from "@components/shared/DynamicComponent/lazy";
import useStylesMain from "@components/layout/Main/styles";
import useStylesActionPageSection from "@components/views/ActionPage/styles";
import {useNavigate, useParams} from "react-router";
import {useMount} from "@s-ui/react-hooks";
import {setMetaDescription, setMetaTitle} from "@tools/metaTags";
import PAGE_META_DETAILS from "@consts/metaTags";
import queryString from "query-string";
import partnerLeadId from "@tools/partnerLeadId";
import ACTION_STATUSES from "@consts/actionStatuses";
import ActionAccessTokenContainer from "@components/views/ActionPage/ActionAccessToken";

const ActionPage = (props) => {
    const {classes: mainStyles} = useStylesMain();
    const {classes: actionPageStyles} = useStylesActionPageSection();

    const timeoutRef = React.useRef();

    const navigate = useNavigate();
    const params = useParams();
    const {slug, takePart} = params;

    const {
        user,
        actionPage
    } = props;

    const {
        actionPageDetails,
        actionPageDetailsErrors,
        participationData,
        isParticipationDialogOpen
    } = actionPage;

    const {title, description} = actionPageDetails?.seoMeta || {};

    const parsedSearch = queryString.parse(window.location.search);
    const partnerLeadIdParamValue = parsedSearch['partnerLeadId'];

    const setMetaTags = () => {
        const metaTitle = actionPageDetails ? title : PAGE_META_DETAILS.ACTION_PAGE.title;
        const metaDescription = actionPageDetails ? description : PAGE_META_DETAILS.ACTION_PAGE.description;

        setMetaTitle(metaTitle);
        setMetaDescription(metaDescription);
    }

    setMetaTags();

    const setPartnerLeadId = () => {
        if (actionPageDetails && partnerLeadIdParamValue) {
            partnerLeadId.set(actionPageDetails.uuid, partnerLeadIdParamValue);
        }
    }

    setPartnerLeadId();

    useMount(() => {
        props.actions.getActionPage(slug, null);
    });

    useEffect(() => {
        if (!isParticipationDialogOpen && takePart) {
            navigate(`/akcja/${slug}/`);
        }
    }, [isParticipationDialogOpen, slug, takePart, navigate]);

    useEffect(() => {
        if (participationData && !timeoutRef.current) {
            navigate(`/akcja/${slug}/udzial`);

            timeoutRef.current = setTimeout(() => {
                window.location.replace(participationData.redirectUrl);
            }, 3000);
        }
    }, [participationData, navigate, slug]);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
            props.actions.getActionPageInit();
        }
    }, [props.actions]);

    return (
        <>
            {actionPageDetails?.status === ACTION_STATUSES.FUTURE && (
                <div className={actionPageStyles.futureStatusInfoContainer}>
                    <Container>
                        <Typography variant={"subtitle1"} align={"center"}>
                            Akcja rozpocznie się {actionPageDetails.actionDateFrom}
                        </Typography>
                    </Container>
                </div>
            )}

            {actionPageDetails ? (
                <>
                    <LazyLoad
                        actionPageDetails={actionPageDetails}
                        user={user}
                        resolve={() => import(`./Actions/${actionPageDetails.templateName}/index`)}
                    />
                    <ParticipationDialogContainer/>
                </>
            ) : (
                <>
                    {actionPageDetailsErrors?.find(error => error.code === 'ACTION:BAD_ACCESS_TOKEN') ? (
                        <ActionAccessTokenContainer/>
                    ) : (
                        <Container>
                            <div className={mainStyles.content}>
                                <h1 className={mainStyles.contentTitle}>Wystąpił błąd</h1>
                                <div className={mainStyles.contentBody}>
                                    <div className={mainStyles.contentBodyFull}>
                                        {actionPageDetailsErrors?.find(error => error.code === 'ACTION:NOT_FOUND') ? (
                                            <DisplayMessageContainer type={DISPLAY_MESSAGES.ACTION_PAGE_NOT_FOUND}/>
                                        ) : (
                                            <DisplayMessageContainer type={DISPLAY_MESSAGES.SOMETHING_WENT_WRONG}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Container>

                    )}
                </>
            )}
        </>
    );
}

export default ActionPage;