import {makeStyles} from "tss-react/mui";

const useStylesComplaintsDataDialog = makeStyles()(theme => ({
    complaintsDataDialog: {
    },
    content: {
        padding: "30px 15px",
    },
    title: {
        position: "relative",
        textAlign: "center",
        borderBottom: `1px solid ${theme.palette.color3}`,
    },
    titleDescription: {
        display: "block",
        color: theme.palette.color1,
        fontSize: "14px",
    },
    closeIcon: {
        position: "absolute",
        right: "5px",
        top: "50%",
        transform: "translate(0, -50%)",
    },
    titleText: {
        display: "block",
        color: "#000",
        fontSize: "18px",
        fontWeight: 600,
    },
    complaintsInfo: {
        textAlign: "center",
        margin: "0 0 15px 0",
        color: theme.palette.color7,
    },
    buttonsContainer: {
        backgroundColor: theme.palette.color6,
        borderTop: `1px solid ${theme.palette.color3}`,
    },
    cancelButton: {
        textTransform: "none",
    },
    submitButton: {
        padding: "10px 20px",
        fontSize: "14px",
        backgroundColor: theme.palette.color1,
        color: "#fff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        }
    },
}))

export default useStylesComplaintsDataDialog;