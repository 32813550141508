import React from 'react';
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Close} from "@mui/icons-material";
import BasicFormBuilderContainer from "@components/shared/BasicFormBuilder";
import IconButton from "@mui/material/IconButton";
import useStylesComplaintsDataDialog from "./styles";

const ComplaintsDataDialog = (props) => {
    const {fields, errors, handleFormValid} = props;
    const {classes: complaintsDataDialogStyles} = useStylesComplaintsDataDialog();

    return (
        <>
            <Dialog
                className={complaintsDataDialogStyles.complaintsDataDialog}
                fullWidth={true}
                maxWidth={"sm"}
                open={true}
            >
                <DialogTitle className={complaintsDataDialogStyles.title}>
                    <div className={complaintsDataDialogStyles.titleText}>
                        Dane do reklamacji
                    </div>
                    <div className={complaintsDataDialogStyles.titleDescription}>
                        {props.user.auth.userData.email} ({props.user.auth.userData.firstName} {props.user.auth.userData.lastName})
                    </div>
                    <IconButton
                        aria-label="close"
                        className={complaintsDataDialogStyles.closeIcon}
                        onClick={() => props.setComplaintsItem(null)}
                        size="large">
                        <Close/>
                    </IconButton>
                </DialogTitle>
                <DialogContent className={complaintsDataDialogStyles.content}>
                    {props.complaintsItem.action.complaintsInfo && (
                        <div className={complaintsDataDialogStyles.complaintsInfo}
                             dangerouslySetInnerHTML={{__html: props.complaintsItem.action.complaintsInfo}}/>
                    )}

                    <BasicFormBuilderContainer
                        fields={fields}
                        errors={errors}
                        handleFormValid={handleFormValid}
                        btnText="Wyślij reklamację"
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ComplaintsDataDialog;