import PAGES from "@consts/pages";
import ROUTES from "@consts/routes";
import StartPageContainer from "@components/views/StartPage";
import ActionPageContainer from "@components/views/ActionPage";
import LoginContainer from "@components/views/Login";
import RecoverPasswordContainer from "@components/views/RecoverPassword";
import ResetPasswordContainer from "@components/views/ResetPassword";
import RegisterContainer from "@components/views/Register";
import RegisterCompletedContainer from "@components/views/RegisterCompleted";
import ActivateAccountContainer from "@components/views/ActivateAccount";
import FaqContainer from "@components/views/Faq";
import StaticPageContainer from "@components/views/StaticPage";
import StatisticsContainer from "@components/views/Statistics";
import ContactContainer from "@components/views/Contact";
import UserProfileContainer from "@components/views/UserProfile";
import ComplaintsContainer from "@components/views/Complaints";
import PayoutContainer from "@components/views/Payout";
import NotFoundPageContainer from "@components/views/NotFoundPage";

const ROUTES_CONFIG = [
    {
        name: PAGES.HOME,
        path: ROUTES.HOME,
        exact: true,
        authRequired: false,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "home",
            "cg3": "",
            "cg4": "",
            "cg5": "home",
        },
        component: StartPageContainer
    },
    {
        name: PAGES.ACTION_PAGE_PARTICIPATION,
        path: ROUTES.ACTION_PAGE_PARTICIPATION,
        exact: false,
        authRequired: false,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "other",
        },
        component: ActionPageContainer
    },
    {
        name: PAGES.ACTION_PAGE,
        path: ROUTES.ACTION_PAGE,
        exact: false,
        authRequired: false,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: ActionPageContainer
    },
    {
        name: PAGES.LOGIN,
        path: ROUTES.LOGIN,
        exact: true,
        authRequired: false,
        logoutBeforeMount: true,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: LoginContainer
    },
    {
        name: PAGES.RECOVER_PASSWORD,
        path: ROUTES.RECOVER_PASSWORD,
        exact: true,
        authRequired: false,
        logoutBeforeMount: true,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: RecoverPasswordContainer
    },
    {
        name: PAGES.RESET_PASSWORD,
        path: ROUTES.RESET_PASSWORD,
        exact: true,
        authRequired: false,
        logoutBeforeMount: true,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: ResetPasswordContainer
    },
    {
        name: PAGES.REGISTER,
        path: ROUTES.REGISTER,
        exact: true,
        authRequired: false,
        logoutBeforeMount: true,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: RegisterContainer
    },
    {
        name: PAGES.REGISTER_COMPLETED,
        path: ROUTES.REGISTER_COMPLETED,
        exact: true,
        authRequired: false,
        logoutBeforeMount: true,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: RegisterCompletedContainer
    },
    {
        name: PAGES.ACTIVATE_ACCOUNT,
        path: ROUTES.ACTIVATE_ACCOUNT,
        exact: true,
        authRequired: false,
        logoutBeforeMount: true,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: ActivateAccountContainer
    },
    {
        name: PAGES.FAQ,
        path: ROUTES.FAQ,
        exact: true,
        authRequired: false,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: FaqContainer
    },
    {
        name: PAGES.STATIC_PAGE,
        path: ROUTES.STATIC_PAGE,
        exact: true,
        authRequired: false,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: StaticPageContainer
    },
    {
        name: PAGES.STATISTICS,
        path: ROUTES.STATISTICS,
        exact: true,
        authRequired: true,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: StatisticsContainer
    },
    {
        name: PAGES.CONTACT,
        path: ROUTES.CONTACT,
        exact: true,
        authRequired: false,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "contact",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: ContactContainer
    },
    {
        name: PAGES.USER_PROFILE,
        path: ROUTES.USER_PROFILE,
        exact: true,
        authRequired: true,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "my-account",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: UserProfileContainer
    },
    {
        name: PAGES.COMPLAINTS,
        path: ROUTES.COMPLAINTS,
        exact: true,
        authRequired: true,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: ComplaintsContainer
    },
    {
        name: PAGES.PAYOUT,
        path: ROUTES.PAYOUT,
        exact: true,
        authRequired: true,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "content",
        },
        component: PayoutContainer
    },
    {
        name: PAGES.NOT_FOUND,
        path: ROUTES.NOT_FOUND,
        exact: false,
        authRequired: null,
        logoutBeforeMount: false,
        ga4Config: {
            "cg1": "level-1",
            "cg2": "others",
            "cg3": "",
            "cg4": "",
            "cg5": "error",
        },
        component: NotFoundPageContainer
    }
];

export default ROUTES_CONFIG;