import {
    LOGOUT, RESET_REDIRECT_USER_AFTER_LOGOUT_TO,
} from "@store/types/user";

import ROUTES from "@consts/routes";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {getAccessToken, removeTokens} from "@tools/tokens";
import {client} from "@api/client";
import partnerLeadId from "@tools/partnerLeadId";
import LOADER_PLACES from "@consts/loaderPlaces";

const logoutUser = (data) => ({
    type: LOGOUT,
    payload: data
});

const resetRedirectUserAfterLogoutTo = () => ({
    type: RESET_REDIRECT_USER_AFTER_LOGOUT_TO
});

const logout = (logoutWithRedirect) => {
    return async (dispatch) => {
        partnerLeadId.removeAll();

        await dispatch(setMultiLoader(LOADER_PLACES.LOGOUT));

        const path = '/api/auth/logout';

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        const data = {};

        try {
            await client('POST', path, data, headers);
        } catch (error) {
        }

        removeTokens();
        await dispatch(logoutUser(logoutWithRedirect && ROUTES.HOME));

        await dispatch(resetMultiLoader(LOADER_PLACES.LOGOUT));
    }
}

export {
    logout,
    resetRedirectUserAfterLogoutTo
};