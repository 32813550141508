import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ActionsSection from "@components/views/shared/ActionsSection/Main";
import {getActionsList} from "@store/actions/actionsList";
import {useMount} from "@s-ui/react-hooks";

const ActionsSectionContainer = (props) => {
    useMount(() => {
        props.actions.getActionsList();
    });

    return (
        <ActionsSection actionsItems={props.actionsList.actionsItems}/>
    );
}

const mapStateToProps = (state) => ({
    actionsList: state.actionsList
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getActionsList,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionsSectionContainer);