import React from 'react';
import ResetPassword from "@components/views/ResetPassword/Main";
import {connect} from "react-redux";
import {getResetPasswordToken} from "@store/actions/user";
import {resetPassword} from "@store/actions/user";
import {bindActionCreators} from "redux";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import {useMount} from "@s-ui/react-hooks";

const ResetPasswordContainer = (props) => {
    const formFields = BASIC_FORM_FIELDS_CONFIG.RESET_PASSWORD;

    const {resetPasswordToken} = props.params;

    const handleFormValid = (data) => {
        data = {
            ...data,
            token: resetPasswordToken,
        }
        props.actions.resetPassword(data);
    }

    useMount(() => {
        props.actions.getResetPasswordToken(resetPasswordToken);
    });

    return (
        <ResetPassword
            fields={formFields}
            user={props.user}
            handleFormValid={handleFormValid}
        />
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getResetPasswordToken,
            resetPassword,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordContainer);