import React, {useCallback, useEffect, useState} from 'react';
import UserProfile from "@components/views/UserProfile/Main";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    updateProfileInit,
    updateProfile,
    changePasswordInit,
    changePassword,
    deleteAccountInit,
    deleteAccount,
} from "@store/actions/user";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import {useMount} from "@s-ui/react-hooks";

const UserProfileContainer = (props) => {
    const [formFields, setFormFields] = useState({
        userData: BASIC_FORM_FIELDS_CONFIG.USER_PROFILE.USER_DATA,
        changePassword: BASIC_FORM_FIELDS_CONFIG.USER_PROFILE.CHANGE_PASSWORD,
        deleteAccount: BASIC_FORM_FIELDS_CONFIG.USER_PROFILE.DELETE_ACCOUNT,
    });

    const initActions = () => {
        props.actions.updateProfileInit();
        props.actions.changePasswordInit();
        props.actions.deleteAccountInit();
    }

    const updateUserDataForm = useCallback(() => {
        const waitingForEditAcceptance = props.user.auth.userData.waitingForEditAcceptance;

        setFormFields({
            ...formFields,
            userData: BASIC_FORM_FIELDS_CONFIG.USER_PROFILE.USER_DATA.map((field) => {
                return {
                    ...field,
                    isDisabled: field.isDisabled || (waitingForEditAcceptance && field.name !== 'phone'),
                    value: props.user.auth.userData[field.name],
                }
            }),
        })
    }, [formFields, props.user.auth.userData]);

    const updateChangePasswordForm = useCallback(() => {
        setFormFields({
            ...formFields,
            changePassword: BASIC_FORM_FIELDS_CONFIG.USER_PROFILE.CHANGE_PASSWORD.map((field) => {
                return {
                    ...field
                }
            }),
        });
    }, [formFields]);

    const handleUpdateProfileFormValid = (data) => {
        props.actions.updateProfile(data);
    }

    const handleChangePasswordFormValid = (data) => {
        props.actions.changePassword(data);
    }

    const handleDeleteAccountFormValid = (data) => {
        props.actions.deleteAccount(data, props.navigate);
    }

    useEffect(() => {
        if (props.user.profile.updateProfileSuccess) {
            updateUserDataForm();
        }
        if (props.user.profile.changePasswordSuccess) {
            updateChangePasswordForm();
        }
    }, [props.user.profile.updateProfileSuccess, props.user.profile.changePasswordSuccess, updateUserDataForm, updateChangePasswordForm]);

    useMount(() => {
        initActions();
        updateUserDataForm();
    });

    return (
        <UserProfile
            user={props.user}
            formFields={formFields}
            initActions={initActions}
            handleUpdateProfileFormValid={handleUpdateProfileFormValid}
            handleChangePasswordFormValid={handleChangePasswordFormValid}
            handleDeleteAccountFormValid={handleDeleteAccountFormValid}
        />
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            updateProfileInit,
            updateProfile,
            changePasswordInit,
            changePassword,
            deleteAccountInit,
            deleteAccount,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfileContainer);