import {
    UPDATE_PROFILE_INIT,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import {getAccessToken} from "@tools/tokens";
import LOADER_PLACES from "@consts/loaderPlaces";

const updateProfileInit = () => ({
    type: UPDATE_PROFILE_INIT,
});

const updateProfileSuccess = (data) => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: data
});

const updateProfileFailure = (errors) => ({
    type: UPDATE_PROFILE_FAILURE,
    payload: errors
});

const updateProfile = (data) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.PROFILE_UPDATE));
        await dispatch(updateProfileInit());

        const path = '/api/profile';

        const headers = {
            Authorization: `Bearer ${getAccessToken()}`,
        };

        try {
            const response = await client('PATCH', path, data, headers);
            const responseData = response.data.data;

            await dispatch(updateProfileSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(updateProfileFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.PROFILE_UPDATE));
    }
}

export {
    updateProfileInit,
    updateProfile,
};