import {makeStyles} from "tss-react/mui";

const useStylesComplaints = makeStyles()(theme => ({
    action: {
        fontSize: "14px",
        margin: "0 auto",
        maxWidth: "395px",
        borderRadius: "7px",
        overflow: "hidden",
        boxShadow: `0 1px 2px ${theme.palette.color5}`,
        backgroundColor: "#fff",
    },
    actionContent: {
        width: "100%",
        padding: "0 15px",
    },
    name: {
        fontSize: "16px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 600,
        lineHeight: "1.5em",
        minHeight: "4em",
        color: "#000",
    },
    topContainer: {
        position: "relative",
        backgroundColor: theme.palette.color3Darker,
    },
    imageContainer: {
        position: "relative",
    },
    imageMask: {
        width: "100%",
    },
    image: {
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
    },
    imageMessage: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: "5px",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textShadow: "0 0 5px #000",
        color: "#fff",
    },
    imageMessageTitle: {
        fontWeight: 400,
    },
    imageMessageDate: {
        fontWeight: 600,
    },
    parametersContainer: {
        width: "100%",
        padding: "15px 0",
        borderTop: `1px solid ${theme.palette.color3Darker}`,
    },
    parameter: {
        width: "100%",
        display: "table",
        textAlign: "center",
        margin: "0 0 15px 0",
        "&:last-of-type": {
            margin: "0",
        }
    },
    parameterName: {
        textTransform: "uppercase",
        color: theme.palette.color4,
    },
    parameterValue: {
        fontWeight: 600,
        color: "#000",
    },
    ctaContainer: {
        borderTop: `1px solid ${theme.palette.color3Darker}`,
        display: "flex",
        justifyContent: "center",
        padding: "30px 0",
    },
    ctaAddData: {
        backgroundColor: "#fff",
        color: theme.palette.color2,
        border: `2px solid ${theme.palette.color2}`,
        textTransform: "none",
        minWidth: "180px",
        "&:hover": {
            backgroundColor: "#fff",
        }
    },
    ctaAddNextData: {
        backgroundColor: "#fff",
        color: theme.palette.color1,
        border: `2px solid ${theme.palette.color1}`,
        textTransform: "none",
        minWidth: "180px",
        "&:hover": {
            backgroundColor: "#fff",
        }
    },
    successSnackbar: {
        "& .MuiPaper-root": {
            minWidth: "auto",
            flexGrow: 0,
            backgroundColor: theme.palette.color2,
            textAlign: "center",
        }
    },
}))

export default useStylesComplaints;