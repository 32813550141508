import React from 'react';
import {Container} from "@mui/material";
import {Link} from "react-router-dom";
import DisplayMessageContainer from "@components/shared/DisplayMessage";
import DISPLAY_MESSAGES from "@consts/displayMessages";
import SkeletonContentContainer from "@components/shared/SkeletonContent";
import SKELETONS from "@consts/skeletons";
import useStylesMain from "@components/layout/Main/styles";
import useStylesStaticPage from "./styles";

const StaticPage = (props) => {
    const {classes: mainStyles} = useStylesMain();
    const {classes: staticPageStyles} = useStylesStaticPage();

    const getTitle = () => {
        if (props.staticPageData?.title) {
            return props.staticPageData.title;
        } else if (props.errors) {
            return "Wystąpił błąd";
        } else {
            return <SkeletonContentContainer type={SKELETONS.STATIC_PAGE_TITLE}/>;
        }
    }

    const getContent = () => {
        if (props.staticPageData) {
            return (
                <>
                    <div className={staticPageStyles.textContainer} dangerouslySetInnerHTML={{__html: props.staticPageData.content}}/>

                    {props.staticPageData.attachments.length > 0 && (
                        <div className={staticPageStyles.attachments}>
                            <div className={staticPageStyles.attachmentsTitle}>Załączniki</div>
                            <ul>
                                {props.staticPageData.attachments && props.staticPageData.attachments.map((attachment, index) => (
                                    <li key={index}>
                                        <Link
                                            to={{pathname: attachment.url}}
                                            target="_blank"
                                            className={staticPageStyles.attachment}>{attachment.name}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
            )
        } else if (props.errors) {
            return (
                <DisplayMessageContainer type={DISPLAY_MESSAGES.SOMETHING_WENT_WRONG}/>
            )
        } else if (!props.staticPageData && !props.errors) {
            return (
                <SkeletonContentContainer type={SKELETONS.STATIC_PAGE}/>
            )
        }
    }

    return (
        <>
            <Container>
                <div className={mainStyles.content}>
                    <h1 className={mainStyles.contentTitle}>
                        {getTitle()}
                    </h1>
                    <div className={mainStyles.contentBody}>
                        <div className={mainStyles.contentBodyFull}>
                            {getContent()}
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default StaticPage;