import {
    MAINTENANCE,
    SET_APP_READY,
    SET_MULTI_LOADER,
    RESET_MULTI_LOADER
} from "@store/types/config";
import {client} from "@api/client";
import LOADER_PLACES from "@consts/loaderPlaces";

const setMaintenance = () => ({
    type: MAINTENANCE
});

const setAppReady = () => ({
    type: SET_APP_READY
});

const setMultiLoader = (name) => ({
    type: SET_MULTI_LOADER,
    payload: name
});

const resetMultiLoader = (name) => ({
    type: RESET_MULTI_LOADER,
    payload: name
});

const isSiteEnabled = () => {
    return async (dispatch) => {
        await dispatch(setMultiLoader(LOADER_PLACES.IS_SITE_ENABLED));

        const path = '/api/system/is-site-enabled';

        const headers = {};

        try {
            const response = await client('GET', path, null, headers);
            const responseData = response.data.data;
            const {isSiteEnabled} = responseData;

            if (!isSiteEnabled) {
                await dispatch(setMaintenance());
            }

            await dispatch(resetMultiLoader(LOADER_PLACES.IS_SITE_ENABLED));
            await dispatch(setAppReady());
        } catch (error) {
            alert('Błąd aplikacji. Spróbuj ponownie za chwilę.');
        }
    }
}

export {
    isSiteEnabled,
    setMaintenance,
    setAppReady,
    setMultiLoader,
    resetMultiLoader,
}

