import {
    GET_REGISTER_AGREEMENTS_INIT,
    GET_REGISTER_AGREEMENTS_SUCCESS,
    GET_REGISTER_AGREEMENTS_FAILURE,
} from "@store/types/user";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const getRegisterAgreementsInit = () => ({
    type: GET_REGISTER_AGREEMENTS_INIT
});

const getRegisterAgreementsSuccess = (data) => ({
    type: GET_REGISTER_AGREEMENTS_SUCCESS,
    payload: data
});

const getRegisterAgreementsFailure = (errors) => ({
    type: GET_REGISTER_AGREEMENTS_FAILURE,
    payload: errors
});

const getRegisterAgreements = () => {
    return async (dispatch) => {
        await dispatch(setMultiLoader(LOADER_PLACES.REGISTER_AGREEMENTS));
        await dispatch(getRegisterAgreementsInit());

        const path = '/api/agreements/REGISTER';

        const headers = {};

        try {
            const response = await client('GET', path, null, headers);
            const responseData = response.data.data;
            await dispatch(getRegisterAgreementsSuccess(responseData));
        } catch (error) {
            if (error.response) {
                await dispatch(getRegisterAgreementsFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.REGISTER_AGREEMENTS));
    }
}

export default getRegisterAgreements;