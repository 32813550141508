import {
    GET_PAYOUT_INIT,
    GET_PAYOUT_SUCCESS,
    GET_PAYOUT_FAILURE,
    SEND_PAYOUT_DATA_INIT,
    SEND_PAYOUT_DATA_SUCCESS,
    SEND_PAYOUT_DATA_FAILURE
} from "@store/types/payout";

const initialState = {
    payoutItems: null,
    payoutItemsErrors: null,
    payoutDataSent: false,
    payoutDataSentErrors: null,
}

const payoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYOUT_INIT: {
            return initialState;
        }
        case GET_PAYOUT_SUCCESS: {
            return {
                ...state,
                payoutItems: action.payload,
                payoutItemsErrors: initialState.payoutItemsErrors,
            };
        }
        case GET_PAYOUT_FAILURE: {
            return {
                ...state,
                payoutItems: initialState.payoutItems,
                payoutItemsErrors: action.payload
            };
        }

        case SEND_PAYOUT_DATA_INIT: {
            return {
                ...state,
                payoutDataSent: initialState.payoutDataSent,
                payoutDataSentErrors: initialState.payoutDataSentErrors
            };
        }
        case SEND_PAYOUT_DATA_SUCCESS: {
            return {
                payoutItems: action.payload,
                payoutItemsErrors: initialState.payoutItemsErrors,
                payoutDataSent: true,
                payoutDataSentErrors: initialState.payoutDataSentErrors,
            };
        }
        case SEND_PAYOUT_DATA_FAILURE: {
            return {
                ...state,
                payoutDataSent: false,
                payoutDataSentErrors: action.payload,
            };
        }

        default:
            return state;
    }
}

export default payoutReducer;


