import {
    AccountBalanceWallet,
    ClearAll,
    Edit, Error, ErrorOutline,
    ImportantDevices,
    LiveTv,
    Lock,
    MailOutline,
    Message, NewReleases,
    NotListedLocation, VerifiedUser,
} from "@mui/icons-material";
import ROUTES from "@consts/routes";

const DISPLAY_MESSAGES = {
    ACTIONS_NO_ACTIVE_ACTIONS: {
        icon: ImportantDevices,
        title: 'Już niedługo nowe Akcje Bonus!',
        description: '',
        link: null,
        linkText: null,
    },
    ACTION_PAGE_NOT_FOUND: {
        icon: NewReleases,
        title: 'Akcja nie została odnaleziona',
        description: 'Upewnij się czy link jest poprawny',
        link: ROUTES.HOME,
        linkText: 'Przejdź do strony głównej',
    },
    STATISTICS_EMPTY: {
        icon: LiveTv,
        title: 'Brak statystyk',
        description: 'Zachęcamy do wzięcia udziału w naszych Akcjach Bonus!',
        link: ROUTES.HOME,
        linkText: 'Przejdź do Akcji Bonus',
    },
    COMPLAINTS_EMPTY: {
        icon: LiveTv,
        title: 'Brak możliwych reklamacji',
        description: 'Aktualnie nie ma Akcji Bonus, na które można złożyć reklamacje',
        link: ROUTES.HOME,
        linkText: 'Przejdź do Akcji Bonus',
    },
    PAYOUT_EMPTY: {
        icon: AccountBalanceWallet,
        title: 'Nie masz nic do wypłaty',
        description: 'Aktualnie nie ma Akcji Bonus, w których można dokonać wypłaty',
        link: ROUTES.HOME,
        linkText: 'Przejdź do Akcji Bonus',
    },
    REGISTER_CHECK_EMAIL: {
        icon: MailOutline,
        title: 'Sprawdz swoją pocztę!',
        description: 'Potwierdź rejestrację profilu uczestnika w Akcji Bonus. Kliknij w link aktywacyjny w wiadomości aktywacyjnej, którą wysłaliśmy na podany przez Ciebie adres e-mail.',
        link: null,
        linkText: null,
    },
    ACTIVATE_ACCOUNT_SUCCESS: {
        icon: VerifiedUser,
        title: 'Twój profil uczestnika został aktywowany!',
        description: 'Możesz się zalogować',
        link: ROUTES.LOGIN,
        linkText: 'Przejdź do strony logowania',
    },
    ACTIVATE_ACCOUNT_ERROR: {
        icon: Error,
        title: null,
        description: null,
        link: ROUTES.HOME,
        linkText: 'Przejdź do strony głównej',
    },
    RECOVER_PASSWORD_CHECK_EMAIL: {
        icon: MailOutline,
        title: 'Sprawdz swoją pocztę!',
        description: 'Instrukcja resetowania hasła została wysłana na podany adres email.',
        link: ROUTES.HOME,
        linkText: 'Przejdź do strony głównej',
    },
    RECOVER_PASSWORD_TOKEN_ERROR: {
        icon: ErrorOutline,
        title: 'Token jest niepoprawny lub wygasł',
        description: 'Możesz ponownie wysłać żądanie odzyskania hasła',
        link: ROUTES.RECOVER_PASSWORD,
        linkText: 'Odzyskaj hasło',
    },
    RESET_PASSWORD_PASSWORD_CHANGED: {
        icon: Lock,
        title: 'Hasło zostało zmienione!',
        description: 'Już teraz możesz zalogować się do swojego profilu uczestnika',
        link: ROUTES.LOGIN,
        linkText: 'Przejdź do strony logowania',
    },
    USER_PROFILE_UPDATE_PROFILE: {
        icon: Edit,
        title: 'Aktualizacja danych',
        description: 'Zmiana niektórych pól wymaga weryfikacji przez nasz zespół',
        link: null,
        linkText: null,
    },
    USER_PROFILE_CHANGE_PASSWORD: {
        icon: Lock,
        title: 'Utwórz nowe hasło',
        description: 'Wpisz aktualne hasło, a następnie ustal nowe silne hasło',
        link: null,
        linkText: null,
    },
    USER_PROFILE_DELETE_PROFILE: {
        icon: ClearAll,
        title: 'Usuwanie profilu uczestnika',
        description: 'Aby usunąć profil uczestnika, wymagane będzie podanie hasła. W procesie usuwania profilu użytkownik zostanie wylogowany',
        link: null,
        linkText: null,
    },
    CONTACT_MESSAGE_SENT: {
        icon: Message,
        title: 'Wiadomość została wysłana!',
        description: 'Już niedługo odpowiemy na Twoje zapytanie',
        link: null,
        linkText: null,
    },
    PAGE_NOT_FOUND: {
        icon: NotListedLocation,
        title: 'Strona nie została odnaleziona',
        description: 'Upewnij się, czy odwiedzany adres jest prawidłowy',
        link: ROUTES.HOME,
        linkText: 'Przejdź do strony głównej',
    },
    SOMETHING_WENT_WRONG: {
        icon: Error,
        title: "Coś poszło nie tak",
        description: "Spróbuj ponownie później",
        link: null,
        linkText: null,
    },
};

export default DISPLAY_MESSAGES;
