import React from 'react';
import ActivateAccount from "@components/views/ActivateAccount/Main";
import {connect} from "react-redux";
import {activateAccount} from "@store/actions/user";
import {bindActionCreators} from "redux";
import {useMount} from "@s-ui/react-hooks";

const ActivateAccountContainer = (props) => {
    useMount(() => {
        const data = {
            activationToken: props.params.activationToken,
        }
        props.actions.activateAccount(data);
    });

    return (
        <ActivateAccount
            user={props.user}
        />
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            activateAccount,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivateAccountContainer);