import {makeStyles} from "tss-react/mui";

const useStylesCookiesInfo = makeStyles()(theme => ({
    cookiesInfoContainer: {
        position: "fixed",
        bottom: 0,
        right: 0,
        display: "table",
        width: "100%",
        zIndex: 10,
        backgroundColor: "#fff",
        boxShadow: `0 -1px 2px ${theme.palette.color5}`,
        [theme.breakpoints.up("lg")]: {
            maxWidth: "600px",
            bottom: "15px",
            right: "15px",
            boxShadow: `0 0 2px ${theme.palette.color5}`,
            borderRadius: "5px",
        },
    },
    content: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "15px 0",
    },
    text: {
        fontSize: "13px",
        textAlign: "justify",
        color: "#000",
        margin: 0,
    },
    closeBtn: {
        alignSelf: "flex-end",
        margin: "10px 0 0 0",
        backgroundColor: theme.palette.color1,
        color: "#fff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.color1Darker,
        },
    },
}));

export default useStylesCookiesInfo;