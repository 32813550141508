import {
    SEND_CONTACT_MESSAGE_INIT,
    SEND_CONTACT_SUCCESS,
    SEND_CONTACT_FAILURE,
} from "@store/types/contact";

import {client} from "@api/client";
import {setMultiLoader, resetMultiLoader} from "@store/actions/config";
import LOADER_PLACES from "@consts/loaderPlaces";

const sendMessageInit = () => ({
    type: SEND_CONTACT_MESSAGE_INIT
});

const sendMessageSuccess = () => ({
    type: SEND_CONTACT_SUCCESS,
});

const sendMessageFailure = (errors) => ({
    type: SEND_CONTACT_FAILURE,
    payload: errors
});

const sendMessage = (data) => {
    return async dispatch => {
        await dispatch(setMultiLoader(LOADER_PLACES.CONTACT_SEND_MESSAGE));
        await dispatch(sendMessageInit());

        const path = '/api/special-page/contact-form';

        const headers = {};

        try {
            await client('POST', path, data, headers);

            await dispatch(sendMessageSuccess());
        } catch (error) {
            if (error.response) {
                await dispatch(sendMessageFailure(error.response.data.errors));
            }
        }

        await dispatch(resetMultiLoader(LOADER_PLACES.CONTACT_SEND_MESSAGE));
    }
}

export default sendMessage;