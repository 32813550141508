import React, {useEffect} from 'react';
import HeaderContainer from "@components/layout/Header";
import MainContainer from "@components/layout/Main";
import FooterContainer from "@components/layout/Footer";
import CookiesInfoContainer from "@components/layout/CookiesInfo";
import {Navigate, useLocation, useNavigate, useParams} from "react-router";
import ROUTES from "@consts/routes";
import Ga4Interface from "@tools/ga4interface";
import PAGES from "@consts/pages";

const Authorization = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const {name, ga4Config} = props.routeConfig;
    const {isLoggedIn} = props.user.auth;

    useEffect(() => {
        if(props.user.auth.redirectToAfterLogout) {
            props.actions.resetRedirectUserAfterLogoutTo();
        }
    }, [props.user.auth.redirectToAfterLogout, props.actions]);

    useEffect(() => {
        if (name !== PAGES.ACTION_PAGE && name !== PAGES.STATIC_PAGE) {
            new Ga4Interface({
                level: 'bonusebrokerpartner',
                highlightEvents: false,
                debug: false,
                isError: name === PAGES.NOT_FOUND,
                isLoggedIn,
                categoriesObject: ga4Config
            });
        }
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        if (name === PAGES.STATIC_PAGE && (props.staticPage.staticPageData || props.staticPage.errors)) {
            new Ga4Interface({
                level: 'bonusebrokerpartner',
                highlightEvents: false,
                debug: false,
                isError: !!props.staticPage.errors,
                isLoggedIn,
                categoriesObject: ga4Config
            });
        }
        // eslint-disable-next-line
    }, [props.staticPage]);

    useEffect(() => {
        if (name === PAGES.ACTION_PAGE && (props.actionPage.actionPageDetails || props.actionPage.actionPageDetailsErrors)) {
            new Ga4Interface({
                level: 'bonusebrokerpartner',
                highlightEvents: false,
                debug: false,
                isError: !!props.actionPage.actionPageDetailsErrors,
                isLoggedIn,
                categoriesObject: ga4Config
            });
        }
        // eslint-disable-next-line
    }, [props.actionPage.actionPageDetails, props.actionPage.actionPageDetailsErrors]);

    return (
        <>
            {props.user.auth.redirectToAfterLogout
                ? <Navigate to={props.user.auth.redirectToAfterLogout}/>
                : (
                    <>
                        {(!props.user.auth.isLoggedIn && props.routeConfig.authRequired)
                            ? <Navigate to={ROUTES.LOGIN}/>
                            : <>
                                <HeaderContainer
                                    {...props}
                                    navigate={navigate}
                                    location={location}
                                    params={params}
                                />
                                <MainContainer
                                    {...props}
                                    navigate={navigate}
                                    location={location}
                                    params={params}
                                    content={props.routeConfig.component}
                                />
                                <FooterContainer/>
                                <CookiesInfoContainer/>
                            </>
                        }
                    </>
                )}

        </>
    );
}

export default Authorization;