import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {registerInit} from "@store/actions/user";
import {bindActionCreators} from "redux";
import RegisterCompleted from "@components/views/RegisterCompleted/Main";
import {Navigate} from "react-router";
import ROUTES from "@consts/routes";

const RegisterCompletedContainer = (props) => {
    useEffect(() => {
        return () => {
            props.actions.registerInit();
        }
    }, [props.actions]);

    return (
        <>
            {props.user.profile.registerSuccess ? (
                <RegisterCompleted/>
            ) : (
                <Navigate to={ROUTES.REGISTER} push={true}/>
            )}
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            registerInit,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterCompletedContainer);