import React from 'react';
import StartPage from "@components/views/StartPage/Main";
import {connect} from "react-redux";

const StartPageContainer = (props) => {
    return (
        <StartPage user={props.user}/>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StartPageContainer);