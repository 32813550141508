import React from 'react';
import RecoverPassword from "@components/views/RecoverPassword/Main";
import {connect} from "react-redux";
import {recoverPasswordInit, recoverPassword} from "@store/actions/user";
import {bindActionCreators} from "redux";
import BASIC_FORM_FIELDS_CONFIG from "@consts/basicFormFieldsConfig";
import {useMount} from "@s-ui/react-hooks";

const RecoverPasswordContainer = (props) => {
    const formFields = BASIC_FORM_FIELDS_CONFIG.RECOVER_PASSWORD;

    const handleFormValid = (data) => {
        props.actions.recoverPassword(data);
    }

    useMount(() => {
        props.actions.recoverPasswordInit();
    });

    return (
        <RecoverPassword
            fields={formFields}
            user={props.user}
            handleFormValid={handleFormValid}
        />
    );
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            recoverPasswordInit,
            recoverPassword,
        },
        dispatch,
    ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecoverPasswordContainer);