import React, {useState} from 'react';
import Button from "@mui/material/Button";
import {Container} from "@mui/material";
import ROUTES from "@consts/routes";
import {Link} from "react-router-dom";
import useStylesCookiesInfo from "./styles";
import {useMount} from "@s-ui/react-hooks";
import cookie from "react-cookies";

const CookiesInfo = () => {
    const {classes: cookiesInfoStyles} = useStylesCookiesInfo();

    const [infoClosed, setInfoClosed] = useState(false);

    useMount(() => {
        const infoClosed = cookie.load('cookiesInfoClosed') === 'true';

        if (infoClosed) {
            setInfoClosed(true);
        }
    });

    const handleCloseInfo = () => {
        const maxAge = 14 * 24 * 60 * 60;

        setInfoClosed(true);

        cookie.save('cookiesInfoClosed', 'true', {maxAge, path: '/'});
    }

    return (
        <>
            {!infoClosed && (<div className={cookiesInfoStyles.cookiesInfoContainer}>
                <Container>
                    <div className={cookiesInfoStyles.content}>
                        <p className={cookiesInfoStyles.text}>
                            Używamy cookies, aby świadczyć usługi na najwyższym poziomie. Korzystając ze strony wyrażasz
                            zgodę na ich używanie zgodnie z aktualnymi ustawieniami przeglądarki. <Link
                            to={ROUTES.PRIVACY_POLICY}>Polityka prywatności</Link>.
                        </p>
                        <Button
                            variant="contained"
                            className={cookiesInfoStyles.closeBtn}
                            disableElevation
                            onClick={handleCloseInfo}
                        >Zamknij</Button>
                    </div>
                </Container>
            </div>)}
        </>
    );
}

export default CookiesInfo;