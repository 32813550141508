import {makeStyles} from "tss-react/mui";

const useStylesUserProfile = makeStyles()(theme => ({
    tabsButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.color3}`,
        borderRadius: 0,
    },
    tabButton: {
        fontWeight: 600,
        fontSize: "14px",
        textTransform: "none",
        padding: "0 10px",
        border: "none",
        borderRadius: 0,
        borderBottom: "2px solid transparent",
        "&:hover": {
            border: "none",
            borderBottom: "2px solid transparent",
            background: theme.palette.color1Lighter,
        },
        [theme.breakpoints.up('lg')]: {
            padding: "0 20px",
        },
    },
    tabButtonActive: {
        borderBottom: `2px solid ${theme.palette.color1}`,
        "&:hover": {
            borderBottom: `2px solid ${theme.palette.color1}`,
        },
    },
    tabsContent: {
        width: "100%",
        margin: "30px 0 0 0",
    },
    tabContent: {},
    successSnackbar: {
        "& .MuiPaper-root": {
            minWidth: "auto",
            flexGrow: 0,
            backgroundColor: theme.palette.color2,
            textAlign: "center",
        }
    },
    warningInfo: {
        border: `1px solid ${theme.palette.color7}`,
        textAlign: "center",
        padding: "10px 20px",
        margin: "20px auto",
        display: "table",
        borderRadius: "50px",
        fontWeight: 600,
        color: theme.palette.color7,
    },
    deleteAccountSwitcherLabel: {
        textAlign: "center",
        display: "table",
        margin: "0 auto 10px auto",
        "& span": {
            fontWeight: 600,
            fontSize: "14px",
        }
    },
    deleteAccountSwitcher: {
        "& .Mui-checked .MuiSwitch-thumb": {
            backgroundColor: theme.palette.color2,
        },
        "& .MuiSwitch-track": {
            backgroundColor: theme.palette.color2,
        }
    },
    messageContainer: {
        display: "block",
        margin: "15px auto 30px auto",
    },
}));

export default useStylesUserProfile;